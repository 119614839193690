/* eslint-disable require-jsdoc */
import React from 'react';
import {roles} from '../../constant/roles';
import {getUserRole} from '../../helpers/getUserRole';
const relatedBox = (field: any, inputName: any, id: any, updateData: any) => {
  let backgroundColor = 'yellow';
  if (field === 'Approved') {
    backgroundColor = 'green';
  } else if (field === 'Rejected') {
    backgroundColor = 'red';
  }
  const isAdmin = getUserRole() === roles.admin;
  return (
    <div
      onClick={() => {
        if (isAdmin) {
          const obj: any = {
            id: id,
          };
          obj[`${inputName}`] = 'Rejected';
          updateData(obj);
        }
      }}
      style={{
        width: 24,
        height: 24,
        background: backgroundColor,
        cursor: backgroundColor !== 'red' && isAdmin ? 'pointer' : 'none',
      }}
    />
  );
};

export const statusField = (gridProps: any, enums: any, updateData: any) => {
  const {dataItem} = gridProps;
  const field = gridProps.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];

  return (
    <td>
      {enums.map((x: any) => {
        if (dataValue === x) {
          return relatedBox(x, field, dataItem.id, updateData);
        }
      })}
    </td>
  );
};
