/* eslint-disable require-jsdoc */
import {isolatedSyllableService} from '../../services/isolatedSyllable.service';
import {isolatedSyllableConstants} from '../constants';

export const isolatedSyllableActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());
    await isolatedSyllableService.getAll(languageId).then(
      (isolatedSyllables: any) => dispatch(success(isolatedSyllables)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: isolatedSyllableConstants.GETALL_REQUEST};
  }
  function success(isolatedSyllable: any) {
    return {type: isolatedSyllableConstants.GETALL_SUCCESS, isolatedSyllable};
  }
  function failure(error: any) {
    return {type: isolatedSyllableConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    isolatedSyllableService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: isolatedSyllableConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: isolatedSyllableConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: isolatedSyllableConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await isolatedSyllableService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: isolatedSyllableConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: isolatedSyllableConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: isolatedSyllableConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string, index: number) {
  return async (dispatch: any) => {
    dispatch(request());

    await isolatedSyllableService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: isolatedSyllableConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: isolatedSyllableConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: isolatedSyllableConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await isolatedSyllableService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: isolatedSyllableConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: isolatedSyllableConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: isolatedSyllableConstants.APPROVE_ALL_FAILURE, error};
  }
}
