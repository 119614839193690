/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {notificationConstants} from '../constants';

export function notification(state = {}, action: any) {
  switch (action.type) {
    case notificationConstants.GET_LATEST_COMMENTS_REQUEST:
      return {
        loading: true,
      };
    case notificationConstants.GET_LATEST_COMMENTS_SUCCESS:
      return {
        data: action.data,
      };
    case notificationConstants.GET_LATEST_COMMENTS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
