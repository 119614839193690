import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {languageActions, schemeActions, sectionActions} from '../redux/actions';
/* eslint-disable require-jsdoc */
function HomeScreen() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(languageActions.getAll());
    dispatch(schemeActions.getAll());
    dispatch(sectionActions.getAll());
  }, []);

  return (
    <div className="home-screen">
      <h2 style={{textAlign: 'center'}}>Home Page</h2>
    </div>
  );
}
export default HomeScreen;
