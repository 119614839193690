/* eslint-disable require-jsdoc */
import {ModelEndpoints} from '../constant/modelEndpoints';
import {authHeader} from '../helpers/auth-header';
import {logout} from '../helpers/logout';
export const generalEndpointService = {
  generateWordComponentXML,
};
const apiUrl: any = process.env.REACT_APP_API_URL;
const modelName: string = ModelEndpoints.GeneralEndpoint;

async function generateWordComponentXML(languageId: string) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  const data = await fetch(
    `${apiUrl}/${modelName}/generateWordComponent/${languageId}`,
    requestOptions,
  ).then(handleResponse);
  return data;
}

async function handleResponse(response: any) {
  return await response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
