/* eslint-disable object-curly-spacing */
/* eslint object-curly-spacing: ["error", "always", { "objectsInObjects": false }]*/
import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { alphabet } from './alphabet.reducer';
import { authentication } from './authentication.reducer';
import { comment } from './comment.reducer';
import { conjugatedVerb } from './conjugatedVerb.reducer';
import { flashCard } from './flashCard.reducer';
import { isolatedSound } from './isolatedSound.reducer';
import { isolatedSyllable } from './isolatedSyllable.reducer';
import { languages } from './languages.reducer';
import { local } from './local.reducer';
import { morphology } from './morphology.reducer';
import { notification } from './notification.reducer';
import { nounsWithArticle } from './nounsWithArticle.reducer';
import { onetestLetterName } from './onetestLetterName.reducer';
import { onetestLetterSound } from './onetestLetterSound.reducer';
import { onetestNonWord } from './onetestNonWord.reducer';
import { onetestNumberIdentification } from './onetestNumberIdentification.reducer';
import { onetestPhonologicalAwareness } from './onetestPhonologicalAwareness.reducer';
import { onetestSyllableIdentification } from './onetestSyllableIdentification.reducer';
import { onetestWord } from './onetestWord.reducer';
import { nonWord } from './nonWord.reducer';
import { phrase } from './phrase.reducer';
import { registration } from './registration.reducer';
import { schemes } from './schemes.reducer';
import { sections } from './sections.reducer';
import { sentence } from './sentence.reducer';
import { story } from './story.reducer';
import { tag } from './tag.reducer';
import { users } from './users.reducer';

const appReducers = combineReducers({
  authentication,
  registration,
  users,
  sections,
  schemes,
  alphabet,
  alert,
  local,
  languages,
  isolatedSound,
  isolatedSyllable,
  onetestWord,
  onetestPhonologicalAwareness,
  onetestLetterSound,
  onetestLetterName,
  onetestNonWord,
  onetestNumberIdentification,
  onetestSyllableIdentification,
  comment,
  nonWord,
  flashCard,
  tag,
  notification,
  sentence,
  phrase,
  conjugatedVerb,
  nounsWithArticle,
  morphology,
  story,
});

const initialState = appReducers({ authentication: { loggedIn: false, user: {} } }, {});

const rootReducer = (state = initialState, action) => {
  if (action.type === 'LOG_OUT') {
    state = initialState;
  }

  return appReducers(state, action);
};

export default rootReducer;
