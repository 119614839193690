/* eslint-disable require-jsdoc */
import {ModelEndpoints} from '../constant/modelEndpoints';
import {authHeader} from '../helpers/auth-header';
import {logout} from '../helpers/logout';
export const alphabetService = {
  getAll,
  // getById,
  insert,
  update,
  remove,
  approveAll,
};
const apiUrl: any = process.env.REACT_APP_API_URL;
const modelName: string = ModelEndpoints.Alphabet;
async function getAll(languageId: string) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };
  const params: any = {
    where: {
      languageId: languageId,
    },
  };

  const data = await fetch(
    `${apiUrl}/${modelName}?filter=${JSON.stringify(params)}`,
    requestOptions,
  ).then(handleResponse);
  return data;
}

function update(letter: any) {
  const requestOptions: any = {
    method: 'PATCH',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(letter),
  };

  return fetch(`${apiUrl}/${modelName}/${letter.id}`, requestOptions).then(
    handleResponse,
  );
}

function insert(letter: any) {
  const requestOptions: any = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(letter),
  };

  return fetch(`${apiUrl}/${modelName}`, requestOptions).then(handleResponse);
}

function remove(id: string) {
  const requestOptions: any = {
    method: 'DELETE',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };

  return fetch(`${apiUrl}/${modelName}/${id}`, requestOptions).then(
    handleResponse,
  );
}

function approveAll(languageId: string) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${apiUrl}/${modelName}/approveAll/${languageId}`,
    requestOptions,
  ).then(handleResponse);
}

async function handleResponse(response: any) {
  return await response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
