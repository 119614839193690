/* eslint-disable require-jsdoc */
/* eslint-disable indent */
import {languageConstants} from '../constants';

export function languages(state = {items: []}, action: any) {
  switch (action.type) {
    case languageConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case languageConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.languages,
      };
    case languageConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    case languageConstants.INSERT_REQUEST:
      return {
        ...state,
        inserting: true,
      };
    case languageConstants.INSERT_SUCCESS:
      return {
        ...state,
        items: state.items.concat(action.payload),
      };
    case languageConstants.INSERT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        insertError: action.error,
      };
    case languageConstants.SETINTERFACELANGUAGE_REQUEST:
      return {
        ...state,
        selected: state.items[action.languageIndex],
      };
    default:
      return state;
  }
}
