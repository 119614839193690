/* eslint-disable require-jsdoc */
import {phraseService} from '../../services/phrase.service';
import {phraseConstants} from '../constants';

export const phraseActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());
    await phraseService.getAll(languageId).then(
      (phrases: any) => dispatch(success(phrases)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: phraseConstants.GETALL_REQUEST};
  }
  function success(phrase: any) {
    return {type: phraseConstants.GETALL_SUCCESS, phrase};
  }
  function failure(error: any) {
    return {type: phraseConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    phraseService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: phraseConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: phraseConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: phraseConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await phraseService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: phraseConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: phraseConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: phraseConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await phraseService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: phraseConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: phraseConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: phraseConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await phraseService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: phraseConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: phraseConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: phraseConstants.APPROVE_ALL_FAILURE, error};
  }
}
