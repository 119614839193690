/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import React from 'react';

function ImportFromCSV({
  importAnchor,
  showImportPopup,
  changeImportPopupStatus,
}: any) {
  return (
    <>
      <button
        title="Import Excel"
        className="k-button k-primary"
        onClick={() => {
          changeImportPopupStatus(!showImportPopup);
        }}
        ref={importAnchor}
        style={{
          padding: 10,
        }}
      >
        Import from CSV{' '}
        <span
          className={
            showImportPopup
              ? 'k-icon k-i-arrow-60-up'
              : 'k-icon k-i-arrow-60-down'
          }
        ></span>
      </button>
    </>
  );
}

export default ImportFromCSV;
