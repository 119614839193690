/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import {onetestSyllableIdentificationService} from '../../services/onetestSyllableIdentification.service';
import {onetestSyllableIdentificationConstants} from '../constants';

export const onetestSyllableIdentificationActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestSyllableIdentificationService.getAll(languageId).then(
      (onetestSyllableIdentification: any) =>
        dispatch(success(onetestSyllableIdentification)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestSyllableIdentificationConstants.GETALL_REQUEST};
  }
  function success(onetestSyllableIdentification: any) {
    return {
      type: onetestSyllableIdentificationConstants.GETALL_SUCCESS,
      onetestSyllableIdentification,
    };
  }
  function failure(error: any) {
    return {type: onetestSyllableIdentificationConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    onetestSyllableIdentificationService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {
      type: onetestSyllableIdentificationConstants.UPDATE_REQUEST,
      letter,
    };
  }
  function success(letter: any) {
    return {
      type: onetestSyllableIdentificationConstants.UPDATE_SUCCESS,
      letter,
    };
  }
  function failure(letter: any, error: any) {
    return {
      type: onetestSyllableIdentificationConstants.UPDATE_FAILURE,
      letter,
      error,
    };
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestSyllableIdentificationService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestSyllableIdentificationConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {
      type: onetestSyllableIdentificationConstants.INSERT_SUCCESS,
      payload,
    };
  }
  function failure(error: any) {
    return {type: onetestSyllableIdentificationConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestSyllableIdentificationService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestSyllableIdentificationConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: onetestSyllableIdentificationConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: onetestSyllableIdentificationConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestSyllableIdentificationService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: onetestSyllableIdentificationConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: onetestSyllableIdentificationConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {
      type: onetestSyllableIdentificationConstants.APPROVE_ALL_FAILURE,
      error,
    };
  }
}
