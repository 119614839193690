import '@progress/kendo-theme-default/dist/all.css';
// import 'core-js/es6/map';
// import 'core-js/es6/promise';
// import 'core-js/es6/set';
import React from 'react';
import {hydrate, render} from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import './assets/styles/main.scss';
import './i18n/config';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
} else {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
