/* eslint-disable require-jsdoc */
import {storyService} from '../../services/story.service';
import {storyConstants} from '../constants';

export const storyActions = {
  getAll,
  update: _update,
  insert,
  remove,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await storyService.getAll(languageId).then(
      (storys: any) => dispatch(success(storys)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: storyConstants.GETALL_REQUEST};
  }
  function success(story: any) {
    return {type: storyConstants.GETALL_SUCCESS, story};
  }
  function failure(error: any) {
    return {type: storyConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    storyService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: storyConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: storyConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: storyConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await storyService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: storyConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: storyConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: storyConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await storyService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: storyConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: storyConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: storyConstants.REMOVE_FAILURE, error};
  }
}
