/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {Button} from '@progress/kendo-react-buttons';
import {NavLink} from 'react-router-dom';
import {DropDownList, ListItemProps} from '@progress/kendo-react-dropdowns';

// @ts-ignore
import {Detector} from 'react-detect-offline';
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from '@progress/kendo-react-layout';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {adminMenu, publicMenu, userMenu} from '../constant/navbarItems';
import {getRelatedSectionActions} from '../helpers/getRelatedSectionAction';
import {getRelatedSectionName} from '../helpers/getRelatedSectionName';
import {Switch} from '@progress/kendo-react-inputs';

import {
  alertActions,
  commentActions,
  languageActions,
  localActions,
  notificationActions,
  tagActions,
  userActions,
} from '../redux/actions';
import LanguageSwitcher from './LanguageSwitcher';
import {isLoggedIn} from '../helpers/isLoggedIn';
import {getUserRole} from '../helpers/getUserRole';
import {roles} from '../constant/roles';

function AppbarComponent() {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state: any) => state.languages.selected,
  );
  const loggedIn = useSelector((state: any) => state.authentication.loggedIn);

  const handleClick = () => {
    if (selectedLanguage || !loggedIn) {
      const data = isLoggedIn()
        ? getUserRole() === roles.admin
          ? userMenu.concat(adminMenu).map((item: any) => ({
              ...item,
            }))
          : userMenu.map((item: any) => ({
              ...item,
            }))
        : publicMenu.map((item: any) => ({
            ...item,
          }));
      dispatch(localActions.setNavbarData(data));
      dispatch(localActions.changeNavbarStatus());
    } else {
      dispatch(alertActions.error(t('errors.selectLanguageFirst')));
    }
  };
  const userName = useSelector((state: any) => state.authentication.user);
  const languages = useSelector((state: any) => state.languages.items);
  const selectedSection = useSelector(
    (state: any) => state.local.selectedSection,
  );
  const logout = () => {
    dispatch(userActions.logout());
    dispatch(localActions.setNavbarData(publicMenu));
  };

  const changeLang = async (event: any) => {
    const index = languages.findIndex(
      (x: any) => x.id === event.target.value.id,
    );
    await dispatch(languageActions.selectLanguage(index, languages[index].id));

    // Refresh the items
    await dispatch(tagActions.getAll(event.target.value.id));
    await dispatch(
      getRelatedSectionActions(selectedSection).getAll(event.target.value.id),
    );
    await dispatch(
      notificationActions.getlatestComment(
        getRelatedSectionName(selectedSection),
        event.target.value.id,
      ),
    );
    await dispatch(
      commentActions.getExistCommentsCount(
        getRelatedSectionName(selectedSection),
        event.target.value.id,
      ),
    );
  };

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps,
  ) => {
    const itemChildren = (
      <span>
        {t(`languages.${itemProps.dataItem.shortCode.toLocaleLowerCase()}`)}
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any,
  ) => {
    if (!value) {
      return element;
    }
    const children = (
      <span key={2}>
        &nbsp; {t(`languages.${value.shortCode.toLocaleLowerCase()}`)}
      </span>
    );
    return React.cloneElement(element, {...element.props}, children);
  };

  const offlineModalStatus = useSelector(
    (state: any) => state.local.offlineModalStatus,
  );

  const toggleOfflineModalStatusValue = async () => {
    await dispatch(localActions.setOfflineModalStatus(!offlineModalStatus));
  };

  const Circle = ({
    size = '10px',
    colour = 'red',
  }: {
    size: string;
    colour: string;
  }) => (
    <div
      style={{
        backgroundColor: colour,
        borderRadius: '50%',
        width: size,
        height: size,
      }}
    />
  );

  return (
    <div>
      <style>{`
                .title {
                    font-size: 18px;
                    margin: 0;
                }

                .k-button {
                    padding: 0;
                }
                .k-badge-container {
                    margin-right: 8px;
                }
            `}</style>
      <AppBar positionMode={'sticky'}>
        <AppBarSection>
          <button className="k-button k-button-clear" onClick={handleClick}>
            <span className="k-icon k-i-menu" />
          </button>
        </AppBarSection>

        <AppBarSpacer style={{width: 4}} />

        <AppBarSection>
          <h1 className="title">{t('languageDatabaseTool')}</h1>
        </AppBarSection>

        <AppBarSpacer style={{width: 32}} />

        <AppBarSection>
          <LanguageSwitcher />
        </AppBarSection>
        <AppBarSection>
          {loggedIn && (
            <DropDownList
              data={languages}
              itemRender={itemRender}
              valueRender={valueRender}
              onChange={(e: any) => {
                changeLang(e);
              }}
              dir={i18n.dir()}
              textField="shortCode"
              dataItemKey="id"
              style={{width: '200px'}}
            />
          )}
        </AppBarSection>

        <AppBarSpacer />
        {loggedIn && (
          <AppBarSection className="actions">
            <Button icon="logout" look="flat" onClick={logout} />
          </AppBarSection>
        )}

        {/* {loggedIn && (
          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
        )}

        {loggedIn && (
          <AppBarSection className="actions">
            <button className="k-button k-button-clear">
              <BadgeContainer>
                <span className="k-icon k-i-bell" />
                <Badge
                  shape="dot"
                  themeColor="info"
                  size="small"
                  position="inside"
                />
              </BadgeContainer>
            </button>
          </AppBarSection>
        )} */}

        {loggedIn && (
          <Switch
            name={'offlineModalStatus'}
            defaultChecked={offlineModalStatus || true}
            onChange={() => {
              toggleOfflineModalStatusValue();
            }}
          />
        )}

        {loggedIn && (
          <AppBarSection>
            <span className="k-appbar-separator" />
          </AppBarSection>
        )}

        {loggedIn && (
          <Detector
            render={({online}: {online: boolean}) => (
              <div>
                {online ? (
                  <Circle size={'10px'} colour={'green'} />
                ) : (
                  <Circle size={'10px'} colour={'red'} />
                )}
              </div>
            )}
          />
        )}

        {loggedIn && userName && (
          <AppBarSection>
            <NavLink to="/profile" style={{textDecoration: 'none'}}>
              <Avatar shape="circle" type="text" border={true} fill="outline">
                {userName.name[0].toLocaleUpperCase()}
              </Avatar>
            </NavLink>
          </AppBarSection>
        )}
      </AppBar>
    </div>
  );
}

export default AppbarComponent;
