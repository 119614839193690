/* eslint-disable indent */
/* eslint-disable require-jsdoc */

export function changeSectionScheme(endpoint: string, scheme: any) {
  const properties: any = scheme;
  switch (endpoint) {
    case 'alphabet':
      properties.uniqueId = {
        defaultValue: 'alph_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.lowercase = {
        uniqueIdDefaultValue: 'alph_',
        defaultValueBaseFrom: true,
        ...properties.lowercase,
      };
      return properties;
    case 'nonWord':
      properties.uniqueId = {
        defaultValue: 'nw_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.word = {
        uniqueIdDefaultValue: 'nw_',
        defaultValueBaseFrom: true,
        ...properties.lowercase,
      };
      return properties;
    case 'isolatedSound':
      properties.uniqueId = {
        defaultValue: 'is_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.lowercase = {
        uniqueIdDefaultValue: 'is_',
        defaultValueBaseFrom: true,
        ...properties.lowercase,
      };
      return properties;
    case 'isolatedSyllable':
      properties.uniqueId = {
        defaultValue: 'isyl_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.syllable = {
        uniqueIdDefaultValue: 'isyl_',
        defaultValueBaseFrom: true,
        ...properties.syllable,
      };
      return properties;
    case 'flashCard':
      properties.uniqueId = {
        defaultValue: 'fc_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.source = {
        uniqueIdDefaultValue: 'fc_',
        defaultValueBaseFrom: true,
        ...properties.source,
      };
      properties.imageId = {
        ...properties.imageId,
        type: 'image',
      };
      return properties;
    case 'onetestWord':
      properties.uniqueId = {
        defaultValue: 'w_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.source = {
        uniqueIdDefaultValue: 'w_',
        defaultValueBaseFrom: false,
        ...properties.source,
      };
      properties.imageId = {
        ...properties.imageId,
        type: 'image',
      };
      return properties;
    case 'onetestPhonologicalAwareness':
      properties.uniqueId = {
        defaultValue: 'w_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.word = {
        uniqueIdDefaultValue: 'w_',
        defaultValueBaseFrom: false,
        ...properties.word,
      };
      return properties;
    case 'onetestLetterSound':
      properties.uniqueId = {
        defaultValue: 's_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.lowercase = {
        uniqueIdDefaultValue: 's_',
        defaultValueBaseFrom: true,
        ...properties.lowercase,
      };
      return properties;
    case 'onetestLetterName':
      properties.uniqueId = {
        defaultValue: 'l_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.lowercase = {
        uniqueIdDefaultValue: 'l_',
        defaultValueBaseFrom: true,
        ...properties.lowercase,
      };
      return properties;
    case 'onetestNonWord':
      properties.uniqueId = {
        defaultValue: 'n_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.word = {
        uniqueIdDefaultValue: 'n_',
        defaultValueBaseFrom: true,
        ...properties.lowercase,
      };
      return properties;
    case 'onetestNumberIdentification':
      properties.uniqueId = {
        defaultValue: 'i_',
        disabled: false,
        ...properties.uniqueId,
      };
      // TODO: Number field doesn't work
      properties.number = {
        uniqueIdDefaultValue: 'i_',
        defaultValueBaseFrom: true,
        ...properties.number,
      };
      return properties;
    case 'onetestSyllableIdentification':
      properties.uniqueId = {
        defaultValue: 'sy_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.syllable = {
        uniqueIdDefaultValue: 'sy_',
        defaultValueBaseFrom: true,
        ...properties.syllable,
      };
      return properties;
    case 'sentence':
      properties.uniqueId = {
        defaultValue: 'se',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.source = {
        ...properties.source,
        type: 'textArea',
      };
      properties.target = {
        ...properties.target,
        uniqueIdDefaultValue: 'se',
        defaultValueBaseFrom: false,
        type: 'textArea',
      };
      properties.imageId = {
        ...properties.imageId,
        type: 'image',
      };
      return properties;
    case 'phrase':
      properties.uniqueId = {
        defaultValue: 'ph',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.source = {
        ...properties.source,
        type: 'textArea',
      };
      properties.target = {
        ...properties.target,
        uniqueIdDefaultValue: 'ph',
        defaultValueBaseFrom: false,
        type: 'textArea',
      };
      properties.imageId = {
        ...properties.imageId,
        type: 'image',
      };
      return properties;
    case 'conjugatedVerb':
      properties.uniqueId = {
        defaultValue: 'ver_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.target = {
        ...properties.target,
        uniqueIdDefaultValue: 'ver_',
        defaultValueBaseFrom: false,
      };
      return properties;
    case 'nounsWithArticle':
      properties.uniqueId = {
        defaultValue: 'art_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.target = {
        ...properties.target,
        uniqueIdDefaultValue: 'art_',
        defaultValueBaseFrom: false,
      };
      return properties;
    case 'morphology':
      properties.uniqueId = {
        defaultValue: 'mrph_',
        disabled: false,
        ...properties.uniqueId,
      };
      properties.markdown = {
        ...properties.markdown,
        uniqueIdDefaultValue: 'mrph_',
        defaultValueBaseFrom: false,
        type: 'tooltip',
      };
      return properties;
    case 'story':
      properties.uniqueId = {
        defaultValue: '',
        disabled: false,
        ...properties.uniqueId,
      };
      // properties.lowercase = {
      //   uniqueIdDefaultValue: '',
      //   defaultValueBaseFrom: false,
      //   ...properties.lowercase,
      // };
      return properties;
  }
}
