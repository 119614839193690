/* eslint-disable indent */

import {ModelEndpoints} from '../constant/modelEndpoints';

/* eslint-disable require-jsdoc */
export const getRelatedSectionName = (type: string) => {
  const sectionPath = '/sections/';
  switch (type) {
    case `${sectionPath}alphabet`:
      return ModelEndpoints.Alphabet;
    case `${sectionPath}flashCard`:
      return ModelEndpoints.FlashCard;
    case `${sectionPath}isolatedSound`:
      return ModelEndpoints.IsolatedSound;
    case `${sectionPath}isolatedSyllable`:
      return ModelEndpoints.IsolatedSyllable;
    case `${sectionPath}nonWord`:
      return ModelEndpoints.NonWord;
    case `${sectionPath}onetestLetterName`:
      return ModelEndpoints.OnetestLetterName;
    case `${sectionPath}onetestLetterSound`:
      return ModelEndpoints.OnetestLetterSound;
    case `${sectionPath}onetestNonWord`:
      return ModelEndpoints.OnetestNonWord;
    case `${sectionPath}onetestNumberIdentification`:
      return ModelEndpoints.OnetestNumberIdentification;
    case `${sectionPath}onetestPhonologicalAwareness`:
      return ModelEndpoints.OnetestPhonologicalAwareness;
    case `${sectionPath}onetestSyllableIdentification`:
      return ModelEndpoints.OnetestSyllableIdentification;
    case `${sectionPath}onetestWord`:
      return ModelEndpoints.OnetestWord;
    case `${sectionPath}sentence`:
      return ModelEndpoints.Sentence;
    case `${sectionPath}phrase`:
      return ModelEndpoints.Phrase;
    case `${sectionPath}conjugatedVerb`:
      return ModelEndpoints.ConjugatedVerb;
    case `${sectionPath}nounsWithArticle`:
      return ModelEndpoints.NounsWithArticle;
    case `${sectionPath}morphology`:
      return ModelEndpoints.Morphology;
    case `${sectionPath}story`:
      return ModelEndpoints.Story;
    default:
      return ModelEndpoints.Alphabet; // TODO:
  }
};
