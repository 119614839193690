/* eslint-disable require-jsdoc */

function isTokenExpired(exp: number) {
  const now = new Date();
  const expDate = new Date(exp * 1000);
  if (expDate < now) {
    return true; // Token Expired
  } else {
    return false; // Token Valid
  }
}

export function isLoggedIn() {
  const user = localStorage.getItem('user');
  if (!user || isTokenExpired(JSON.parse(user).exp)) {
    localStorage.removeItem('user');
    return false;
  } else {
    return true;
  }
}
