/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */

import {Controls, Player} from '@lottiefiles/react-lottie-player';
import {Button} from '@progress/kendo-react-buttons';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {Input} from '@progress/kendo-react-inputs';
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {Formik} from 'formik';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import RegisterScreenImage from '../assets/images/pages/registerScreenImage.json';
import {roles} from '../constant/roles';
import {userActions} from '../redux/actions';

const arr = Object.keys(roles).map((key: any) => ({text: key, value: key}));
const schema = yup.object().shape({
  email: yup.string().email('form.email').required('form.required'),
  name: yup.string().required('form.required'),
  roles: yup.array().min(1, 'form.required'),
  access: yup.array().min(1, 'form.required'),
});

function RegisterScreen() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const languages = useSelector((state: any) => state.languages.items);
  const lngData = languages.map((x: any) => ({text: x.name, value: x.id}));

  interface MyFormValues {
    email: string;
    name: string;
    roles: Array<string>;
    access: any;
  }

  const register = (values: any) => {
    const user = {
      email: values.email,
      name: values.name,
      roles:
        values.roles &&
        values.roles.map((e: any) => {
          return e.value;
        }),
      access:
        values.access &&
        values.access.map((e: any) => {
          return {
            languageId: e.value,
            read: 1,
            write: e.write,
          };
        }),
    };
    dispatch(userActions.register(user));
  };
  const initialValues: MyFormValues = {
    email: '',
    name: '',
    roles: [],
    access: [],
  };
  return (
    <div className="registerScreen">
      <Formik
        validationSchema={schema}
        onSubmit={(values: any) => {
          register(values);
        }}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            <Player
              autoplay
              loop
              src={RegisterScreenImage}
              style={{height: '300px', width: '500px'}}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
            <header>
              <h3>{t('registerHeader')}</h3>
              <br />
            </header>
            <fieldset className={'k-form-fieldset'}>
              <FormElement>
                <FieldWrapper>
                  <Label editorId={'email'}>{t('email')}</Label>
                  <Input
                    autoFocus
                    name={'email'}
                    type={'email'}
                    value={values.email}
                    onChange={handleChange}
                    valid={!errors.email && values.email.length > 0}
                  />
                  {errors.email && (
                    <Error id={'email'}>
                      {t(`errors.${errors.email}`, {fieldType: t('email')})}
                    </Error>
                  )}
                  {!errors.email && values.email.length > 0 && (
                    <Hint id={'email'} style={{color: 'green'}}>
                      {t('looksGood')}
                    </Hint>
                  )}
                </FieldWrapper>
                <FieldWrapper>
                  <Label editorId={'name'}>{t('name')}</Label>
                  <Input
                    name={'name'}
                    type={'name'}
                    value={values.name}
                    onChange={handleChange}
                    valid={!errors.name && values.name.length > 0}
                  />
                  {errors.name && (
                    <Error id={'name'}>
                      {t(`errors.${errors.name}`, {fieldType: t('name')})}
                    </Error>
                  )}
                  {!errors.name && values.name.length > 0 && (
                    <Hint id={'name'} style={{color: 'green'}}>
                      {t('looksGood')}
                    </Hint>
                  )}
                </FieldWrapper>
                <FieldWrapper>
                  <Label editorId={'roles'}>{t('roles')}</Label>
                  <MultiSelect
                    onChange={handleChange}
                    name="roles"
                    value={values.roles}
                    data={arr}
                    textField="text"
                  />
                  {errors.roles && (
                    <Error id={'roles'}>
                      {t(`errors.${errors.roles}`, {fieldType: t('roles')})}
                    </Error>
                  )}
                </FieldWrapper>
                {lngData && (
                  <FieldWrapper>
                    <Label editorId={'access'}>{t('access')}</Label>
                    <MultiSelect
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      name="access"
                      value={values.access}
                      data={lngData}
                      textField="text"
                    />
                    {errors.access && (
                      <Error id={'access'}>
                        {t(`errors.${errors.access}`, {fieldType: t('access')})}
                      </Error>
                    )}
                    {values.access && values.access.length > 0 && (
                      <table>
                        <tr>
                          <th>{t('language')}</th>
                          <th>{t('read')}</th>
                          <th>{t('write')}</th>
                        </tr>
                        {values.access.map((item: any, index: number) => {
                          return (
                            <tr key={item.text}>
                              <td>{item.text}</td>
                              <td>
                                <input
                                  name={'read'}
                                  id={`read-${item.value}`}
                                  value={1}
                                  checked={true}
                                  type={'checkbox'}
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  name={'write'}
                                  id="write"
                                  value={values.access[index].write ? 1 : 0}
                                  type={'checkbox'}
                                  onChange={(e: any) => {
                                    values.access[index].write =
                                      e.target.checked;
                                    handleChange(e);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    )}
                  </FieldWrapper>
                )}
              </FormElement>
              <br />
              <div className="k-form-buttons k-justify-content-end">
                <Button
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  primary={true}
                  look={'outline'}
                  type={'submit'}
                  disabled={errors.email || errors.name ? true : false}
                >
                  {t('create')}
                </Button>
              </div>
            </fieldset>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default RegisterScreen;
