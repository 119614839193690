/* eslint-disable require-jsdoc */
export function authHeader() {
  // return authorization header with jwt token
  const user: any = JSON.parse(localStorage.getItem('user') || '{}');
  const selectedLanguageId: any = localStorage.getItem('selectedLanguageId');

  if (user && user.token) {
    return {
      Authorization: 'Bearer ' + user.token,
      Language: selectedLanguageId,
    };
  } else {
    return {};
  }
}
