/* eslint-disable require-jsdoc */
import {Switch} from '@progress/kendo-react-inputs';
import React, {useState} from 'react';

export const booleanField = (props: any, updateData: Function) => {
  const {dataItem} = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  const [toggleValue, setToggleValue] = useState(dataValue);
  return (
    <td>
      {dataItem.inEdit ? (
        <Switch
          name={field}
          defaultChecked={toggleValue}
          onChange={() => {
            const obj: any = {
              id: dataItem.id,
              status: 'Edited',
            };
            obj[`${field}`] = !toggleValue;
            setToggleValue(!toggleValue);
            updateData(obj);
          }}
        />
      ) : (
        dataValue.toString()
      )}
    </td>
  );
};
