/* eslint-disable require-jsdoc */
import i18next from 'i18next';
import {alertActions} from '.';

export const generalActions = {
  generateSpecialXMLFile,
};

function generateSpecialXMLFile(
  languageId: any,
  relatedService: any,
  relatedConstants: any,
) {
  return async (dispatch: any) => {
    dispatch(request());
    const successfulMessage = i18next.t('specialXMLGenerationSuccessful');
    await relatedService.generateSpecialXMLFile(languageId).then(
      (xml: any) => {
        dispatch(success(xml));
        dispatch(alertActions.success(successfulMessage));
      },
      (error: any) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request() {
    return {type: relatedConstants.GENERATE_SPECIAL_XML_REQUEST};
  }
  function success(xml: string) {
    return {type: relatedConstants.GENERATE_SPECIAL_XML_SUCCESS, xml};
  }
  function failure(error: any) {
    return {type: relatedConstants.GENERATE_SPECIAL_XML_FAILURE, error};
  }
}
