/* eslint-disable require-jsdoc */
import {authHeader} from '../helpers/auth-header';
import {logout} from '../helpers/logout';
export const tagService = {
  getAll,
  insert,
  // getById,
  // update,
  // delete: _delete,
};
const apiUrl: any = process.env.REACT_APP_API_URL;

async function getAll(languageId: string) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };
  const params: any = {
    where: {
      languageId: languageId,
    },
  };

  const data = await fetch(
    `${apiUrl}/tag?filter=${JSON.stringify(params)}`,
    requestOptions,
  ).then(handleResponse);
  return data;
}

function insert(tag: any) {
  const requestOptions: any = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(tag),
  };

  return fetch(`${apiUrl}/tag`, requestOptions).then(handleResponse);
}

async function handleResponse(response: any) {
  return await response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
