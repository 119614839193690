/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import {Input} from '@progress/kendo-react-inputs';
import {Popup} from '@progress/kendo-react-popup';
import React, {useState} from 'react';

export const imageField = (
  gridProps: any,
  value: string,
  defaultVal: string,
  updateData: Function,
) => {
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const [show, setShow] = React.useState(false);
  const [focus, setFocus] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const {dataItem} = gridProps;
  const field = gridProps.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  return (
    <td
      onMouseEnter={() => {
        if (dataValue.length > 0) {
          setImageUrl(
            `https://ldbimages.onebillion.org/svg/${dataValue.toString()}.svg`,
          );
          setShow(true);
        }
      }}
      onMouseLeave={() => {
        if (dataValue.length > 0) {
          setImageUrl(``);
          setShow(false);
        }
      }}
    >
      {dataItem.inEdit ? (
        <Input
          defaultValue={dataValue ? dataValue : defaultVal}
          name={field}
          value={field === 'uniqueId' && !focus ? dataValue : undefined}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={(e: any) => {
            setFocus(false);
            const obj: any = {
              id: dataItem.id,
            };

            obj[`${field}`] = e.target.value;

            if (value !== e.target.value) {
              obj['status'] = 'Edited';
              updateData(obj);
            }
          }}
        />
      ) : (
        <div style={{maxWidth: 'fit-content'}}>
          <button
            ref={anchor}
            style={{
              border: 0,
              padding: 0,
              fontFamily: 'inherit',
              fontSize: '100%',
              backgroundColor: 'transparent',
            }}
          >
            {dataValue.toString()}
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={'popup-content'}
            style={{width: 200, height: 200}}
          >
            <img
              src={imageUrl}
              alt="This image doesn't exist on the server."
              style={{width: 200, height: 200}}
              onError={(e: any) => {
                setImageUrl(
                  `https://ldbimages.onebillion.org/png/${dataValue.toString()}.png`,
                );
              }}
            />
          </Popup>
        </div>
      )}
    </td>
  );
};
