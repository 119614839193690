/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */

import {Controls, Player} from '@lottiefiles/react-lottie-player';
import {Slide} from '@progress/kendo-react-animation';
import {Button} from '@progress/kendo-react-buttons';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {Input} from '@progress/kendo-react-inputs';
import {Error, Label} from '@progress/kendo-react-labels';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';
import {Formik} from 'formik';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import * as yup from 'yup';
import LoginScreenImage from '../assets/images/pages/loginScreenImage.json';
import {userActions} from '../redux/actions';

const schema = yup.object().shape({
  email: yup.string().email('form.email').required('form.required'),
  password: yup.string().required('form.required'),
});

function LoginScreen() {
  const [showToast, setShow] = useState(true);
  const toggleShow = () => setShow(!showToast);
  const loggingIn = useSelector((state: any) => state.authentication.loggingIn);
  // const history = useHistory();
  const location = useLocation();
  // const auth: any = useAuth();
  const {t} = useTranslation();
  const {from}: any = location.state || {from: {pathname: '/'}};
  const {message}: any = location.state || {message: null};
  const dispatch = useDispatch();

  const login = (email: string, password: string) => {
    dispatch(userActions.login(email, password, from));
  };

  return (
    <div className="loginScreen">
      <Formik
        validationSchema={schema}
        onSubmit={(values: any) => {
          login(values.email, values.password);
        }}
        initialValues={{
          email: '',
          password: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            <Player
              autoplay
              loop
              src={LoginScreenImage}
              style={{height: '300px', width: '500px'}}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
            <header>
              <h3>{t('signIn')}</h3>
              <br />
            </header>
            <fieldset className={'k-form-fieldset'}>
              <FormElement>
                <FieldWrapper>
                  <Label editorId={'email'}>{t('email')}</Label>
                  <Input
                    autoFocus
                    name={'email'}
                    type={'email'}
                    value={values.email}
                    onChange={handleChange}
                    valid={!errors.email && values.email.length > 0}
                  />
                  {errors.email && (
                    <Error id={'email'}>
                      {t(`errors.${errors.email}`, {fieldType: t('email')})}
                    </Error>
                  )}
                </FieldWrapper>
                <FieldWrapper>
                  <Label editorId={'password'}>{t('password')}</Label>
                  <Input
                    name={'password'}
                    type={'password'}
                    value={values.password}
                    onChange={handleChange}
                    valid={!errors.password && values.password.length > 0}
                  />
                  {errors.password && (
                    <Error id={'password'}>
                      {t(`errors.${errors.password}`, {
                        fieldType: t('password'),
                      })}
                    </Error>
                  )}
                </FieldWrapper>
              </FormElement>
              <br />
              <div className="k-form-buttons k-justify-content-end">
                <Button
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  primary={true}
                  look={'outline'}
                  type={'submit'}
                  disabled={
                    errors.email ||
                    errors.password ||
                    loggingIn ||
                    values.password.length === 0 ||
                    values.email.length === 0
                      ? true
                      : false
                  }
                >
                  {t('login')}
                </Button>
              </div>
            </fieldset>
          </form>
        )}
      </Formik>
      {showToast && message && (
        <NotificationGroup
          style={{
            right: 0,
            bottom: 0,
            alignItems: 'flex-start',
            flexWrap: 'wrap-reverse',
          }}
        >
          <Slide direction={'up'}>
            <Notification
              type={{
                style: 'error',
                // icon: true,
              }}
              closable={true}
              onClose={toggleShow}
            >
              {t(message)}
            </Notification>
          </Slide>
        </NotificationGroup>
      )}
    </div>
  );
}

export default LoginScreen;
