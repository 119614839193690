/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {
  Grid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {Input} from '@progress/kendo-react-inputs';
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {getFilterFieldType} from '../../helpers/getFilterFieldType';
import {tagActions} from '../../redux/actions';

const initialFilter: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};
const initialSort: Array<SortDescriptor> = [];

const schema = yup.object().shape({
  key: yup.string().required('form.required'),
  value: yup.string().required('form.required'),
  color: yup.string().required('form.required'),
});

export function TagScreen({schemes}: any) {
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = React.useState(initialSort);
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state: any) => state.languages.selected);
  const create = (values: any) => {
    const newTag = {
      key: values.key,
      value: values.value,
      color: values.color,
      rank: tags.length,
      languageId: currentLanguage.id,
    };
    dispatch(tagActions.insert(newTag));
    setDialogVisible(!dialogVisible);
  };

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState<any>([]);

  useEffect(() => {
    dispatch(tagActions.getAll(currentLanguage.id));
  }, [value]);

  let tagScheme = null;
  // TODO: The first "if block" doesn't work
  if (!schemes) {
    const schemes = useSelector((state: any) => state.schemes.scheme);
    tagScheme = schemes['Tag'].properties;
  } else {
    tagScheme = schemes['Tag'].properties;
  }
  const tags = useSelector((state: any) => state.tag.data);
  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };
  return (
    <div className="section-template">
      <div>
        <h2 style={{textAlign: 'center'}}>{t('menu.section.tag')}</h2>
      </div>
      {tagScheme && tags && (
        <Grid
          data={orderBy(filterBy(tags, filter), sort)}
          filterable={true}
          filter={filter}
          onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridToolbar>
            <button
              title="Add"
              className="k-button k-primary"
              onClick={toggleDialog}
              style={{padding: 10}}
            >
              {t('create')}
            </button>
          </GridToolbar>
          <Column
            field={'Operations'}
            title={t('operations')}
            width="100"
            filterable={false}
          />
          {Object.entries(tagScheme).map(([key, value]: any, index) => {
            if (key !== 'id' && key !== 'languageId' && key !== 'rank') {
              return (
                <Column
                  field={key}
                  filter={getFilterFieldType(value.type)}
                  title={t(`tag.${key}`)}
                />
              );
            }
          })}
        </Grid>
      )}
      {dialogVisible && (
        <Dialog title={t('create')} onClose={toggleDialog}>
          <Formik
            validationSchema={schema}
            onSubmit={(values: any) => {
              create(values);
            }}
            initialValues={{
              key: '',
              value: '',
              color: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form onSubmit={handleSubmit} className="form">
                <fieldset className={'k-form-fieldset'}>
                  <FormElement>
                    <FieldWrapper>
                      <Label editorId={'name'}>{t('tag.key')}</Label>
                      <Input
                        autoFocus
                        name={'key'}
                        value={values.key}
                        onChange={handleChange}
                        valid={!errors.key && values.key.length > 0}
                      />
                      {errors.key && (
                        <Error id={'key'}>
                          {t(`errors.${errors.key}`, {fieldType: t('tag.key')})}
                        </Error>
                      )}
                      {!errors.key && values.key.length > 0 && (
                        <Hint id={'key'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'name'}>{t('tag.value')}</Label>
                      <Input
                        autoFocus
                        name={'value'}
                        value={values.value}
                        onChange={handleChange}
                        valid={!errors.value && values.value.length > 0}
                      />
                      {errors.value && (
                        <Error id={'value'}>
                          {t(`errors.${errors.value}`, {
                            fieldType: t('tag.value'),
                          })}
                        </Error>
                      )}
                      {!errors.value && values.value.length > 0 && (
                        <Hint id={'value'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'name'}>{t('tag.color')}</Label>
                      <Input
                        autoFocus
                        name={'color'}
                        type={'color'}
                        value={values.color}
                        onChange={handleChange}
                        valid={!errors.color && values.color.length > 0}
                      />
                      {errors.color && (
                        <Error id={'color'}>
                          {t(`errors.${errors.color}`, {
                            fieldType: t('tag.color'),
                          })}
                        </Error>
                      )}
                      {!errors.color && values.color.length > 0 && (
                        <Hint id={'color'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                  </FormElement>
                  <br />
                  <DialogActionsBar>
                    <div />
                    <div className="k-form-buttons k-justify-content-end">
                      <Button
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                        }}
                        primary={true}
                        look={'outline'}
                        type={'submit'}
                        disabled={
                          errors.key || errors.value || errors.color
                            ? true
                            : false
                        }
                      >
                        {t('create')}
                      </Button>
                    </div>
                  </DialogActionsBar>
                </fieldset>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}
