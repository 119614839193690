export const conjugatedVerbConstants = {
  GETALL_REQUEST: 'CONJUGATED_VERB_GETALL_REQUEST',
  GETALL_SUCCESS: 'CONJUGATED_VERB_GETALL_SUCCESS',
  GETALL_FAILURE: 'CONJUGATED_VERB_GETALL_FAILURE',

  UPDATE_REQUEST: 'CONJUGATED_VERB_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CONJUGATED_VERB_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CONJUGATED_VERB_UPDATE_FAILURE',

  INSERT_REQUEST: 'CONJUGATED_VERB_INSERT_REQUEST',
  INSERT_SUCCESS: 'CONJUGATED_VERB_INSERT_SUCCESS',
  INSERT_FAILURE: 'CONJUGATED_VERB_INSERT_FAILURE',

  REMOVE_REQUEST: 'CONJUGATED_VERB_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'CONJUGATED_VERB_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'CONJUGATED_VERB_REMOVE_FAILURE',

  APPROVE_ALL_REQUEST: 'CONJUGATED_VERB_APPROVE_ALL_REQUEST',
  APPROVE_ALL_SUCCESS: 'CONJUGATED_VERB_APPROVE_ALL_SUCCESS',
  APPROVE_ALL_FAILURE: 'CONJUGATED_VERB_APPROVE_ALL_FAILURE',

  GENERATE_SPECIAL_XML_REQUEST: 'CONJUGATED_VERB_GENERATE_SPECIAL_XML_REQUEST',
  GENERATE_SPECIAL_XML_SUCCESS: 'CONJUGATED_VERB_GENERATE_SPECIAL_XML_SUCCESS',
  GENERATE_SPECIAL_XML_FAILURE: 'CONJUGATED_VERB_GENERATE_SPECIAL_XML_FAILURE',

  MATCH_COMMENT_DATA: 'CONJUGATED_VERB_MATCH_COMMENT_DATA',
  MATCH_COMMENT_COUNT_DATA: 'CONJUGATED_VERB_MATCH_COMMENT_COUNT_DATA',
  REMOVE_UNREAD_NOTIFICATION: 'CONJUGATED_VERB_REMOVE_UNREAD_NOTIFICATION',
};
