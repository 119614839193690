export const morphologyConstants = {
  GETALL_REQUEST: 'MORPHOLOGY_GETALL_REQUEST',
  GETALL_SUCCESS: 'MORPHOLOGY_GETALL_SUCCESS',
  GETALL_FAILURE: 'MORPHOLOGY_GETALL_FAILURE',

  UPDATE_REQUEST: 'MORPHOLOGY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'MORPHOLOGY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'MORPHOLOGY_UPDATE_FAILURE',

  INSERT_REQUEST: 'MORPHOLOGY_INSERT_REQUEST',
  INSERT_SUCCESS: 'MORPHOLOGY_INSERT_SUCCESS',
  INSERT_FAILURE: 'MORPHOLOGY_INSERT_FAILURE',

  REMOVE_REQUEST: 'MORPHOLOGY_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'MORPHOLOGY_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'MORPHOLOGY_REMOVE_FAILURE',

  APPROVE_ALL_REQUEST: 'MORPHOLOGY_APPROVE_ALL_REQUEST',
  APPROVE_ALL_SUCCESS: 'MORPHOLOGY_APPROVE_ALL_SUCCESS',
  APPROVE_ALL_FAILURE: 'MORPHOLOGY_APPROVE_ALL_FAILURE',

  GENERATE_SPECIAL_XML_REQUEST: 'MORPHOLOGY_GENERATE_SPECIAL_XML_REQUEST',
  GENERATE_SPECIAL_XML_SUCCESS: 'MORPHOLOGY_GENERATE_SPECIAL_XML_SUCCESS',
  GENERATE_SPECIAL_XML_FAILURE: 'MORPHOLOGY_GENERATE_SPECIAL_XML_FAILURE',

  MATCH_COMMENT_DATA: 'MORPHOLOGY_MATCH_COMMENT_DATA',
  MATCH_COMMENT_COUNT_DATA: 'MORPHOLOGY_MATCH_COMMENT_COUNT_DATA',
  REMOVE_UNREAD_NOTIFICATION: 'MORPHOLOGY_REMOVE_UNREAD_NOTIFICATION',

  CHANGE_MARKUP_MODE: 'MORPHOLOGY_CHANGE_MARKUP_MODE',
};
