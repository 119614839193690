/* eslint-disable require-jsdoc */
import {Loader} from '@progress/kendo-react-indicators';
import moment from 'moment';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {getRelatedSectionConstant} from '../../helpers/getRelatedSectionConstant';
import {getRelatedSectionService} from '../../helpers/getRelatedSectionService';

function GenerateSpecialXML({props}: any) {
  const [clickedDownload, setClickedDownload] = useState(false);
  const selectedSection = useSelector(
    (state: any) => state.local.selectedSection,
  );
  const selectedSectionName = selectedSection.replace('/sections/', '');
  const generateSpecialXMLRequest = useSelector(
    (state: any) => state[selectedSectionName].generateSpecialXMLRequest,
  );

  const specialXML: string = useSelector(
    (state: any) => state[selectedSectionName].specialXML,
  );

  const relatedService = getRelatedSectionService(selectedSection);
  const relatedConstant = getRelatedSectionConstant(selectedSection);
  const generate = () => {
    const languageId = props.selected.id;
    props.generateSpecialXMLFile(languageId, relatedService, relatedConstant);
  };

  const download = () => {
    setClickedDownload(true);
    const utcMoment = moment.utc();
    const encodedUri = encodeURIComponent(specialXML);
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodedUri);
    link.setAttribute(
      'download',
      `${selectedSection}-${utcMoment.format('LLLL')}-${
        props.selected.shortCode
      }.xml`,
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      {specialXML ? (
        <button
          title="Download"
          onClick={() => {
            download();
          }}
          className="k-button k-primary"
          style={{padding: 10}}
          disabled={clickedDownload}
        >
          <span className={'k-icon k-i-download'}></span> Download Special XML
        </button>
      ) : (
        <button
          title="Generate Special XML"
          onClick={() => {
            generate();
          }}
          className="k-button k-primary"
          style={{padding: 10}}
        >
          {generateSpecialXMLRequest ? (
            <Loader themeColor={'light'} />
          ) : (
            <span>
              <span className={'k-icon k-i-save'}></span> Generate Special XML
            </span>
          )}
        </button>
      )}
    </>
  );
}

export default GenerateSpecialXML;
