/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {tagConstants} from '../constants';

export function tag(state = {data: []}, action: any) {
  switch (action.type) {
    case tagConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case tagConstants.GETALL_SUCCESS:
      return {
        data: action.tag,
      };
    case tagConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    case tagConstants.INSERT_REQUEST:
      return {
        ...state,
        inserting: true,
      };
    case tagConstants.INSERT_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload),
      };
    case tagConstants.INSERT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        insertError: action.error,
      };
    default:
      return state;
  }
}
