/* eslint-disable require-jsdoc */
import React from 'react';
import {BrowserRouter, Redirect, Router, Switch} from 'react-router-dom';
import {history} from '../helpers/history';
import {LanguageScreen} from '../pages/admin/LanguageScreen';
import {SectionScreen} from '../pages/admin/SectionScreen';
import {TagScreen} from '../pages/admin/TagScreen';
import HomeScreen from '../pages/HomeScreen';
import LoginScreen from '../pages/LoginScreen';
import LogoutScreen from '../pages/LogoutScreen';
import RegisterScreen from '../pages/RegisterScreen';
import ProfileScreen from '../pages/ProfileScreen';
import AppbarComponent from './Appbar';
import DrawerRouterContainer from './DrawerRouterContainer';
import {AdminRoute} from './routes/AdminRoute';
import {PrivateRoute} from './routes/PrivateRoute';
import {SectionRoute} from './routes/SectionRoute';
import {UnPrivateRoute} from './routes/UnPrivateRoute';
import {UsersScreen} from '../pages/admin/UsersScreen';

function NavbarComponent({}: any) {
  const baseName = process.env.REACT_APP_BASE_NAME;

  return (
    <BrowserRouter basename={baseName}>
      <Router history={history}>
        <AppbarComponent />
        <DrawerRouterContainer />
        <Switch>
          <PrivateRoute exact path="/" component={HomeScreen} />
          <PrivateRoute exact path="/profile" component={ProfileScreen} />
          <UnPrivateRoute exact path="/login" component={LoginScreen} />
          <AdminRoute exact path="/register" component={RegisterScreen} />
          <AdminRoute exact path="/admin/language" component={LanguageScreen} />
          <AdminRoute exact path="/admin/section" component={SectionScreen} />
          <AdminRoute exact path="/admin/tag" component={TagScreen} />
          <AdminRoute exact path="/admin/users" component={UsersScreen} />
          <UnPrivateRoute exact path="/logout" component={LogoutScreen} />
          <SectionRoute exact path="/sections/:endpoint" />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}

export default NavbarComponent;
