/* eslint-disable require-jsdoc */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {roles} from '../../constant/roles';
import {getUserRole} from '../../helpers/getUserRole';
import {isLoggedIn} from '../../helpers/isLoggedIn';

function AdminRoute({component: Component, ...rest}: any) {
  const {t} = useTranslation();
  const schemes = useSelector((state: any) => state.schemes.scheme);
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!isLoggedIn() || getUserRole() !== roles.admin) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {from: props.location, message: t('authAdmin')},
              }}
            />
          );
        }

        // logged in so return component
        return <Component {...props} schemes={schemes} />;
      }}
    />
  );
}

export {AdminRoute};
