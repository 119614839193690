/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */

import {Button} from '@progress/kendo-react-buttons';
import {Dialog} from '@progress/kendo-react-dialogs';
import {
  Avatar,
  Card,
  CardActions,
  CardBody,
  CardTitle,
} from '@progress/kendo-react-layout';
import {Formik} from 'formik';
import React, {useEffect} from 'react';
// import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {userActions} from '../../redux/actions';
import * as yup from 'yup';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import {roles} from '../../constant/roles';
import {useTranslation} from 'react-i18next';
import {Input} from '@progress/kendo-react-inputs';
const arr = Object.keys(roles).map((key: any) => ({text: key, value: key}));

export function UsersScreen({}) {
  const [editDialogVisible, setEditDialogVisible] =
    React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({});
  const [initialValues, setInitialValues] = React.useState<any>({});
  const {t} = useTranslation();
  const languages = useSelector((state: any) => state.languages.items);
  const lngData = languages.map((x: any) => ({text: x.name, value: x.id}));

  const toggleDialog = (user: any | null = null) => {
    if (editDialogVisible) {
      setEditDialogVisible(!editDialogVisible);
    } else if (user) {
      setEditDialogVisible(!editDialogVisible);
      setSelectedUser(user);
      setInitialValues({
        id: user.id,
        email: user.email,
        name: user.name,
        roles: user.roles
          ? user.roles.map((x: any) => ({text: x, value: x}))
          : [],
        access: user.access
          ? user.access.map((x: any) => ({
              text: lngData.find((y: any) => y.value === x.languageId).text,
              value: x.languageId,
              read: x.read,
              write: x.write,
            }))
          : [],
      });
    }
  };

  useEffect(() => {
    initialValues &&
      initialValues.access &&
      initialValues.access.map((x: any) => {
        const t: any = document.getElementById(`read-${x.value}`);
        if (x.read) {
          t.checked = true;
        }
        const p: any = document.getElementById(`write-${x.value}`);
        if (x.write) {
          p.checked = true;
        }
      });
  }, [initialValues]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getAll());
  }, []);

  interface MyFormValues {
    id: string;
    email: string;
    name: string;
    roles: Array<string>;
    access: any;
  }

  const update = (values: MyFormValues) => {
    const data = {
      id: values.id,
      email: values.email,
      name: values.name,
      roles:
        values.roles &&
        values.roles.map((e: any) => {
          return e.value;
        }),
      access:
        values.access &&
        values.access.map((e: any) => {
          return {
            languageId: e.value,
            read: 1,
            write: e.write,
          };
        }),
    };
    dispatch(userActions.updateById(data));
    toggleDialog();
  };

  const schema = yup.object().shape({
    email: yup.string().email('form.email').required('form.required'),
    name: yup.string().required('form.required'),
    roles: yup.array().min(1, 'form.required'),
    access: yup.array(),
  });

  const users = useSelector((state: any) => state.users.items);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {users &&
          users.map((x: any) => (
            <Card style={{flex: '0 1 24%', margin: 5}} key={x.id}>
              <CardBody>
                {x.name && (
                  <CardTitle>
                    <Avatar
                      shape="circle"
                      type="text"
                      border={true}
                      fill="outline"
                    >
                      {x.name[0].toLocaleUpperCase()}
                    </Avatar>
                    {x.name}
                  </CardTitle>
                )}
                <p>Email: {x.email}</p>
                <div
                  style={{
                    color: '#FF6358',
                  }}
                >
                  <span>Roles:</span>
                  {x.roles &&
                    x.roles.map((y: any) => (
                      <span style={{padding: 8}} key={y}>
                        {y}
                      </span>
                    ))}
                </div>
              </CardBody>
              <CardActions style={{justifyContent: 'flex-end'}}>
                <div className="k-form-buttons k-justify-content-end">
                  <Button
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                    primary={true}
                    look={'outline'}
                    onClick={() => {
                      toggleDialog(x);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </CardActions>
            </Card>
          ))}
      </div>
      {editDialogVisible && selectedUser && initialValues && (
        <Dialog
          title={`${selectedUser?.name}`}
          onClose={() => {
            toggleDialog();
          }}
        >
          <Formik
            validationSchema={schema}
            onSubmit={(values: any) => {
              update(values);
            }}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form onSubmit={handleSubmit} className="form">
                <header>
                  <h3>{t('profile')}</h3>
                  <br />
                </header>
                <fieldset className={'k-form-fieldset'}>
                  <FormElement>
                    <FieldWrapper>
                      <Label editorId={'email'}>{t('email')}</Label>
                      <Input
                        autoFocus
                        name={'email'}
                        type={'email'}
                        value={values.email}
                        onChange={handleChange}
                        valid={!errors.email && values.email.length > 0}
                      />
                      {errors.email && (
                        <Error id={'email'}>
                          {t(`errors.${errors.email}`, {fieldType: t('email')})}
                        </Error>
                      )}
                      {!errors.email && values.email.length > 0 && (
                        <Hint id={'email'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'name'}>{t('name')}</Label>
                      <Input
                        name={'name'}
                        type={'name'}
                        value={values.name ? values.name : ''}
                        onChange={handleChange}
                        valid={
                          !errors.name && values.name && values.name.length > 0
                        }
                      />
                      {errors.name && (
                        <Error id={'name'}>
                          {t(`errors.${errors.name}`, {fieldType: t('name')})}
                        </Error>
                      )}
                      {!errors.name && values.name && values.name.length > 0 && (
                        <Hint id={'name'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'roles'}>{t('roles')}</Label>
                      <MultiSelect
                        onChange={handleChange}
                        name="roles"
                        value={values.roles}
                        data={arr}
                        textField="text"
                      />
                      {errors.roles && (
                        <Error id={'roles'}>
                          {t(`errors.${errors.roles}`, {fieldType: t('roles')})}
                        </Error>
                      )}
                    </FieldWrapper>
                    {lngData && (
                      <FieldWrapper>
                        <Label editorId={'access'}>{t('access')}</Label>
                        <MultiSelect
                          onChange={(e: any) => {
                            handleChange(e);
                          }}
                          name="access"
                          value={values.access}
                          data={lngData}
                          textField="text"
                        />
                        {errors.access && (
                          <Error id={'access'}>
                            {t(`errors.${errors.access}`, {
                              fieldType: t('access'),
                            })}
                          </Error>
                        )}
                        {values.access && values.access.length > 0 && (
                          <table>
                            <tr>
                              <th>{t('language')}</th>
                              <th>{t('read')}</th>
                              <th>{t('write')}</th>
                            </tr>
                            {values.access.map((item: any, index: number) => {
                              return (
                                <tr key={item.text}>
                                  <td>{item.text}</td>
                                  <td>
                                    <input
                                      name={'read'}
                                      id={`read-${item.value}`}
                                      value={1}
                                      type={'checkbox'}
                                      disabled
                                      checked={true}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name={'write'}
                                      id={`write-${item.value}`}
                                      value={values.access[index].write ? 1 : 0}
                                      type={'checkbox'}
                                      onChange={(e: any) => {
                                        values.access[index].write =
                                          e.target.checked;
                                        handleChange(e);
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        )}
                      </FieldWrapper>
                    )}
                  </FormElement>
                  <br />
                  <div className="k-form-buttons k-justify-content-end">
                    <Button
                      style={{
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                      primary={true}
                      look={'outline'}
                      type={'submit'}
                      disabled={errors.email || errors.name ? true : false}
                    >
                      {t('update')}
                    </Button>
                  </div>
                </fieldset>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}
