/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */

import {Controls, Player} from '@lottiefiles/react-lottie-player';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog} from '@progress/kendo-react-dialogs';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {Input} from '@progress/kendo-react-inputs';
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {Formik} from 'formik';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import yupPassword from 'yup-password';
import ProfileScreenImage from '../assets/images/pages/profileScreenImage.json';
import ChagePasswordModalImage from '../assets/images/modals/chagePasswordModalImage.json';
import {roles} from '../constant/roles';
import {getUserRole} from '../helpers/getUserRole';
import {userActions} from '../redux/actions';
yupPassword(yup);

function ProfileScreen() {
  const [changePasswordDialogVisible, setChangePasswordDialogVisible] =
    React.useState<boolean>(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user: any = JSON.parse(localStorage.getItem('user') || '{}');
  const languages = useSelector((state: any) => state.languages.items);
  const lngData = languages.map((x: any) => ({text: x.name, value: x.id}));

  const isAdmin = getUserRole() === roles.admin;
  const arr = Object.keys(roles).map((key: any) => ({text: key, value: key}));
  const schema = yup.object().shape({
    email: yup.string().email('form.email').required('form.required'),
    name: yup.string().required('form.required'),
    roles: yup.array().min(1, 'form.required'),
    access: isAdmin ? yup.array() : yup.array().min(1, 'form.required'),
  });

  const schemaPassword = yup.object().shape({
    currentPassword: yup.string().required('form.required'),
    password: yup
      .string()
      .minUppercase(1, 'form.minUppercase')
      .minNumbers(1, 'form.minNumber')
      .minSymbols(1, 'form.minSymbol')
      .min(8, 'form.password.min')
      .required('form.required'),
    confirmPassword: yup
      .string()
      .required('form.required')
      .oneOf([yup.ref('password'), null], 'form.password.confirmPassword'),
  });

  interface MyFormValues {
    email: string;
    name: string;
    roles: Array<string>;
    access: any;
  }

  interface PasswordForm {
    password: string;
    confirmPassword: string;
    currentPassword: string;
  }

  const toggleDialog = () => {
    setChangePasswordDialogVisible(!changePasswordDialogVisible);
  };

  const update = (values: MyFormValues) => {
    const data = {
      id: user.id,
      email: values.email,
      name: values.name,
      roles:
        values.roles &&
        values.roles.map((e: any) => {
          return e.value;
        }),
      access:
        values.access &&
        values.access.map((e: any) => {
          return {
            languageId: e.value,
            read: 1,
            write: e.write,
          };
        }),
    };
    dispatch(userActions.update(data));
  };

  const updatePassword = (values: PasswordForm) => {
    const data = {
      userId: user.id,
      email: user.email,
      currentPassword: values.currentPassword,
      password: values.password,
    };
    dispatch(userActions.updatePassword(data));
  };

  const initialValues: MyFormValues = {
    email: user.email,
    name: user.name,
    roles: user.roles.map((x: any) => ({text: x, value: x})),
    access: user.access
      ? user.access.map((x: any) => ({
          text: lngData.find((y: any) => y.value === x.languageId).text,
          value: x.languageId,
          read: x.read,
          write: x.write,
        }))
      : [],
  };

  const initialValuesPassword: PasswordForm = {
    password: '',
    confirmPassword: '',
    currentPassword: '',
  };

  useEffect(() => {
    {
      isAdmin &&
        initialValues.access.map((x: any) => {
          const t: any = document.getElementById(`read-${x.value}`);
          if (x.read) {
            t.checked = true;
          }
          const p: any = document.getElementById(`write-${x.value}`);
          if (x.write) {
            p.checked = true;
          }
        });
    }
  }, []);

  return (
    <div className="profileScreen">
      <Formik
        validationSchema={schema}
        onSubmit={(values: any) => {
          update(values);
        }}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            <Player
              autoplay
              loop
              src={ProfileScreenImage}
              style={{height: '300px', width: '500px'}}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
            <header>
              <h3>{t('profile')}</h3>
              <br />
            </header>
            <fieldset className={'k-form-fieldset'}>
              <FormElement>
                <FieldWrapper>
                  <Label editorId={'email'}>{t('email')}</Label>
                  <Input
                    autoFocus
                    name={'email'}
                    type={'email'}
                    value={values.email}
                    onChange={handleChange}
                    valid={!errors.email && values.email.length > 0}
                  />
                  {errors.email && (
                    <Error id={'email'}>
                      {t(`errors.${errors.email}`, {fieldType: t('email')})}
                    </Error>
                  )}
                  {!errors.email && values.email.length > 0 && (
                    <Hint id={'email'} style={{color: 'green'}}>
                      {t('looksGood')}
                    </Hint>
                  )}
                </FieldWrapper>
                <FieldWrapper>
                  <Label editorId={'name'}>{t('name')}</Label>
                  <Input
                    name={'name'}
                    type={'name'}
                    value={values.name}
                    onChange={handleChange}
                    valid={!errors.name && values.name.length > 0}
                  />
                  {errors.name && (
                    <Error id={'name'}>
                      {t(`errors.${errors.name}`, {fieldType: t('name')})}
                    </Error>
                  )}
                  {!errors.name && values.name.length > 0 && (
                    <Hint id={'name'} style={{color: 'green'}}>
                      {t('looksGood')}
                    </Hint>
                  )}
                </FieldWrapper>
                {isAdmin && (
                  <div>
                    <FieldWrapper>
                      <Label editorId={'roles'}>{t('roles')}</Label>
                      <MultiSelect
                        onChange={handleChange}
                        name="roles"
                        value={values.roles}
                        data={arr}
                        textField="text"
                      />
                      {errors.roles && (
                        <Error id={'roles'}>
                          {t(`errors.${errors.roles}`, {fieldType: t('roles')})}
                        </Error>
                      )}
                    </FieldWrapper>
                    {lngData && (
                      <FieldWrapper>
                        <Label editorId={'access'}>{t('access')}</Label>
                        <MultiSelect
                          onChange={(e: any) => {
                            handleChange(e);
                          }}
                          name="access"
                          value={values.access}
                          data={lngData}
                          textField="text"
                        />
                        {errors.access && (
                          <Error id={'access'}>
                            {t(`errors.${errors.access}`, {
                              fieldType: t('access'),
                            })}
                          </Error>
                        )}
                        {values.access && values.access.length > 0 && (
                          <table>
                            <tr>
                              <th>{t('language')}</th>
                              <th>{t('read')}</th>
                              <th>{t('write')}</th>
                            </tr>
                            {values.access.map((item: any, index: number) => {
                              return (
                                <tr key={item.text}>
                                  <td>{item.text}</td>
                                  <td>
                                    <input
                                      name={'read'}
                                      id={`read-${item.value}`}
                                      value={1}
                                      checked
                                      disabled
                                      type={'checkbox'}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name={'write'}
                                      id={`write-${item.value}`}
                                      value={values.access[index].write ? 1 : 0}
                                      type={'checkbox'}
                                      onChange={(e: any) => {
                                        values.access[index].write =
                                          e.target.checked;
                                        handleChange(e);
                                      }}
                                      disabled={!isAdmin}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        )}
                      </FieldWrapper>
                    )}
                  </div>
                )}
              </FormElement>
              <br />
              <div className="k-form-buttons k-justify-content-end">
                <Button
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  primary={true}
                  look={'outline'}
                  type={'submit'}
                  disabled={errors.email || errors.name ? true : false}
                >
                  {t('update')}
                </Button>
              </div>
            </fieldset>
          </form>
        )}
      </Formik>
      <div className="k-form-buttons k-justify-content-end">
        <Button
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 8,
            paddingRight: 8,
          }}
          primary={true}
          onClick={toggleDialog}
          look={'outline'}
        >
          {t('changePassword')}
        </Button>
      </div>
      {changePasswordDialogVisible && (
        <Dialog title={'Change Your Password'} onClose={toggleDialog}>
          <Formik
            validationSchema={schemaPassword}
            onSubmit={(values: any) => {
              updatePassword(values);
            }}
            initialValues={initialValuesPassword}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form onSubmit={handleSubmit} className="form">
                <Player
                  autoplay
                  loop
                  src={ChagePasswordModalImage}
                  style={{height: '150px', width: '250px'}}
                >
                  <Controls
                    visible={false}
                    buttons={['play', 'repeat', 'frame', 'debug']}
                  />
                </Player>
                <fieldset className={'k-form-fieldset'}>
                  <FormElement>
                    <FieldWrapper>
                      <Label editorId={'currentPassword'}>
                        {t('currentPassword')}
                      </Label>
                      <Input
                        autoFocus
                        name={'currentPassword'}
                        type={'password'}
                        value={values.currentPassword}
                        onChange={handleChange}
                        valid={
                          !errors.currentPassword &&
                          values.currentPassword.length > 0
                        }
                      />
                      {errors.currentPassword && (
                        <Error id={'currentPassword'}>
                          {t(`errors.${errors.currentPassword}`, {
                            fieldType: t('currentPassword'),
                          })}
                        </Error>
                      )}
                      {!errors.currentPassword &&
                        values.currentPassword.length > 0 && (
                          <Hint id={'currentPassword'} style={{color: 'green'}}>
                            {t('looksGood')}
                          </Hint>
                        )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'password'}>{t('password')}</Label>
                      <Input
                        autoFocus
                        name={'password'}
                        type={'password'}
                        value={values.password}
                        onChange={handleChange}
                        valid={!errors.password && values.password.length > 0}
                      />
                      {errors.password && (
                        <Error id={'password'}>
                          {t(`errors.${errors.password}`, {
                            fieldType: t('password'),
                            count: 1,
                          })}
                        </Error>
                      )}
                      {!errors.password && values.password.length > 0 && (
                        <Hint id={'password'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'confirmPassword'}>
                        {t('confirmPassword')}
                      </Label>
                      <Input
                        name={'confirmPassword'}
                        type={'password'}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        valid={
                          !errors.confirmPassword &&
                          values.confirmPassword.length > 0
                        }
                      />
                      {errors.confirmPassword && (
                        <Error id={'confirmPassword'}>
                          {t(`errors.${errors.confirmPassword}`, {
                            fieldType: t('confirmPassword'),
                            count: 1,
                          })}
                        </Error>
                      )}
                      {!errors.confirmPassword &&
                        values.confirmPassword.length > 0 && (
                          <Hint id={'confirmPassword'} style={{color: 'green'}}>
                            {t('looksGood')}
                          </Hint>
                        )}
                    </FieldWrapper>
                  </FormElement>
                  <br />
                  <div className="k-form-buttons k-justify-content-end">
                    <Button
                      style={{
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                      primary={true}
                      look={'outline'}
                      type={'submit'}
                      disabled={
                        errors.password || errors.confirmPassword ? true : false
                      }
                    >
                      {t('update')}
                    </Button>
                  </div>
                </fieldset>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}

export default ProfileScreen;
