/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {commentConstants} from '../constants';

export function comment(state = {data: []}, action: any) {
  switch (action.type) {
    case commentConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case commentConstants.GETALL_SUCCESS:
      return {
        data: action.comment,
      };
    case commentConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    case commentConstants.UPDATE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        data: state.data.map((comment: any) =>
          comment.id === action.comment.id
            ? {...comment, updating: true}
            : comment,
        ),
      };
    case commentConstants.UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map((comment: any) =>
          comment.id === action.comment.id
            ? {...comment, ...action.comment, updating: false}
            : comment,
        ),
      };
    case commentConstants.UPDATE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        updateError: action.error,
      };
    case commentConstants.INSERT_REQUEST:
      return {
        ...state,
        inserting: true,
      };
    case commentConstants.INSERT_SUCCESS:
      return {
        data: state.data.concat(action.payload),
      };
    case commentConstants.INSERT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        insertError: action.error,
      };
    case commentConstants.REMOVE_REQUEST:
      return {
        ...state,
        removing: true,
      };
    case commentConstants.REMOVE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (item: any, index: number) => item.id !== action.id,
        ),
      };
    case commentConstants.REMOVE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        removeError: action.error,
      };
    case commentConstants.CLEAN_COMMENTS:
      return {
        ...state,
        data: [],
      };
    case commentConstants.GET_EXIST_COMMENTS_COUNT_REQUEST:
      return {
        ...state,
        loadingExistCommentReq: true,
      };
    case commentConstants.GET_EXIST_COMMENTS_COUNT_SUCCESS:
      return {
        ...state,
        loadingExistCommentReq: false,
        existCommentsCount: action.data,
      };
    case commentConstants.GET_EXIST_COMMENTS_COUNT_FAILURE:
      return {
        ...state,
        errorExistCommentReq: action.error,
      };
    default:
      return state;
  }
}
