/* eslint-disable require-jsdoc */
import {localConstants} from '../constants';

export const localActions = {
  changeNavbarStatus,
  changeSelectedSection,
  setNavbarData,
  setOfflineModalStatus,
};

function changeNavbarStatus() {
  return {type: localConstants.CHANGE_NAVBAR_STATUS};
}

function changeSelectedSection(selectedSection: string) {
  return {type: localConstants.SELECTED_SECTION, selectedSection};
}

function setOfflineModalStatus(offlineModalStatus: boolean) {
  return {type: localConstants.SET_OFFLINE_MODAL_STATUS, offlineModalStatus};
}

function setNavbarData(navbarData: any) {
  return {type: localConstants.SET_NAVBAR_DATA, navbarData};
}
