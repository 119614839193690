/* eslint-disable require-jsdoc */
import {Button} from '@progress/kendo-react-buttons';
import {Badge, BadgeContainer} from '@progress/kendo-react-indicators';
import React from 'react';
import CommentDialog from '../dialogs/CommentDialog';

export const operations = (
  props: any,
  removeData: Function,
  showCommentDialog: boolean,
  changeCommentDialogStatus: any,
  modelEndpoint: any,
  currentUser: any = {},
  languageId: any = '',
  commentModalHeader: string = '',
) => {
  const {dataItem} = props;
  return (
    <td>
      {dataItem.inEdit ? (
        <div>
          <Button
            icon="delete"
            look="flat"
            onClick={() => removeData(dataItem.id)}
          />
          <Button
            icon="comment"
            look="flat"
            style={{marginLeft: 15}}
            onClick={() => changeCommentDialogStatus(!showCommentDialog)}
          />
          {showCommentDialog && (
            <CommentDialog
              props
              showCommentDialog={showCommentDialog}
              changeCommentDialogStatus={changeCommentDialogStatus}
              dataId={dataItem.id}
              modelEndpoint={modelEndpoint}
              currentUser={currentUser}
              languageId={languageId}
              header={commentModalHeader}
            />
          )}
        </div>
      ) : (
        <div>
          {dataItem.unreadComment && (
            <BadgeContainer>
              <span className="k-icon k-i-comment" />
              <Badge>{dataItem.unreadComment}</Badge>
            </BadgeContainer>
          )}
          {dataItem.commentExist && !dataItem.unreadComment && (
            <span className="k-icon k-i-comment" />
          )}
        </div>
      )}
    </td>
  );
};
