/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import React from 'react';
import HomeScreen from '../pages/HomeScreen';
import AlphabetScreen from '../pages/sections/AlphabetScreen';
import ConjugatedVerbsScreen from '../pages/sections/ConjugatedVerbsScreen';
import FlashCardScreen from '../pages/sections/FlashCardScreen';
import IsolatedSoundScreen from '../pages/sections/IsolatedSoundScreen';
import IsolatedSyllableScreen from '../pages/sections/IsolatedSyllableScreen';
import MorphologyScreen from '../pages/sections/MorphologyScreen';
import NounsWithArticleScreen from '../pages/sections/NounsWithArticleScreen';
import OnetestLetterNameScreen from '../pages/sections/OnetestLetterNameScreen';
import OnetestLetterSoundScreen from '../pages/sections/OnetestLetterSoundScreen';
import OnetestNonWordScreen from '../pages/sections/OnetestNonWordScreen';
import NonWordScreen from '../pages/sections/NonWordScreen';
import OnetestNumberIdentificationScreen from '../pages/sections/OnetestNumberIdentificationScreen';
import OnetestPhonologicalAwarenessScreen from '../pages/sections/OnetestPhonologicalAwarenessScreen';
import OnetestSyllableIdentificationScreen from '../pages/sections/OnetestSyllableIdentificationScreen';
import OnetestWordScreen from '../pages/sections/OnetestWordScreen';
import PhraseScreen from '../pages/sections/PhraseScreen';
import SentenceScreen from '../pages/sections/SentenceScreen';
import StoryScreen from '../pages/sections/StoryScreen';
import {changeSectionScheme} from './changeSectionScheme';

export function getRelatedSectionScreen(props: any, schemes: any) {
  switch (props.match.params.endpoint) {
    case 'alphabet':
      return (
        <AlphabetScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.Alphabet.properties,
          )}
        />
      );
    case 'isolatedSound':
      return (
        <IsolatedSoundScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.IsolatedSound.properties,
          )}
        />
      );
    case 'isolatedSyllable':
      return (
        <IsolatedSyllableScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.IsolatedSyllable.properties,
          )}
        />
      );
    case 'flashCard':
      return (
        <FlashCardScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.FlashCard.properties,
          )}
        />
      );
    case 'phrase':
      return (
        <PhraseScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.Phrase.properties,
          )}
        />
      );
    case 'sentence':
      return (
        <SentenceScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.Sentence.properties,
          )}
        />
      );
    case 'nounsWithArticle':
      return (
        <NounsWithArticleScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.NounsWithArticle.properties,
          )}
        />
      );
    case 'conjugatedVerb':
      return (
        <ConjugatedVerbsScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.ConjugatedVerb.properties,
          )}
        />
      );
    case 'onetestPhonologicalAwareness':
      return (
        <OnetestPhonologicalAwarenessScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestPhonologicalAwareness.properties,
          )}
        />
      );
    case 'onetestLetterSound':
      return (
        <OnetestLetterSoundScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestLetterSound.properties,
          )}
        />
      );
    case 'onetestLetterName':
      return (
        <OnetestLetterNameScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestLetterName.properties,
          )}
        />
      );
    case 'onetestWord':
      return (
        <OnetestWordScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestWord.properties,
          )}
        />
      );
    case 'onetestNonWord':
      return (
        <OnetestNonWordScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestNonWord.properties,
          )}
        />
      );
    case 'onetestNumberIdentification':
      return (
        <OnetestNumberIdentificationScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestNumberIdentification.properties,
          )}
        />
      );
    case 'onetestSyllableIdentification':
      return (
        <OnetestSyllableIdentificationScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.OnetestSyllableIdentification.properties,
          )}
        />
      );
    case 'morphology':
      return (
        <MorphologyScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.Morphology.properties,
          )}
        />
      );
    case 'nonWord':
      return (
        <NonWordScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.NonWord.properties,
          )}
        />
      );
    case 'story':
      return (
        <StoryScreen
          {...props}
          properties={changeSectionScheme(
            props.match.params.endpoint,
            schemes.Story.properties,
          )}
        />
      );
    default:
      return <HomeScreen {...props} />;
  }
}
