/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {
  Grid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {Input} from '@progress/kendo-react-inputs';
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {getFilterFieldType} from '../../helpers/getFilterFieldType';
import {languageActions, schemeActions} from '../../redux/actions';

const initialFilter: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};
const initialSort: Array<SortDescriptor> = [];

const schema = yup.object().shape({
  name: yup.string().required('form.required'),
  shortCode: yup.string().required('form.required'),
  textAlign: yup.object().required('form.required'),
});

export function LanguageScreen({schemes}: any) {
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = React.useState(initialSort);
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const create = (values: any) => {
    const newLanguage = {
      name: values.name,
      shortCode: values.shortCode,
      textAlign: values.textAlign && values.textAlign.value,
    };
    dispatch(languageActions.insert(newLanguage));
    setDialogVisible(!dialogVisible);
  };

  useEffect(() => {
    async () => {
      dispatch(languageActions.getAll());
      if (!schemes) {
        dispatch(schemeActions.getAll());
      }
    };
  }, []);

  let languageScheme = null;
  // TODO: The first "if block" doesn't work
  if (!schemes) {
    const schemes = useSelector((state: any) => state.schemes.scheme);
    languageScheme = schemes['Language'].properties;
  } else {
    languageScheme = schemes['Language'].properties;
  }
  const arr = languageScheme.textAlign.enum.map((key: any) => ({
    text: key,
    value: key,
  }));

  const languages = useSelector((state: any) => state.languages.items);
  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };
  return (
    <div className="section-template">
      <div>
        <h2 style={{textAlign: 'center'}}>{t('language')}</h2>
      </div>
      {languageScheme && languages && (
        <Grid
          data={orderBy(filterBy(languages, filter), sort)}
          filterable={true}
          filter={filter}
          onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridToolbar>
            <button
              title="Add"
              className="k-button k-primary"
              onClick={toggleDialog}
              style={{padding: 10}}
            >
              {t('create')}
            </button>
          </GridToolbar>
          <Column
            field={'Operations'}
            title={t('operations')}
            width="100"
            filterable={false}
          />
          {Object.entries(languageScheme).map(([key, value]: any, index) => {
            if (key !== 'languageId' && key !== 'id') {
              return (
                <Column
                  field={key}
                  filter={getFilterFieldType(value.type)}
                  title={t(key)}
                />
              );
            }
          })}
        </Grid>
      )}
      {dialogVisible && (
        <Dialog title={t('create')} onClose={toggleDialog}>
          <Formik
            validationSchema={schema}
            onSubmit={(values: any) => {
              create(values);
            }}
            initialValues={{
              name: '',
              shortCode: '',
              textAlign: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form onSubmit={handleSubmit} className="form">
                <fieldset className={'k-form-fieldset'}>
                  <FormElement>
                    <FieldWrapper>
                      <Label editorId={'name'}>{t('language')}</Label>
                      <Input
                        autoFocus
                        name={'name'}
                        type={'name'}
                        value={values.name}
                        onChange={handleChange}
                        valid={!errors.name && values.name.length > 0}
                      />
                      {errors.name && (
                        <Error id={'name'}>
                          {t(`errors.${errors.name}`, {fieldType: t('name')})}
                        </Error>
                      )}
                      {!errors.name && values.name.length > 0 && (
                        <Hint id={'name'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'shortCode'}>{t('shortCode')}</Label>
                      <Input
                        name={'shortCode'}
                        type={'shortCode'}
                        value={values.shortCode}
                        onChange={handleChange}
                        valid={!errors.shortCode && values.shortCode.length > 0}
                      />
                      {errors.shortCode && (
                        <Error id={'shortCode'}>
                          {t(`errors.${errors.shortCode}`, {
                            fieldType: t('shortCode'),
                          })}
                        </Error>
                      )}
                      {!errors.shortCode && values.shortCode.length > 0 && (
                        <Hint id={'shortCode'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'textAlign'}>{t('textAlign')}</Label>
                      <div>
                        <DropDownList
                          onChange={handleChange}
                          name="textAlign"
                          data={arr}
                          textField="text"
                        />
                      </div>
                      {errors.textAlign && (
                        <Error id={'textAlign'}>
                          {t(`errors.${errors.textAlign}`, {
                            fieldType: t('textAlign'),
                          })}
                        </Error>
                      )}
                    </FieldWrapper>
                  </FormElement>
                  <br />
                  <DialogActionsBar>
                    <div />
                    <div className="k-form-buttons k-justify-content-end">
                      <Button
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                        }}
                        primary={true}
                        look={'outline'}
                        type={'submit'}
                        disabled={
                          errors.shortCode || errors.name || errors.textAlign
                            ? true
                            : false
                        }
                      >
                        {t('create')}
                      </Button>
                    </div>
                  </DialogActionsBar>
                </fieldset>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}
