export const flashCardConstants = {
  GETALL_REQUEST: 'FLASH_CARD_GETALL_REQUEST',
  GETALL_SUCCESS: 'FLASH_CARD_GETALL_SUCCESS',
  GETALL_FAILURE: 'FLASH_CARD_GETALL_FAILURE',

  UPDATE_REQUEST: 'FLASH_CARD_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FLASH_CARD_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FLASH_CARD_UPDATE_FAILURE',

  INSERT_REQUEST: 'FLASH_CARD_INSERT_REQUEST',
  INSERT_SUCCESS: 'FLASH_CARD_INSERT_SUCCESS',
  INSERT_FAILURE: 'FLASH_CARD_INSERT_FAILURE',

  REMOVE_REQUEST: 'FLASH_CARD_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'FLASH_CARD_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'FLASH_CARD_REMOVE_FAILURE',

  APPROVE_ALL_REQUEST: 'FLASH_CARD_APPROVE_ALL_REQUEST',
  APPROVE_ALL_SUCCESS: 'FLASH_CARD_APPROVE_ALL_SUCCESS',
  APPROVE_ALL_FAILURE: 'FLASH_CARD_APPROVE_ALL_FAILURE',

  GENERATE_SPECIAL_XML_REQUEST: 'FLASH_CARD_GENERATE_SPECIAL_XML_REQUEST',
  GENERATE_SPECIAL_XML_SUCCESS: 'FLASH_CARD_GENERATE_SPECIAL_XML_SUCCESS',
  GENERATE_SPECIAL_XML_FAILURE: 'FLASH_CARD_GENERATE_SPECIAL_XML_FAILURE',

  MATCH_COMMENT_DATA: 'FLASH_CARD_MATCH_COMMENT_DATA',
  MATCH_COMMENT_COUNT_DATA: 'ALPHABET_MATCH_COMMENT_COUNT_DATA',
  REMOVE_UNREAD_NOTIFICATION: 'FLASH_CARD_REMOVE_UNREAD_NOTIFICATION',
};
