/* eslint-disable require-jsdoc */
import {alertActions} from '.';
import {languageService} from '../../services/language.service';
import {languageConstants} from '../constants';

export const languageActions = {
  getAll,
  selectLanguage,
  insert,
};

function getAll() {
  return async (dispatch: any) => {
    dispatch(request());

    await languageService.getAll().then(
      (languages: any) => dispatch(success(languages)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: languageConstants.GETALL_REQUEST};
  }
  function success(languages: any) {
    return {type: languageConstants.GETALL_SUCCESS, languages};
  }
  function failure(error: any) {
    return {type: languageConstants.GETALL_FAILURE, error};
  }
}

function insert(language: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await languageService.insert(language).then(
      (payload: any) => {
        dispatch(success(payload));
        dispatch(alertActions.success('insertCompleted'));
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request() {
    return {type: languageConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: languageConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: languageConstants.INSERT_FAILURE, error};
  }
}

function selectLanguage(languageIndex: number, languageId: string) {
  return (dispatch: any) => {
    localStorage.setItem('selectedLanguageId', languageId);
    return dispatch({
      type: languageConstants.SETINTERFACELANGUAGE_REQUEST,
      languageIndex,
    });
  };
}
