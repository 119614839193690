/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import {onetestNumberIdentificationService} from '../../services/onetestNumberIdentification.service';
import {onetestNumberIdentificationConstants} from '../constants';

export const onetestNumberIdentificationActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNumberIdentificationService.getAll(languageId).then(
      (onetestNumberIdentification: any) =>
        dispatch(success(onetestNumberIdentification)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestNumberIdentificationConstants.GETALL_REQUEST};
  }
  function success(onetestNumberIdentification: any) {
    return {
      type: onetestNumberIdentificationConstants.GETALL_SUCCESS,
      onetestNumberIdentification,
    };
  }
  function failure(error: any) {
    return {type: onetestNumberIdentificationConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    onetestNumberIdentificationService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: onetestNumberIdentificationConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: onetestNumberIdentificationConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {
      type: onetestNumberIdentificationConstants.UPDATE_FAILURE,
      letter,
      error,
    };
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNumberIdentificationService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestNumberIdentificationConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: onetestNumberIdentificationConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: onetestNumberIdentificationConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNumberIdentificationService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestNumberIdentificationConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: onetestNumberIdentificationConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: onetestNumberIdentificationConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNumberIdentificationService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: onetestNumberIdentificationConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: onetestNumberIdentificationConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {
      type: onetestNumberIdentificationConstants.APPROVE_ALL_FAILURE,
      error,
    };
  }
}
