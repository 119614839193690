/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {alphabetConstants} from '../constants';

export function alphabet(state = {data: []}, action: any) {
  switch (action.type) {
    case alphabetConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case alphabetConstants.GETALL_SUCCESS:
      return {
        data: action.alphabet,
      };
    case alphabetConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    case alphabetConstants.UPDATE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        data: state.data.map((letter: any) =>
          letter.id === action.letter.id ? {...letter, updating: true} : letter,
        ),
      };
    case alphabetConstants.UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map((letter: any) =>
          letter.id === action.letter.id
            ? {...letter, ...action.letter, updating: false}
            : letter,
        ),
      };
    case alphabetConstants.UPDATE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        updateError: action.error,
      };
    case alphabetConstants.INSERT_REQUEST:
      return {
        ...state,
        inserting: true,
      };
    case alphabetConstants.INSERT_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload),
      };
    case alphabetConstants.INSERT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        insertError: action.error,
      };
    case alphabetConstants.REMOVE_REQUEST:
      return {
        ...state,
        removing: true,
      };
    case alphabetConstants.REMOVE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (item: any, index: number) => item.id !== action.id,
        ),
      };
    case alphabetConstants.REMOVE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        removeError: action.error,
      };
    case alphabetConstants.MATCH_COMMENT_DATA:
      if (action.commentCount.length === 0) {
        return {
          ...state,
          unreadCommentMatched: true,
        };
      } else {
        const newData: any = state.data;
        for (let i = 0; i < action.commentCount.length; i++) {
          const e = action.commentCount[i];
          const index = state.data.findIndex(
            (x: any) => x.id === Object.keys(e)[0],
          );
          if (index !== -1) {
            newData[index]['unreadComment'] = Object.values(e)[0];
          }
        }
        return {
          data: newData,
          unreadCommentMatched: true,
        };
      }
    case alphabetConstants.MATCH_COMMENT_COUNT_DATA:
      if (action.commentCount.length === 0) {
        return {
          ...state,
          commentCountMatched: true,
        };
      } else {
        const newData: any = state.data;
        for (let i = 0; i < action.commentCount.length; i++) {
          const e = action.commentCount[i];
          const index = state.data.findIndex(
            (x: any) => x.id === Object.keys(e)[0],
          );
          if (index !== -1) {
            newData[index]['commentExist'] = true;
          }
        }
        return {
          ...state,
          data: newData,
          commentCountMatched: true,
        };
      }
    case alphabetConstants.REMOVE_UNREAD_NOTIFICATION:
      return {
        ...state,
        data: state.data.map((letter: any) =>
          letter.id === action.dataId
            ? {...letter, unreadComment: undefined}
            : letter,
        ),
      };
    case alphabetConstants.APPROVE_ALL_REQUEST:
      return {
        ...state,
        approving: true,
      };
    case alphabetConstants.APPROVE_ALL_SUCCESS:
      return {
        ...state,
        data: state.data.map((letter: any) => {
          return {...letter, status: 'Approved'};
        }),
      };
    case alphabetConstants.APPROVE_ALL_FAILURE:
      return {
        ...state,
        insertError: action.error,
      };
    default:
      return state;
  }
}
