/* eslint-disable require-jsdoc */
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {authHeader} from '../helpers/auth-header';
import {logout} from '../helpers/logout';

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  updatePassword,
  delete: _delete,
};
const apiUrl: any = process.env.REACT_APP_API_URL;

function login(email: string, password: string) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email, password}),
  };

  return fetch(`${apiUrl}/users/login`, requestOptions)
    .then(handleResponse)
    .then((user: any) => {
      const decoded: any = jwtDecode<JwtPayload>(user.token);
      const ExpandedUser = {
        token: user.token,
        roles: decoded.roles,
        access: decoded.access,
        email: decoded.email,
        exp: decoded.exp,
        iat: decoded.iat,
        id: decoded.id,
        name: decoded.name,
      };
      localStorage.setItem('user', JSON.stringify(ExpandedUser));

      return ExpandedUser;
    });
}

function getAll() {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id: any) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user: any) {
  const requestOptions: any = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user),
  };

  return fetch(`${apiUrl}/users/signup`, requestOptions).then(handleResponse);
}

function update(user: any) {
  const requestOptions: any = {
    method: 'PATCH',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(user),
  };

  return fetch(`${apiUrl}/users/${user.id}`, requestOptions).then(
    handleResponse,
  );
}

function updatePassword(userCredentials: any) {
  const requestOptions: any = {
    method: 'PATCH',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(userCredentials),
  };

  return fetch(
    `${apiUrl}/users/changePassword/${userCredentials.userId}`,
    requestOptions,
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id: any) {
  const requestOptions: any = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
