/* eslint-disable object-curly-spacing */
import i18next from 'i18next';
import '../i18n/config';

export const publicMenu = [
  { 'text': i18next.t('login'), 'icon': 'k-i-login', 'route': '/login' },
  { 'separator': true },
];

export const userMenu = [
  { text: i18next.t('home'), icon: 'k-i-inbox', id: 1, route: '/' },
  { separator: true },
  {
    text: 'Onetab',
    id: 2,
    ['data-expanded']: false,
    icon: 'k-i-globe',
    route: '/sections/onetab',
  },
  {
    text: i18next.t('menu.section.alphabets'),
    id: 3,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/alphabet',
  },
  {
    text: i18next.t('menu.section.conjugatedVerbs'),
    id: 4,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/conjugatedVerb',
  },
  {
    text: i18next.t('menu.section.flashCards'),
    id: 5,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/flashCard',
  },
  {
    text: i18next.t('menu.section.isolatedSounds'),
    id: 6,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/isolatedSound',
  },
  {
    text: i18next.t('menu.section.isolatedSyllables'),
    id: 7,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/isolatedSyllable',
  },
  {
    text: i18next.t('menu.section.morphology'),
    id: 8,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/morphology',
  },
  {
    text: i18next.t('menu.section.nounsWithArticles'),
    id: 9,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/nounsWithArticle',
  },
  {
    text: i18next.t('menu.section.nonWord'),
    id: 10,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/nonWord',
  },
  {
    text: i18next.t('menu.section.phrase'),
    id: 11,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/phrase',
  },
  {
    text: i18next.t('menu.section.sentence'),
    id: 12,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/sentence',
  },
  {
    text: i18next.t('menu.section.story'),
    id: 13,
    parentId: 2,
    icon: 'k-i-window-minimize',
    route: '/sections/story',
  },
  {
    text: 'Onetest',
    id: 14,
    ['data-expanded']: false,
    icon: 'k-i-marker-pin-target',
    route: '/sections/onetest',
  },
  {
    text: i18next.t('menu.section.onetest.letterName'),
    id: 15,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestLetterName',
  },
  {
    text: i18next.t('menu.section.onetest.letterSound'),
    id: 16,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestLetterSound',
  },
  {
    text: i18next.t('menu.section.onetest.nonWord'),
    id: 17,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestNonWord',
  },
  {
    text: i18next.t('menu.section.onetest.numberIdentification'),
    id: 18,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestNumberIdentification',
  },
  {
    text: i18next.t('menu.section.onetest.phonologicalAwareness'),
    id: 19,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestPhonologicalAwareness',
  },
  {
    text: i18next.t('menu.section.onetest.syllableIdentification'),
    id: 20,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestSyllableIdentification',
  },
  {
    text: i18next.t('menu.section.onetest.word'),
    id: 21,
    parentId: 14,
    icon: 'k-i-window-minimize',
    route: '/sections/onetestWord',
  },
];

export const adminMenu = [
  { separator: true },
  {
    text: 'Admin',
    id: 21,
    ['data-expanded']: false,
    icon: 'k-i-lock',
    route: '/sections/admin',
  },
  {
    text: i18next.t('language'),
    id: 22,
    parentId: 21,
    icon: 'k-i-window-minimize',
    route: '/admin/language',
  },
  {
    text: i18next.t('register'),
    id: 23,
    parentId: 21,
    icon: 'k-i-window-minimize',
    route: '/register',
  },
  {
    text: i18next.t('menu.section.section'),
    id: 24,
    parentId: 21,
    icon: 'k-i-window-minimize',
    route: '/admin/section',
  },
  {
    text: i18next.t('menu.section.tag'),
    id: 25,
    parentId: 21,
    icon: 'k-i-window-minimize',
    route: '/admin/tag',
  },
  {
    text: i18next.t('menu.section.users'),
    id: 26,
    parentId: 21,
    icon: 'k-i-window-minimize',
    route: '/admin/users',
  },
];
