import React from 'react';
import {Controls, Player} from '@lottiefiles/react-lottie-player';
import {Link} from 'react-router-dom';
import LogoutScreenImage from '../assets/images/pages/logoutScreenImage.json';

/* eslint-disable require-jsdoc */
function LogoutScreen() {
  return (
    <div className="logoutScreen">
      <Player
        autoplay
        loop
        src={LogoutScreenImage}
        style={{height: '300px', width: '500px'}}
      >
        <Controls
          visible={false}
          buttons={['play', 'repeat', 'frame', 'debug']}
        />
      </Player>
      <h3>Logout successful</h3>
      <p>
        You have successfully logged out of the Language Database Tool. You may{' '}
        <Link to="/login">log in</Link> again.
      </p>
    </div>
  );
}
export default LogoutScreen;
