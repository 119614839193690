/* eslint-disable require-jsdoc */
import {alertActions} from '.';
import {sectionService} from '../../services/section.service';
import {sectionConstants} from '../constants';

export const sectionActions = {
  getAll,
  insert,
};

function getAll() {
  return async (dispatch: any) => {
    dispatch(request());

    await sectionService.getAll().then(
      (sections: any) => dispatch(success(sections)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: sectionConstants.GETALL_REQUEST};
  }
  function success(sections: any) {
    return {type: sectionConstants.GETALL_SUCCESS, sections};
  }
  function failure(error: any) {
    return {type: sectionConstants.GETALL_FAILURE, error};
  }
}

function insert(section: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await sectionService.insert(section).then(
      (payload: any) => {
        dispatch(success(payload));
        dispatch(alertActions.success('insertCompleted'));
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request() {
    return {type: sectionConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: sectionConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: sectionConstants.INSERT_FAILURE, error};
  }
}
