/* eslint-disable indent */
/* eslint-disable require-jsdoc */
export const getFilterFieldType = (type: string) => {
  switch (type) {
    case 'string':
      return 'text';
    case 'boolean':
      return 'boolean';
    case 'number':
      return 'numeric';
    default:
      return 'text';
  }
};
