/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {GridCellProps} from '@progress/kendo-react-grid';
import {booleanField} from '../components/fields/BooleanField';
import {dropdownField} from '../components/fields/DropdownField';
import {imageField} from '../components/fields/ImageField';
import {numberField} from '../components/fields/NumberField';
import {statusField} from '../components/fields/StatusField';
import {stringField} from '../components/fields/StringField';
import {textAreaField} from '../components/fields/TextAreaField';
import {tooltipField} from '../components/fields/TooltipField';

export const relatedField = (
  props: GridCellProps,
  params: any,
  inputName: any,
  value: any,
  allData: any,
  updateData: Function,
) => {
  switch (params.type) {
    case 'string':
      if (params.enum) {
        if (inputName === 'status') {
          return statusField(props, params.enum, updateData);
        } else {
          return dropdownField(props, params, updateData);
        }
      } else {
        return stringField(
          props,
          value,
          params.defaultValue,
          params.defaultValueBaseFrom,
          params.uniqueIdDefaultValue,
          params.disabled ? params.disabled : false,
          allData,
          updateData,
        );
      }
    case 'boolean':
      return booleanField(props, updateData);
    case 'number':
      return numberField(props, updateData);
    case 'textArea':
      return textAreaField(
        props,
        value,
        params.defaultValue,
        params.defaultValueBaseFrom,
        params.uniqueIdDefaultValue,
        params.disabled ? params.disabled : false,
        allData,
        updateData,
      );
    case 'image':
      return imageField(props, value, params.uniqueIdDefaultValue, updateData);
    case 'tooltip':
      return tooltipField(
        props,
        value,
        params.defaultValue,
        params.defaultValueBaseFrom,
        params.uniqueIdDefaultValue,
        params.disabled ? params.disabled : false,
        allData,
        updateData,
      );
    default:
      return stringField(
        props,
        value,
        params.defaultValue,
        params.defaultValueBaseFrom,
        params.uniqueIdDefaultValue,
        params.disabled ? params.disabled : false,
        allData,
        updateData,
      );
  }
};
