/* eslint-disable max-len */
/* eslint-disable indent */
import {alphabetService} from '../services/alphabet.service';
import {conjugatedVerbService} from '../services/conjugatedVerb.service';
import {flashCardService} from '../services/flashCard.service';
import {isolatedSoundService} from '../services/isolatedSound.service';
import {isolatedSyllableService} from '../services/isolatedSyllable.service';
import {morphologyService} from '../services/morphology.service';
import {nounsWithArticleService} from '../services/nounsWithArticle.service';
import {nonWordService} from '../services/nonWord.service';
import {onetestLetterNameService} from '../services/onetestLetterName.service';
import {onetestLetterSoundService} from '../services/onetestLetterSound.service';
import {onetestNonWordService} from '../services/onetestNonWord.service';
import {onetestNumberIdentificationService} from '../services/onetestNumberIdentification.service';
import {onetestPhonologicalAwarenessService} from '../services/onetestPhonologicalAwareness.service';
import {onetestSyllableIdentificationService} from '../services/onetestSyllableIdentification.service';
import {onetestWordService} from '../services/onetestWord.service';
import {phraseService} from '../services/phrase.service';
import {sentenceService} from '../services/sentence.service';
import {storyService} from '../services/story.service';

/* eslint-disable require-jsdoc */
export const getRelatedSectionService = (type: string) => {
  const sectionPath = '/sections/';
  switch (type) {
    case `${sectionPath}alphabet`:
      return alphabetService;
    case `${sectionPath}flashCard`:
      return flashCardService;
    case `${sectionPath}isolatedSound`:
      return isolatedSoundService;
    case `${sectionPath}isolatedSyllable`:
      return isolatedSyllableService;
    case `${sectionPath}nonWord`:
      return nonWordService;
    case `${sectionPath}onetestLetterName`:
      return onetestLetterNameService;
    case `${sectionPath}onetestLetterSound`:
      return onetestLetterSoundService;
    case `${sectionPath}onetestNonWord`:
      return onetestNonWordService;
    case `${sectionPath}onetestNumberIdentification`:
      return onetestNumberIdentificationService;
    case `${sectionPath}onetestPhonologicalAwareness`:
      return onetestPhonologicalAwarenessService;
    case `${sectionPath}onetestSyllableIdentification`:
      return onetestSyllableIdentificationService;
    case `${sectionPath}onetestWord`:
      return onetestWordService;
    case `${sectionPath}sentence`:
      return sentenceService;
    case `${sectionPath}phrase`:
      return phraseService;
    case `${sectionPath}conjugatedVerb`:
      return conjugatedVerbService;
    case `${sectionPath}nounsWithArticle`:
      return nounsWithArticleService;
    case `${sectionPath}morphology`:
      return morphologyService;
    case `${sectionPath}story`:
      return storyService;
    default:
      return alphabetService; // TODO:
  }
};
