/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {Popup} from '@progress/kendo-react-popup';
import React from 'react';
import ImportCSV from '../ImportCSV';

function ImportFromCSVPopup({
  props,
  importAnchor,
  showImportPopup,
  changeImportPopupStatus,
  menuKey,
  modelEndPoint,
  user,
}: any) {
  return (
    <>
      <Popup
        anchor={importAnchor.current}
        show={showImportPopup}
        popupClass={'popup-content'}
        style={{width: 600}}
      >
        <ImportCSV
          props={props}
          changeImportPopupStatus={changeImportPopupStatus}
          menuKey={menuKey}
          modelEndPoint={modelEndPoint}
          user={user}
        />
      </Popup>
    </>
  );
}

export default ImportFromCSVPopup;
