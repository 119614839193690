/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {schemesConstants} from '../constants';

export function schemes(state = {}, action: any) {
  switch (action.type) {
    case schemesConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case schemesConstants.GETALL_SUCCESS:
      return {
        scheme: action.schemes,
      };
    case schemesConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
