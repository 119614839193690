/* eslint-disable require-jsdoc */
import React from 'react';
import ExportAudioX from './buttons/ExportAudioX';
import GenerateSpecialXML from './buttons/GenerateSpecialXML';

function AdminTableMenu({props}: any) {
  return (
    <div style={{display: 'flex', gap: '1rem'}}>
      {props.approveAll && (
        <ExportAudioX props={props} languageId={props.selected.id} />
      )}
      {props.generateSpecialXMLFile && <GenerateSpecialXML props={props} />}
    </div>
  );
}
export default AdminTableMenu;
