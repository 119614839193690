/* eslint-disable require-jsdoc */
import {authHeader} from '../helpers/auth-header';
import {logout} from '../helpers/logout';
export const schemeService = {
  getAll,
  // getById,
  // update,
  // delete: _delete,
};
const apiUrl: any = process.env.REACT_APP_API_URL;

async function getAll() {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  const data = await fetch(
    `${apiUrl}/explorer/openapi.json`,
    requestOptions,
  ).then(handleResponse);
  return data.components.schemas;
}

async function handleResponse(response: any) {
  return await response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
