/* eslint-disable require-jsdoc */
import {alertActions, localActions} from '.';
import {userMenu} from '../../constant/navbarItems';
import {history} from '../../helpers/history';
import {userService} from '../../services/user.service';
import {userConstants} from '../constants';

export const userActions = {
  login,
  logout,
  update,
  updateById,
  updatePassword,
  register,
  getAll,
  delete: _delete,
};

function login(email: string, password: string, from: any) {
  return (dispatch: any) => {
    dispatch(request({email}));
    userService.login(email, password).then(
      (user: any) => {
        dispatch(success(user));
        dispatch(localActions.setNavbarData(userMenu));
        history.push(from);
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(user: any) {
    return {type: userConstants.LOGIN_REQUEST, user};
  }
  function success(user: any) {
    return {type: userConstants.LOGIN_SUCCESS, user};
  }
  function failure(error: Error) {
    return {type: userConstants.LOGIN_FAILURE, error};
  }
}

function logout() {
  userService.logout();
  history.push({pathname: '/logout'});
  return {type: 'LOG_OUT'};
}

function register(user: any) {
  return (dispatch: any) => {
    dispatch(request(user));

    userService.register(user).then(
      (user: any) => {
        dispatch(success(user));
        history.push('/login');
        dispatch(alertActions.success('registrationSuccessful'));
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(user: any) {
    return {type: userConstants.REGISTER_REQUEST, user};
  }
  function success(user: any) {
    return {type: userConstants.REGISTER_SUCCESS, user};
  }
  function failure(error: any) {
    return {type: userConstants.REGISTER_FAILURE, error};
  }
}

function update(user: any) {
  return (dispatch: any) => {
    dispatch(request(user));

    userService.update(user).then(
      (user: any) => {
        dispatch(success(user));
        logout();
        dispatch(alertActions.success('updateSuccessful'));
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(user: any) {
    return {type: userConstants.UPDATE_REQUEST, user};
  }
  function success(user: any) {
    return {type: userConstants.UPDATE_SUCCESS, user};
  }
  function failure(error: any) {
    return {type: userConstants.UPDATE_FAILURE, error};
  }
}

function updateById(user: any) {
  return (dispatch: any) => {
    dispatch(request(user));

    userService.update(user).then(
      () => {
        dispatch(success(user));
        dispatch(alertActions.success('updateProfileSuccessful'));
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(user: any) {
    return {type: userConstants.UPDATE_BY_ID_REQUEST, user};
  }
  function success(user: any) {
    return {type: userConstants.UPDATE_BY_ID_SUCCESS, user};
  }
  function failure(error: any) {
    return {type: userConstants.UPDATE_BY_ID_FAILURE, error};
  }
}

function updatePassword(user: any) {
  return (dispatch: any) => {
    dispatch(request(user));

    userService.updatePassword(user).then(
      () => {
        logout();
        dispatch(alertActions.success('updatePasswordSuccessful'));
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function request(user: any) {
    return {type: userConstants.UPDATE_PASSWORD_REQUEST, user};
  }
  function failure(error: any) {
    return {type: userConstants.UPDATE_PASSWORD_FAILURE, error};
  }
}

function getAll() {
  return (dispatch: any) => {
    dispatch(request());

    userService.getAll().then(
      (users: any) => dispatch(success(users)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: userConstants.GETALL_REQUEST};
  }
  function success(users: any) {
    return {type: userConstants.GETALL_SUCCESS, users};
  }
  function failure(error: any) {
    return {type: userConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id: any) {
  return (dispatch: any) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(id, error.toString())),
    );
  };

  function request(id: any) {
    return {type: userConstants.DELETE_REQUEST, id};
  }
  function success(id: any) {
    return {type: userConstants.DELETE_SUCCESS, id};
  }
  function failure(id: any, error: any) {
    return {type: userConstants.DELETE_FAILURE, id, error};
  }
}
