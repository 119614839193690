/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {FieldWrapper, FormElement} from '@progress/kendo-react-form';
import {
  Grid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {Input} from '@progress/kendo-react-inputs';
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
import {getFilterFieldType} from '../../helpers/getFilterFieldType';
import {schemeActions, sectionActions} from '../../redux/actions';

const initialFilter: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};
const initialSort: Array<SortDescriptor> = [];

const schema = yup.object().shape({
  name: yup.string().required('form.required'),
  endpoint: yup.string().required('form.required'),
  rank: yup.number().required('form.required'),
});

export function SectionScreen({schemes}: any) {
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = React.useState(initialSort);
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const create = (values: any) => {
    const newSection = {
      name: values.name,
      endpoint: values.endpoint,
      rank: parseInt(values.rank, 10),
      updatedDate: new Date(),
    };
    dispatch(sectionActions.insert(newSection));
    setDialogVisible(!dialogVisible);
  };

  useEffect(() => {
    async () => {
      dispatch(sectionActions.getAll());
      if (!schemes) {
        dispatch(schemeActions.getAll());
      }
    };
  }, []);

  let sectionScheme = null;
  // TODO: The first "if block" doesn't work
  if (!schemes) {
    const schemes = useSelector((state: any) => state.schemes.scheme);
    sectionScheme = schemes['Section'].properties;
  } else {
    sectionScheme = schemes['Section'].properties;
  }

  const sections = useSelector((state: any) => state.sections.items);
  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };
  return (
    <div className="section-template">
      <div>
        <h2 style={{textAlign: 'center'}}>{t('menu.section.section')}</h2>
      </div>
      {sectionScheme && sections && (
        <Grid
          data={orderBy(filterBy(sections, filter), sort)}
          filterable={true}
          filter={filter}
          onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
        >
          <GridToolbar>
            <button
              title="Add"
              className="k-button k-primary"
              onClick={toggleDialog}
              style={{padding: 10}}
            >
              {t('create')}
            </button>
          </GridToolbar>
          <Column
            field={'Operations'}
            title={t('operations')}
            width="100"
            filterable={false}
          />
          {Object.entries(sectionScheme).map(([key, value]: any, index) => {
            if (key !== 'sectionId' && key !== 'id') {
              return (
                <Column
                  field={key}
                  filter={getFilterFieldType(value.type)}
                  title={t(key)}
                />
              );
            }
          })}
        </Grid>
      )}
      {dialogVisible && (
        <Dialog title={t('create')} onClose={toggleDialog}>
          <Formik
            validationSchema={schema}
            onSubmit={(values: any) => {
              create(values);
            }}
            initialValues={{
              name: '',
              endpoint: '',
              rank: 0,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <form onSubmit={handleSubmit} className="form">
                <fieldset className={'k-form-fieldset'}>
                  <FormElement>
                    <FieldWrapper>
                      <Label editorId={'name'}>{t('name')}</Label>
                      <Input
                        autoFocus
                        name={'name'}
                        type={'name'}
                        value={values.name}
                        onChange={handleChange}
                        valid={!errors.name && values.name.length > 0}
                      />
                      {errors.name && (
                        <Error id={'name'}>
                          {t(`errors.${errors.name}`, {fieldType: t('name')})}
                        </Error>
                      )}
                      {!errors.name && values.name.length > 0 && (
                        <Hint id={'name'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'endpoint'}>{t('endpoint')}</Label>
                      <Input
                        name={'endpoint'}
                        type={'endpoint'}
                        value={values.endpoint}
                        onChange={handleChange}
                        valid={!errors.endpoint && values.endpoint.length > 0}
                      />
                      {errors.endpoint && (
                        <Error id={'endpoint'}>
                          {t(`errors.${errors.endpoint}`, {
                            fieldType: t('endpoint'),
                          })}
                        </Error>
                      )}
                      {!errors.endpoint && values.endpoint.length > 0 && (
                        <Hint id={'endpoint'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                    <FieldWrapper>
                      <Label editorId={'rank'}>{t('rank')}</Label>
                      <Input
                        name={'rank'}
                        type={'number'}
                        value={values.rank}
                        onChange={handleChange}
                        valid={!errors.rank && values.rank !== 0}
                      />
                      {errors.rank && (
                        <Error id={'rank'}>
                          {t(`errors.${errors.rank}`, {
                            fieldType: t('rank'),
                          })}
                        </Error>
                      )}
                      {!errors.rank && values.rank !== 0 && (
                        <Hint id={'rank'} style={{color: 'green'}}>
                          {t('looksGood')}
                        </Hint>
                      )}
                    </FieldWrapper>
                  </FormElement>
                  <br />
                  <DialogActionsBar>
                    <div />
                    <div className="k-form-buttons k-justify-content-end">
                      <Button
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                        }}
                        primary={true}
                        look={'outline'}
                        type={'submit'}
                        disabled={
                          errors.endpoint || errors.name || errors.rank
                            ? true
                            : false
                        }
                      >
                        {t('create')}
                      </Button>
                    </div>
                  </DialogActionsBar>
                </fieldset>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}
