/* eslint-disable indent */
/* eslint-disable no-invalid-this */
/* eslint-disable arrow-parens */
/* eslint-disable require-jsdoc */
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {Button} from '@progress/kendo-react-buttons';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import {relatedLanguageRules} from '../../helpers/relatedLanguageRules';
import {
  Grid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import {getUserRole} from '../../helpers/getUserRole';
// import {Sticky, StickyContainer} from 'react-sticky';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import AdminTableMenu from '../../components/AdminTableMenu';
import ImportFromCSV from '../../components/buttons/ImportFromCSV';
import {operations} from '../../components/fields/Operations';
import ImportFromCSVPopup from '../../components/popups/ImportFromCSVPopup';
import {ModelEndpoints} from '../../constant/modelEndpoints';
import {roles} from '../../constant/roles';
import {getFilterFieldType} from '../../helpers/getFilterFieldType';
import {relatedField} from '../../helpers/relatedField';
import {
  commentActions,
  generalActions,
  notificationActions,
  onetestWordActions,
} from '../../redux/actions';
import NonexportedChanges from '../../components/modals/NonexportedChanges';

type MyProps = {
  // using `interface` is also ok
  t: any;
  properties: any;
  getAll: any;
  update: any;
  insert: any;
  remove: any;
  data: any;
  selected: any;
  approveAll: any;
  generateSpecialXMLFile: any;
  getlatestComment: any;
  unreadCommentMatched: any;
  getExistCommentsCount: any;
  commentCountMatched: any;
  specialXML: any;
};

type MyState = {
  editID: string | null;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
  user: string | null;
  showImportPopup: boolean;
  showCommentDialog: boolean;
  positionY: number;
};

class OnetestWordScreen extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      editID: null,
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [],
      user: localStorage.getItem('user') || null,
      showImportPopup: false,
      showCommentDialog: false,
      positionY: 0,
    };
  }

  _export: ExcelExport | null = null;
  export = () => {
    if (this._export !== null) {
      this._export.save();
    }
  };

  importAnchor: any = React.createRef();
  changeImportPopupStatus = (status: boolean) => {
    this.setState({showImportPopup: status});
  };

  changeCommentDialogStatus = (status: boolean) => {
    this.setState({showCommentDialog: status});
  };

  // changeScrollY = () => {
  //   this.setState({
  //     positionY: window.scrollY,
  //   });
  // };

  // componentWillUnmount = async () => {
  //   window.removeEventListener('scroll', this.changeScrollY);
  // };

  componentDidMount = async () => {
    const languageId = this.props.selected.id;
    await this.props.getAll(languageId);
    // window.addEventListener('scroll', this.changeScrollY);
    await this.props.getlatestComment(ModelEndpoints.OnetestWord, languageId);
    await this.props.getExistCommentsCount(
      ModelEndpoints.OnetestWord,
      languageId,
    );
  };

  addNewData() {
    this.props.insert({languageId: this.props.selected.id, status: 'Edited'});
  }

  rowClick = (event: GridRowClickEvent) => {
    const {user} = this.state;
    const rules = relatedLanguageRules(user, this.props.selected.id);
    const isAdmin = getUserRole() === roles.admin;
    if (rules.write || isAdmin) {
      this.setState({
        editID: event.dataItem.id,
      });
    }
  };

  itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const obj: any = {
      id: event.dataItem.id,
    };
    obj[field] = event.value;
    obj['status'] = 'Edited';
    this.props.update(obj);
  };

  closeEdit = (event: any) => {
    if (event.target === event.currentTarget) {
      this.setState({editID: null});
    }
  };

  addRecord = () => {
    this.props.insert({languageId: this.props.selected.id, status: 'Edited'});
  };

  render() {
    const {user} = this.state;
    const rules = relatedLanguageRules(user, this.props.selected.id);
    const isAdmin = getUserRole() === roles.admin;
    return (
      <div className="section-template">
        {/* <StickyContainer> */}
        <div>
          <h2 style={{textAlign: 'center'}}>
            {this.props.t('menu.section.onetest.word')}
          </h2>
          {/* <Sticky>
              {({style}) => ( */}
          <div
            style={{
              // ...style,
              zIndex: 99999,
              display: 'flex',
              gap: '1rem',
              padding: '0.5rem',
              backgroundColor: '#fafafa',
            }}
          >
            {user && JSON.parse(user).roles[0] === roles.admin && (
              <AdminTableMenu props={this.props} />
            )}
            <div style={{display: 'flex', gap: '1rem'}}>
              <button
                title="Export Excel"
                className="k-button k-primary"
                onClick={() => {
                  this.export();
                }}
                style={{padding: 10}}
              >
                Export to Excel
              </button>
              {(rules.write || isAdmin) && (
                <ImportFromCSV
                  importAnchor={this.importAnchor}
                  showImportPopup={this.state.showImportPopup}
                  changeImportPopupStatus={this.changeImportPopupStatus}
                />
              )}
            </div>
            {this.props.data && <NonexportedChanges data={this.props.data} />}
          </div>
          {/* )}
            </Sticky> */}
        </div>
        <div className={this.state.positionY > 134 ? 'fixHeader' : ''}>
          {this.props.data &&
            this.props.unreadCommentMatched &&
            this.props.commentCountMatched && (
              <ExcelExport
                data={orderBy(
                  filterBy(
                    this.props.data.map((item: any) => ({
                      ...item,
                      inEdit: item.id === this.state.editID,
                    })),
                    this.state.filter,
                  ),
                  this.state.sort,
                )}
                ref={exporter => (this._export = exporter)}
              >
                <Grid
                  data={orderBy(
                    filterBy(
                      this.props.data.map((item: any) => ({
                        ...item,
                        inEdit: item.id === this.state.editID,
                      })),
                      this.state.filter,
                    ),
                    this.state.sort,
                  )}
                  filterable={true}
                  filter={this.state.filter}
                  editField="inEdit"
                  onRowClick={this.rowClick}
                  onItemChange={this.itemChange}
                  onFilterChange={(e: GridFilterChangeEvent) => {
                    this.setState({
                      filter: e.filter,
                    });
                  }}
                  sort={this.state.sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                    this.setState({
                      sort: e.sort,
                    });
                  }}
                  sortable={true}
                >
                  <Column
                    field={'Operations'}
                    title={this.props.t('operations')}
                    width="100"
                    editable={false}
                    filterable={false}
                    cell={p => {
                      return operations(
                        p,
                        this.props.remove,
                        this.state.showCommentDialog,
                        this.changeCommentDialogStatus,
                        ModelEndpoints.OnetestWord,
                        user,
                        this.props.selected.id,
                      );
                    }}
                  />
                  {Object.entries(this.props.properties).map(
                    ([key, value]: any, index) => {
                      if (key !== 'languageId' && key !== 'id') {
                        return (
                          <Column
                            field={key}
                            filter={getFilterFieldType(value.type)}
                            cell={p => {
                              return relatedField(
                                p,
                                value,
                                Object.keys(this.props.properties)[index],
                                this.props.properties[
                                  Object.keys(this.props.properties)[index]
                                ],
                                this.props.data,
                                this.props.update,
                              );
                            }}
                            title={this.props.t(
                              this.props.properties[key].description,
                            )}
                          />
                        );
                      }
                    },
                  )}
                </Grid>
              </ExcelExport>
            )}
        </div>
        {/* </StickyContainer> */}
        <ImportFromCSVPopup
          props={this.props}
          importAnchor={this.importAnchor}
          showImportPopup={this.state.showImportPopup}
          changeImportPopupStatus={this.changeImportPopupStatus}
          menuKey={'menu.section.onetest.word'}
          modelEndPoint={ModelEndpoints.OnetestWord}
          user={user}
        />
        {(rules.write || isAdmin) && (
          <Button
            icon="add"
            onClick={() => {
              this.addNewData();
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {data, unreadCommentMatched, commentCountMatched, specialXML} =
    state.onetestWord;
  const {selected} = state.languages;
  return {
    data,
    unreadCommentMatched,
    commentCountMatched,
    selected,
    specialXML,
  };
};

const mapDispatchToProps = {
  getAll: onetestWordActions.getAll,
  update: onetestWordActions.update,
  insert: onetestWordActions.insert,
  remove: onetestWordActions.remove,
  approveAll: onetestWordActions.approveAll,
  generateSpecialXMLFile: generalActions.generateSpecialXMLFile,
  getlatestComment: notificationActions.getlatestComment,
  getExistCommentsCount: commentActions.getExistCommentsCount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(OnetestWordScreen));
