/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {userConstants} from '../constants';

let user: any = localStorage.getItem('user') || null;
if (user) {
  user = JSON.parse(user);
}
const initialState = user ? {loggedIn: true, user} : {};

export function authentication(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    default:
      return state;
  }
}
