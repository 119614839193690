/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {localConstants} from '../constants';

export function local(state = {navbarStatus: false}, action: any) {
  switch (action.type) {
    case localConstants.CHANGE_NAVBAR_STATUS:
      return {
        ...state,
        navbarStatus: !state.navbarStatus,
      };
    case localConstants.SET_OFFLINE_MODAL_STATUS:
      return {
        ...state,
        offlineModalStatus: action.offlineModalStatus,
      };
    case localConstants.SET_NAVBAR_DATA:
      return {
        ...state,
        navbarData: action.navbarData,
      };
    case localConstants.SELECTED_SECTION:
      return {
        ...state,
        selectedSection: action.selectedSection,
      };
    default:
      return state;
  }
}
