/* eslint-disable require-jsdoc */
import {alphabetService} from '../../services/alphabet.service';
import {alphabetConstants} from '../constants';

export const alphabetActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await alphabetService.getAll(languageId).then(
      (alphabets: any) => dispatch(success(alphabets)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: alphabetConstants.GETALL_REQUEST};
  }
  function success(alphabet: any) {
    return {type: alphabetConstants.GETALL_SUCCESS, alphabet};
  }
  function failure(error: any) {
    return {type: alphabetConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    alphabetService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: alphabetConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: alphabetConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: alphabetConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await alphabetService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: alphabetConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: alphabetConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: alphabetConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await alphabetService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: alphabetConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: alphabetConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: alphabetConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await alphabetService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: alphabetConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: alphabetConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: alphabetConstants.APPROVE_ALL_FAILURE, error};
  }
}
