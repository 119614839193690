import {DropDownList} from '@progress/kendo-react-dropdowns';
import * as React from 'react';

export const dropdownField = (props: any, fieldDetails: any, update: any) => {
  const localizedDataNew = fieldDetails.enum.map((x: any) => {
    return {text: x, value: x};
  });

  const handleBlur = (e: any) => {
    const field = props.field || '';
    const obj: any = {
      id: props.dataItem.id,
    };
    obj[field] = e.target.value.value;
    obj['status'] = 'Edited';
    update(obj);
  };
  const {dataItem} = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <DropDownList
          style={{width: '100px'}}
          onChange={handleBlur}
          defaultValue={localizedDataNew.find(
            (c: any) => c.value === dataValue,
          )}
          data={localizedDataNew}
          textField="text"
        />
      ) : (
        dataValue.toString()
      )}
    </td>
  );
};
