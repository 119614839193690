/* eslint-disable require-jsdoc */
import {nonWordService} from '../../services/nonWord.service';
import {nonWordConstants} from '../constants';

export const nonWordActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await nonWordService.getAll(languageId).then(
      (nonWord: any) => dispatch(success(nonWord)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: nonWordConstants.GETALL_REQUEST};
  }
  function success(nonWord: any) {
    return {
      type: nonWordConstants.GETALL_SUCCESS,
      nonWord,
    };
  }
  function failure(error: any) {
    return {type: nonWordConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    nonWordService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: nonWordConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: nonWordConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {
      type: nonWordConstants.UPDATE_FAILURE,
      letter,
      error,
    };
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await nonWordService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: nonWordConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: nonWordConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: nonWordConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await nonWordService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: nonWordConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: nonWordConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: nonWordConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await nonWordService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: nonWordConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: nonWordConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: nonWordConstants.APPROVE_ALL_FAILURE, error};
  }
}
