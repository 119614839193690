/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import {Input} from '@progress/kendo-react-inputs';
import React, {useState} from 'react';
import {uniqueIdConst} from '../../constant/uniqueIdConst';
import {RemovePunctuationMarks} from '../../helpers/removePunctuationMarks';

export const stringField = (
  gridProps: any,
  value: string,
  defaultVal: string,
  defaultValueBaseFrom: string,
  uniqueIdDefaultValue: string,
  disabled: boolean,
  allData: any,
  updateData: Function,
) => {
  const [focus, setFocus] = useState(false);
  const {dataItem} = gridProps;
  const field = gridProps.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <Input
          defaultValue={dataValue ? dataValue : defaultVal}
          name={field}
          value={field === 'uniqueId' && !focus ? dataValue : undefined}
          disabled={disabled}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={async (e: any) => {
            setFocus(false);
            const obj: any = {
              id: dataItem.id,
            };

            obj[`${field}`] = e.target.value;
            if (defaultValueBaseFrom && uniqueIdDefaultValue) {
              let uniqueId = '';
              const valueWithoutPunctuationMarks = await RemovePunctuationMarks(
                e.target.value,
              );
              for (let j = 0; j < valueWithoutPunctuationMarks.length; j++) {
                const letter = valueWithoutPunctuationMarks[j];
                const existUniqueIdInTable = uniqueIdConst.find((x: any) => {
                  return x.Character === letter;
                });
                if (existUniqueIdInTable) {
                  if (j + 1 < valueWithoutPunctuationMarks.length) {
                    uniqueId += `${existUniqueIdInTable.ID}_`;
                  } else {
                    uniqueId += existUniqueIdInTable.ID;
                  }
                } else {
                  uniqueId += letter;
                }
              }

              const regx = new RegExp(
                `((${uniqueIdDefaultValue + uniqueId})_[0-9]+$)`,
                'g',
              );
              const existUniqueIdLength = allData.filter(
                (x: any) =>
                  (x.uniqueId === uniqueIdDefaultValue + uniqueId ||
                    x.uniqueId.match(regx)) &&
                  x.id !== dataItem.id,
              ).length;
              const originalExist = allData.filter(
                (x: any) =>
                  x.uniqueId === uniqueIdDefaultValue + uniqueId &&
                  x.id === dataItem.id,
              ).length;

              if (originalExist !== 1 && existUniqueIdLength !== 0) {
                obj['uniqueId'] = `${uniqueIdDefaultValue}${uniqueId}_${
                  existUniqueIdLength + 1
                }`;
              } else {
                obj['uniqueId'] = uniqueIdDefaultValue + uniqueId;
              }
            }
            if (value !== e.target.value) {
              obj['status'] = 'Edited';
              updateData(obj);
            }
          }}
        />
      ) : (
        dataValue.toString()
      )}
    </td>
  );
};
