/* eslint-disable require-jsdoc */
import {NumericTextBox} from '@progress/kendo-react-inputs';
import React from 'react';

export const numberField = (props: any, updateData: Function) => {
  const handleBlur = (e: any) => {
    const field = props.field || '';
    const obj: any = {
      id: props.dataItem.id,
    };
    obj[field] = e.target.value;
    obj['status'] = 'Edited';
    updateData(obj);
  };

  const {dataItem} = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  return (
    <td>
      {dataItem.inEdit ? (
        <NumericTextBox
          name={field}
          defaultValue={dataValue}
          onBlur={handleBlur}
        />
      ) : (
        dataValue.toString()
      )}
    </td>
  );
};
