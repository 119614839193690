/* eslint-disable require-jsdoc */
import {Slide} from '@progress/kendo-react-animation';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {alertActions} from '../redux/actions';

function AlertComponent({type, message}: any) {
  const dispatch = useDispatch();
  const [showToast, setShow] = useState(true);
  const toggleShow = () => setShow(!showToast);
  const {t} = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      dispatch(alertActions.clear());
    }, 5000);
  });
  return (
    <div className="alert">
      <NotificationGroup
        style={{
          right: 0,
          bottom: 0,
          alignItems: 'flex-start',
          flexWrap: 'wrap-reverse',
        }}
      >
        <Slide direction={'up'}>
          <Notification
            type={{
              style: type === 'alert-success' ? 'success' : 'error',
              // icon: true,
            }}
            onClose={toggleShow}
          >
            {t(message)}
          </Notification>
        </Slide>
      </NotificationGroup>
    </div>
  );
}

export default AlertComponent;
