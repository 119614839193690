/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {getRelatedSectionConstant} from '../../helpers/getRelatedSectionConstant';
import {commentService} from '../../services/comment.service';
import {commentConstants} from '../constants';

export const commentActions = {
  getAll,
  update: _update,
  insert,
  remove,
  insertCommentSeen,
  getExistCommentsCount,
  clean,
};

function getAll(dataId: any, modelEndpoint: string, currentUserId: string) {
  return async (dispatch: any) => {
    dispatch(request());
    await commentService.getAll(dataId, modelEndpoint).then(
      (comments: any) => {
        dispatch(success(comments));
        const unread = comments
          .filter(
            (x: any) =>
              x.userId !== currentUserId &&
              (!x.hasOwnProperty('commentSeens') ||
                (x.hasOwnProperty('commentSeens') &&
                  !x.commentSeens.some(
                    (y: any) => y.userId === currentUserId,
                  ))),
          )
          .map((x: any) => x.id);

        for (let i = 0; i < unread.length; i++) {
          const id = unread[i];
          dispatch(commentActions.insertCommentSeen(id, currentUserId));
        }
      },
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: commentConstants.GETALL_REQUEST};
  }
  function success(comment: any) {
    return {type: commentConstants.GETALL_SUCCESS, comment};
  }
  function failure(error: any) {
    return {type: commentConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because update is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    commentService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: commentConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: commentConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: commentConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(comment: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await commentService.insert(comment).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: commentConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: commentConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: commentConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await commentService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: commentConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: commentConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: commentConstants.REMOVE_FAILURE, error};
  }
}

function insertCommentSeen(commentId: string, userId: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await commentService.insertCommentSeen(commentId, userId).then(
      (commentSeen: any) => dispatch(success(commentSeen)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: commentConstants.INSERT_COMMENT_SEEN_REQUEST};
  }
  function success(commentSeen: any) {
    return {type: commentConstants.INSERT_COMMENT_SEEN_SUCCESS, commentSeen};
  }
  function failure(error: any) {
    return {type: commentConstants.INSERT_COMMENT_SEEN_FAILURE, error};
  }
}

function getExistCommentsCount(modelEndpoint: string, languageId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(request());
    const {selectedSection} = getState().local;

    await commentService.getExistCommentsCount(modelEndpoint, languageId).then(
      (data: any) => {
        dispatch(success(data)),
          dispatch(matchCommentData(data, selectedSection));
      },
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: commentConstants.GET_EXIST_COMMENTS_COUNT_REQUEST};
  }
  function success(data: any) {
    return {
      type: commentConstants.GET_EXIST_COMMENTS_COUNT_SUCCESS,
      data,
    };
  }
  function matchCommentData(commentCount: any, selectedSection: string) {
    return {
      type: getRelatedSectionConstant(selectedSection).MATCH_COMMENT_COUNT_DATA,
      commentCount,
    };
  }
  function failure(error: any) {
    return {type: commentConstants.GET_EXIST_COMMENTS_COUNT_FAILURE, error};
  }
}

function clean(dataId: any) {
  return (dispatch: any, getState: any) => {
    dispatch(cleanCommentData());
    const {selectedSection} = getState().local;
    dispatch(removeUnreadNotification(dataId, selectedSection));
  };
  function removeUnreadNotification(dataId: string, selectedSection: string) {
    return {
      type: getRelatedSectionConstant(selectedSection)
        .REMOVE_UNREAD_NOTIFICATION,
      dataId,
    };
  }
  function cleanCommentData() {
    return {type: commentConstants.CLEAN_COMMENTS};
  }
}
