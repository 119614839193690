/* eslint-disable require-jsdoc */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect, Route} from 'react-router-dom';
import {isLoggedIn} from '../../helpers/isLoggedIn';

function PrivateRoute({component: Component, roles, ...rest}: any) {
  const {t} = useTranslation();
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!isLoggedIn()) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {from: props.location, message: t('auth')},
              }}
            />
          );
        }

        // logged in so return component
        return <Component {...props} />;
      }}
    />
  );
}

export {PrivateRoute};
