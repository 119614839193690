/* eslint-disable require-jsdoc */
import {onetestLetterSoundService} from '../../services/onetestLetterSound.service';
import {onetestLetterSoundConstants} from '../constants';

export const onetestLetterSoundActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestLetterSoundService.getAll(languageId).then(
      (onetestLetterSound: any) => dispatch(success(onetestLetterSound)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestLetterSoundConstants.GETALL_REQUEST};
  }
  function success(onetestLetterSound: any) {
    return {
      type: onetestLetterSoundConstants.GETALL_SUCCESS,
      onetestLetterSound,
    };
  }
  function failure(error: any) {
    return {type: onetestLetterSoundConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    onetestLetterSoundService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: onetestLetterSoundConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: onetestLetterSoundConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: onetestLetterSoundConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestLetterSoundService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestLetterSoundConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: onetestLetterSoundConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: onetestLetterSoundConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestLetterSoundService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestLetterSoundConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: onetestLetterSoundConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: onetestLetterSoundConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestLetterSoundService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: onetestLetterSoundConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: onetestLetterSoundConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: onetestLetterSoundConstants.APPROVE_ALL_FAILURE, error};
  }
}
