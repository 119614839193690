/* eslint-disable no-unused-vars */
export enum ModelEndpoints {
  Alphabet = 'alphabet',
  Comment = 'comments',
  IsolatedSound = 'isolatedSound',
  IsolatedSyllable = 'isolatedSyllable',
  NonWord = 'nonWord',
  OnetestLetterName = 'onetestLetterName',
  OnetestLetterSound = 'onetestLetterSound',
  OnetestNonWord = 'onetestNonWord',
  OnetestNumberIdentification = 'onetestNumberIdentification',
  OnetestPhonologicalAwareness = 'onetestPhonologicalAwareness',
  OnetestSyllableIdentification = 'onetestSyllableIdentification',
  OnetestWord = 'onetestWord',
  FlashCard = 'flashCard',
  Notification = 'notification',
  Sentence = 'sentence',
  Phrase = 'phrase',
  ConjugatedVerb = 'conjugatedVerb',
  NounsWithArticle = 'nounsWithArticle',
  Morphology = 'morphology',
  Story = 'story',
  GeneralEndpoint = 'general',
}
