/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import {DropDownList, ListItemProps} from '@progress/kendo-react-dropdowns';
import i18n from 'i18next';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

interface State {
  language: string;
}

interface Props extends WithTranslation {}

class LanguageSwitcher extends React.Component<WithTranslation, State, Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      language: 'en',
    };
  }

  changeLang = async (event: any) => {
    i18n.changeLanguage(event.target.value.value);
    this.setState({...this.state, language: event.target.value.value});
    await new Promise((r: any) => setTimeout(r, 200));
  };

  languages = [
    {text: 'English', value: 'en'},
    {text: 'Portuguese', value: 'pt'},
    {text: 'Hausa', value: 'ha'},
    {text: 'Arabic', value: 'ar'},
  ];

  itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps,
  ) => {
    const {t} = this.props;
    const itemChildren = (
      <span>{t(itemProps.dataItem.text.toLocaleLowerCase())}</span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  defaultItem = {text: 'English', value: 'en'};

  valueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
    const {t} = this.props;
    if (!value) {
      return element;
    }
    const children = (
      <span key={2}>&nbsp; {t(value.text.toLocaleLowerCase())}</span>
    );
    return React.cloneElement(element, {...element.props}, children);
  };

  render() {
    return (
      <div>
        <DropDownList
          data={this.languages}
          textField="text"
          dir={i18n.dir()}
          dataItemKey="value"
          itemRender={this.itemRender}
          valueRender={this.valueRender}
          // value={{value: getCurrentLng()}}
          onChange={this.changeLang.bind(this)}
          defaultValue={this.defaultItem}
        />
      </div>
    );
  }
}

export default withTranslation()(LanguageSwitcher);
