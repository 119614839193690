/* eslint-disable indent */
/* eslint-disable require-jsdoc */

import {getRelatedSectionConstant} from '../../helpers/getRelatedSectionConstant';
import {notificationService} from '../../services/notification.service';
import {notificationConstants} from '../constants';

export const notificationActions = {
  getlatestComment,
};

function getlatestComment(modelEndpoint: string, languageId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(request());
    const {selectedSection} = getState().local;

    await notificationService.getlatestComment(modelEndpoint, languageId).then(
      (data: any) => {
        dispatch(success(data)),
          dispatch(matchCommentData(data, selectedSection));
      },
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: notificationConstants.GET_LATEST_COMMENTS_REQUEST};
  }
  function success(data: any) {
    return {
      type: notificationConstants.GET_LATEST_COMMENTS_SUCCESS,
      data,
    };
  }
  function matchCommentData(commentCount: any, selectedSection: string) {
    return {
      type: getRelatedSectionConstant(selectedSection).MATCH_COMMENT_DATA,
      commentCount,
    };
  }
  function failure(error: any) {
    return {type: notificationConstants.GET_LATEST_COMMENTS_FAILURE, error};
  }
}
