/* eslint-disable require-jsdoc */
import {ModelEndpoints} from '../constant/modelEndpoints';
import {authHeader} from '../helpers/auth-header';
import {logout} from '../helpers/logout';
export const commentService = {
  getAll,
  // getById,
  insert,
  update,
  remove,
  approveAll,
  insertCommentSeen,
  getExistCommentsCount,
};
const apiUrl: any = process.env.REACT_APP_API_URL;
const modelName: string = ModelEndpoints.Comment;
async function getAll(dataId: string, modelEndpoint: string) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };
  const params: any = {
    where: {
      dataId: dataId,
      modelEndpoint: modelEndpoint,
    },
    include: [
      {
        relation: 'user',
        scope: {
          fields: ['name', 'id'],
        },
      },
      {
        relation: 'commentSeens',
        scope: {
          include: [{relation: 'user', scope: {fields: ['name', 'id']}}],
        },
      },
    ],
  };

  const data = await fetch(
    `${apiUrl}/${modelName}?filter=${JSON.stringify(params)}`,
    requestOptions,
  ).then(handleResponse);
  return data;
}

function update(letter: any) {
  const requestOptions: any = {
    method: 'PATCH',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(letter),
  };

  return fetch(`${apiUrl}/${modelName}/${letter.id}`, requestOptions).then(
    handleResponse,
  );
}

function insert(letter: any) {
  const requestOptions: any = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(letter),
  };

  return fetch(`${apiUrl}/${modelName}`, requestOptions).then(handleResponse);
}

function remove(id: string) {
  const requestOptions: any = {
    method: 'DELETE',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
  };

  return fetch(`${apiUrl}/${modelName}/${id}`, requestOptions).then(
    handleResponse,
  );
}

function approveAll(languageId: string) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(
    `${apiUrl}/${modelName}/approveAll/${languageId}`,
    requestOptions,
  ).then(handleResponse);
}

function insertCommentSeen(commentId: string, userId: string) {
  const obj = {
    commentId,
    userId,
    timestamp: new Date(),
  };
  const requestOptions: any = {
    method: 'POST',
    headers: {...authHeader(), 'Content-Type': 'application/json'},
    body: JSON.stringify(obj),
  };

  return fetch(
    `${apiUrl}/${modelName}/${commentId}/comment-seens`,
    requestOptions,
  ).then(handleResponse);
}

async function getExistCommentsCount(
  modelEndpoint: string,
  languageId: string,
) {
  const requestOptions: any = {
    method: 'GET',
    headers: authHeader(),
  };

  const data = await fetch(
    `${apiUrl}/${modelName}/count/${modelEndpoint}/${languageId}`,
    requestOptions,
  ).then(handleResponse);
  return data;
}

async function handleResponse(response: any) {
  return await response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
