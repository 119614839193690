export const onetestSyllableIdentificationConstants = {
  GETALL_REQUEST: 'ONETEST_SYLLABLE_IDENTIFICATION_GETALL_REQUEST',
  GETALL_SUCCESS: 'ONETEST_SYLLABLE_IDENTIFICATION_GETALL_SUCCESS',
  GETALL_FAILURE: 'ONETEST_SYLLABLE_IDENTIFICATION_GETALL_FAILURE',

  UPDATE_REQUEST: 'ONETEST_SYLLABLE_IDENTIFICATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ONETEST_SYLLABLE_IDENTIFICATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ONETEST_SYLLABLE_IDENTIFICATION_UPDATE_FAILURE',

  INSERT_REQUEST: 'ONETEST_SYLLABLE_IDENTIFICATION_INSERT_REQUEST',
  INSERT_SUCCESS: 'ONETEST_SYLLABLE_IDENTIFICATION_INSERT_SUCCESS',
  INSERT_FAILURE: 'ONETEST_SYLLABLE_IDENTIFICATION_INSERT_FAILURE',

  REMOVE_REQUEST: 'ONETEST_SYLLABLE_IDENTIFICATION_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'ONETEST_SYLLABLE_IDENTIFICATION_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'ONETEST_SYLLABLE_IDENTIFICATION_REMOVE_FAILURE',

  APPROVE_ALL_REQUEST: 'ONETEST_SYLLABLE_IDENTIFICATION_APPROVE_ALL_REQUEST',
  APPROVE_ALL_SUCCESS: 'ONETEST_SYLLABLE_IDENTIFICATION_APPROVE_ALL_SUCCESS',
  APPROVE_ALL_FAILURE: 'ONETEST_SYLLABLE_IDENTIFICATION_APPROVE_ALL_FAILURE',

  GENERATE_SPECIAL_XML_REQUEST:
    'ONETEST_SYLLABLE_IDENTIFICATION_GENERATE_SPECIAL_XML_REQUEST',
  GENERATE_SPECIAL_XML_SUCCESS:
    'ONETEST_SYLLABLE_IDENTIFICATION_GENERATE_SPECIAL_XML_SUCCESS',
  GENERATE_SPECIAL_XML_FAILURE:
    'ONETEST_SYLLABLE_IDENTIFICATION_GENERATE_SPECIAL_XML_FAILURE',

  MATCH_COMMENT_DATA: 'ONETEST_SYLLABLE_IDENTIFICATION_MATCH_COMMENT_DATA',
  MATCH_COMMENT_COUNT_DATA: 'ALPHABET_MATCH_COMMENT_COUNT_DATA',
  REMOVE_UNREAD_NOTIFICATION:
    'ONETEST_SYLLABLE_IDENTIFICATION_REMOVE_UNREAD_NOTIFICATION',
};
