/* eslint-disable require-jsdoc */
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isLoggedIn} from '../../helpers/isLoggedIn';

function UnPrivateRoute({component: Component, roles, ...rest}: any) {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isLoggedIn()) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect to={{pathname: '/', state: {from: props.location}}} />
          );
        }

        // logged in so return component
        return <Component {...props} />;
      }}
    />
  );
}

export {UnPrivateRoute};
