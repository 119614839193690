/* eslint object-curly-spacing: ["error", "always", { "objectsInObjects": false }]*/
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
    },
    ha: {
      translations: require('./locales/ha/translations.json'),
    },
    ar: {
      translations: require('./locales/ar/translations.json'),
    },
    pt: {
      translations: require('./locales/pt/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'ha', 'ar', 'pt'];

export default i18n;
