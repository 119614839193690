/* eslint-disable new-cap */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {
  Chat,
  ChatMessage,
  ChatMessageProps,
  ChatMessageSendEvent,
  Message,
} from '@progress/kendo-react-conversational-ui';
import {Dialog} from '@progress/kendo-react-dialogs';
import {BadgeContainer} from '@progress/kendo-react-indicators';
import {Avatar} from '@progress/kendo-react-layout';
import {Offset, Popup} from '@progress/kendo-react-popup';
import moment from 'moment';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {commentActions} from '../../redux/actions';

const initialMessages: Message[] = [];

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
const avatarLink = 'https://cdn.statically.io/avatar/shape=circle/';

function CommentDialog({
  props,
  showCommentDialog,
  changeCommentDialogStatus,
  dataId,
  modelEndpoint,
  currentUser,
  languageId,
  header,
}: any) {
  // const {t, i18n} = useTranslation();
  const [messages, setMessages] = React.useState(initialMessages);
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const offset: Offset = {left: 150, top: 50};
  const [show, setShow] = React.useState(false);
  const [selectedComment, setSelectedComment] = React.useState<any>(null);
  const addNewMessage = (event: ChatMessageSendEvent) => {
    setMessages([...messages, event.message]);
    const comment = {
      userId: event.message.author.id,
      text: event.message.text,
      timestamp: event.message.timestamp && new Date(event.message.timestamp),
      modelEndpoint,
      dataId,
      languageId,
    };
    dispatch(commentActions.insert(comment));
  };
  const dispatch = useDispatch();

  const CustomChatMessage = (props: ChatMessageProps) => (
    <ChatMessage {...props} dateFormat={'t'} />
  );

  const MessageTemplate = (props: any) => {
    // const currentUserId = JSON.parse(currentUser).id;
    return (
      <div>
        <span ref={anchor}>
          <div className="k-bubble">
            <div>{props.item.text}</div>
          </div>
          {props.item.commentSeens && (
            <div
              onMouseEnter={() => {
                setSelectedComment(props.item);
                setShow(true);
              }}
              onMouseLeave={() => {
                setShow(false);
                setSelectedComment(null);
              }}
            >
              <span className="k-icon k-i-check" style={{color: 'blue'}} />
            </div>
          )}
        </span>
      </div>
    );
  };

  useEffect(() => {
    dispatch(
      commentActions.getAll(dataId, modelEndpoint, JSON.parse(currentUser).id),
    );
  }, []);
  const commentState = useSelector((state: any) => state.comment);

  return (
    <>
      {showCommentDialog && (
        <Dialog
          title={'Comments'}
          onClose={() => {
            changeCommentDialogStatus(!showCommentDialog);
            setMessages([]);
            dispatch(commentActions.clean(dataId));
          }}
        >
          <div
            style={{
              margin: '25px',
              textAlign: 'center',
              minHeight: 200,
              minWidth: 400,
            }}
          >
            {commentState.loading ? (
              loadingPanel
            ) : (
              <div>
                {header.length > 0 && (
                  <div dangerouslySetInnerHTML={{__html: header}} />
                )}
                <Chat
                  user={{
                    ...JSON.parse(currentUser),
                    avatarUrl: `${avatarLink}${JSON.parse(
                      currentUser,
                    ).name.toUpperCase()}`,
                  }}
                  messages={commentState.data.map((x: any) => {
                    return {
                      text: x.text,
                      timestamp: new Date(x.timestamp),
                      author: {
                        id: x.user ? x.user.id : JSON.parse(currentUser).id,
                        name: x.user
                          ? x.user.name
                          : JSON.parse(currentUser).name,
                        avatarUrl: `${avatarLink}${
                          x.user
                            ? x.user.name.toUpperCase()
                            : JSON.parse(currentUser).name.toUpperCase()
                        }`,
                      },
                      commentSeens: x.commentSeens,
                    };
                  })}
                  message={CustomChatMessage}
                  messageTemplate={MessageTemplate}
                  onMessageSend={addNewMessage}
                  placeholder={'Type a message...'}
                  width={400}
                />
                {selectedComment && (
                  <Popup
                    offset={offset}
                    anchor={anchor.current}
                    show={show}
                    popupClass={'popup-content'}
                    id={selectedComment.id}
                  >
                    <div className="users">
                      {selectedComment.commentSeens.map((x: any) => {
                        return (
                          <div className="k-hbox" key={x.id}>
                            <BadgeContainer>
                              <Avatar shape="circle" type="image">
                                <img
                                  src={`${avatarLink}${x.user.name.toUpperCase()}`}
                                />
                              </Avatar>
                            </BadgeContainer>
                            <div className="contact-info">
                              <h2>{x.user.name}</h2>
                              <p>{moment(x.timestamp).format('LLLL')}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <style>
                      {`.users h2 {
                                font-size: 1.3em;
                                font-weight: normal;
                                margin: 0;
                            }
                            .users p {
                                margin: 0;
                                font-size: 0.8em;
                            }
                            .k-hbox {
                                align-items: center;
                                border-bottom: 1px solid #ccc;
                                padding: 12px 14px;
                            }
                            .k-hbox .contact-info {
                                margin-left: 1em;
                            }`}
                    </style>
                  </Popup>
                )}
              </div>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
}

export default CommentDialog;
