/* eslint-disable require-jsdoc */
import React, {useEffect} from 'react';
// @ts-ignore
import {Offline} from 'react-detect-offline';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import './App.css';
import AlertComponent from './components/Alert';
import OfflineModal from './components/modals/OfflineModal';
import Navbar from './components/Navbar';
import {history} from './helpers/history';
import {alertActions} from './redux/actions';

function App() {
  const {i18n} = useTranslation();
  const dir = i18n.dir();
  const alert = useSelector((state: any) => state.alert);
  const offlineModalStatus = useSelector(
    (state: any) => state.local.offlineModalStatus,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location: any, action: any) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, []);

  return (
    <div>
      <div dir={dir}>
        {!offlineModalStatus && (
          <Offline>
            <OfflineModal />
          </Offline>
        )}
        {alert.message && (
          <AlertComponent type={alert.type} message={alert.message} />
        )}
      </div>
      <Navbar />
    </div>
  );
}

export default App;
