/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import {userConstants} from '../constants';

export function registration(state = {}, action: any) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {registering: true};
    case userConstants.REGISTER_SUCCESS:
      return {};
    case userConstants.REGISTER_FAILURE:
      return {};
    default:
      return state;
  }
}
