/* eslint-disable indent */

import {
  alphabetActions,
  conjugatedVerbActions,
  flashCardActions,
  isolatedSoundActions,
  isolatedSyllableActions,
  morphologyActions,
  nounsWithArticleActions,
  onetestLetterNameActions,
  onetestLetterSoundActions,
  onetestNonWordActions,
  onetestNumberIdentificationActions,
  onetestPhonologicalAwarenessActions,
  onetestSyllableIdentificationActions,
  nonWordActions,
  onetestWordActions,
  phraseActions,
  sentenceActions,
  storyActions,
  tagActions,
} from '../redux/actions';

/* eslint-disable require-jsdoc */
export const getRelatedSectionActions = (type: string) => {
  const sectionPath = '/sections/';
  const adminPath = '/admin/';
  switch (type) {
    case `${sectionPath}alphabet`:
      return alphabetActions;
    case `${sectionPath}flashCard`:
      return flashCardActions;
    case `${sectionPath}isolatedSound`:
      return isolatedSoundActions;
    case `${sectionPath}isolatedSyllable`:
      return isolatedSyllableActions;
    case `${sectionPath}onetestLetterName`:
      return onetestLetterNameActions;
    case `${sectionPath}onetestLetterSound`:
      return onetestLetterSoundActions;
    case `${sectionPath}onetestNonWord`:
      return onetestNonWordActions;
    case `${sectionPath}onetestNumberIdentification`:
      return onetestNumberIdentificationActions;
    case `${sectionPath}onetestPhonologicalAwareness`:
      return onetestPhonologicalAwarenessActions;
    case `${sectionPath}onetestSyllableIdentification`:
      return onetestSyllableIdentificationActions;
    case `${sectionPath}nonWord`:
      return nonWordActions;
    case `${sectionPath}onetestWord`:
      return onetestWordActions;
    case `${sectionPath}sentence`:
      return sentenceActions;
    case `${sectionPath}phrase`:
      return phraseActions;
    case `${sectionPath}conjugatedVerb`:
      return conjugatedVerbActions;
    case `${sectionPath}nounsWithArticle`:
      return nounsWithArticleActions;
    case `${sectionPath}morphology`:
      return morphologyActions;
    case `${sectionPath}story`:
      return storyActions;
    case `${adminPath}tag`:
      return tagActions;
    default:
      return alphabetActions; // TODO:
  }
};
