/* eslint-disable require-jsdoc */
import {morphologyService} from '../../services/morphology.service';
import {morphologyConstants} from '../constants';

export const morphologyActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
  changeMarkupMode,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await morphologyService.getAll(languageId).then(
      (morphologys: any) => dispatch(success(morphologys)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: morphologyConstants.GETALL_REQUEST};
  }
  function success(morphology: any) {
    return {type: morphologyConstants.GETALL_SUCCESS, morphology};
  }
  function failure(error: any) {
    return {type: morphologyConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    morphologyService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: morphologyConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: morphologyConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: morphologyConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await morphologyService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: morphologyConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: morphologyConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: morphologyConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await morphologyService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: morphologyConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: morphologyConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: morphologyConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await morphologyService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: morphologyConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: morphologyConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: morphologyConstants.APPROVE_ALL_FAILURE, error};
  }
}

function changeMarkupMode() {
  return async (dispatch: any) => {
    dispatch(change());
  };

  function change() {
    return {type: morphologyConstants.CHANGE_MARKUP_MODE};
  }
}
