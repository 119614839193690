/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable space-before-function-paren */
/* eslint-disable quote-props */
/* eslint-disable operator-linebreak */
/* eslint-disable require-jsdoc */
import {Button} from '@progress/kendo-react-buttons';
import {Stepper} from '@progress/kendo-react-layout';
import {ExternalDropZone, Upload} from '@progress/kendo-react-upload';
import moment from 'moment';
import Papa from 'papaparse';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ModelEndpoints} from '../constant/modelEndpoints';
import {uniqueIdConst} from '../constant/uniqueIdConst';
import {RemovePunctuationMarks} from '../helpers/removePunctuationMarks';
import {stringToBoolean} from '../helpers/stringToBoolean';
import {alertActions} from '../redux/actions';

const stepperItems = [
  {label: 'Template', icon: 'k-i-download'},
  {label: 'Upload', icon: 'k-i-upload'},
  {label: 'Review', icon: 'k-i-eye'},
];

const apiUrl: any = process.env.REACT_APP_API_URL;

function ImportCSV({
  props,
  changeImportPopupStatus,
  menuKey,
  modelEndPoint,
  user,
}: any) {
  const uploadRef: any = React.createRef<Upload>();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [stepperValue, setStepperValue] = useState<number>(0);
  const [templateCorrect, setTemplateCorrect] = useState<boolean>(true);
  const [importCSVData, setImportCSVData] =
    useState<Papa.ParseResult<unknown> | null>(null);
  const [diffList, setDiffList] = useState<Array<unknown>>([]);
  const [newImportDatas, setNewImportDatas] = useState<Array<unknown>>([]);

  const addDummyData = (data: any, modelEndPoint: ModelEndpoints) => {
    let newData = data;
    switch (modelEndPoint) {
      case ModelEndpoints.Alphabet:
        newData += ',a,A,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.IsolatedSound:
        newData += ',c,cat,,TRUE,cat,,[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.IsolatedSyllable:
        newData += ',ca,cat,TRUE,[comment for voice over],,,[leave this blank]';
        newData += '\r\n';
        newData +=
          ',bi,bite,TRUE,[comment for voice over],,is_b/is_i_2,[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestLetterName:
        newData += ',c,,,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestLetterSound:
        newData += ',c,cat,,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestNonWord:
        newData += ',slub,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestNumberIdentification:
        newData += ',1,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestPhonologicalAwareness:
        newData += ',t,,cat,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        newData += 'c,,,cat,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestSyllableIdentification:
        newData += ',ca,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.OnetestWord:
        newData +=
          ',sourceCat,cat,fc_cat,TRUE,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.Phrase:
        newData +=
          'ph1,ph_talking_on_phone,talk/ing on the phone,a fa/lar ao telefone,y,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.Sentence:
        newData +=
          'se1,ph_lock_and_key,This is the lock and key for my dad’s store.,Es/te é o ca/de/a/do e a cha/ve do ar/ma/zém do meu Pai.,y,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.ConjugatedVerb:
        newData +=
          'ver_have_pre_sing_1p,ter,eu,tenho,eu tenho,present,te/nho,1,y,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.NounsWithArticle:
        newData +=
          'art_woman_sing_def,la dame,fc_woman,la,dame,lady,fc_lady,1,y,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      case ModelEndpoints.Morphology:
        newData +=
          'mrph_grandmaateanegg,Grandma ate an egg.,Grandma ate an egg.,an_egg,,y,[comment for voice over],[leave this blank]';
        newData += '\r\n';
        break;
      default:
        break;
    }
    return newData;
  };

  const generateTemplateFile = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const headers = Object.entries(props.properties)
      .map(([key, value]: any, index) => {
        if (key !== 'languageId' && key !== 'id') {
          return key;
        }
      })
      .filter((e: any) => e);
    for (let k = 0; k < headers.length; k++) {
      const header = headers[k];
      if (k !== headers.length - 1) {
        csvContent += `${header},`;
      } else {
        csvContent += `${header}`;
      }
    }
    csvContent += '\r\n';

    csvContent = addDummyData(csvContent, modelEndPoint);

    const utcMoment = moment.utc();
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${t(menuKey)}-${utcMoment.format('LLLL')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
  };

  const downloadCsv = async (fileName: string) => {
    try {
      const target = `${apiUrl}/files/${fileName}`; // file

      const res = await fetch(target, {
        method: 'get',
        headers: {
          Authorization: `Bearer ${user && JSON.parse(user).token}`,
        },
      });

      const headerTypes = Object.entries(props.properties)
        .map(([key, value]: any, index) => {
          if (key !== 'languageId' && key !== 'id') {
            return [key, value.type];
          }
        })
        .filter((e: any) => e);

      if (res.status === 200) {
        const data: any = await res.text();
        const parsedData: any = Papa.parse(data, {
          header: true,
          dynamicTyping: false,
          transform: function (value: any, field: string) {
            const relatedField: any = headerTypes.find(
              (x: any) => x[0] === field,
            );
            switch (relatedField && relatedField[1]) {
              case 'string':
                return value ? value.toString() : '';
              case 'number':
                return value ? parseInt(value) : 0;
              case 'boolean':
                return value ? stringToBoolean(value) : false;
              case 'array':
                return [];
              default:
                return value;
            }
          },
        });
        setImportCSVData(parsedData);
        templateCheck(parsedData.data[0]);
      } else {
        console.log(`Error code ${res.status}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const templateCheck = (data: any) => {
    const headers = Object.entries(props.properties)
      .map(([key, value]: any, index) => {
        if (key !== 'languageId' && key !== 'id') {
          return key;
        }
      })
      .filter((e: any) => e);
    for (let k = 0; k < headers.length; k++) {
      const header = headers[k];
      let exist = false;
      for (const key in data) {
        if (key === header) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        setTemplateCorrect(false);
        dispatch(
          alertActions.error(
            'Template is not correct. Please check your document and try again.',
          ),
        );
        break;
      }
    }
  };

  const reviewConflicts = async () => {
    const dataLength: any = importCSVData?.data.length;
    const uniqueIdDefaultValue: string = props.properties.uniqueId.defaultValue;
    const allData = JSON.parse(JSON.stringify(props.data));
    for (let i = 0; i < dataLength; i++) {
      const e: any = importCSVData?.data[i];

      // CLEAN DUMMY DATA
      if (e['status'] === '[leave this blank]') {
        continue;
      }

      let uniqueId = '';
      let newUniqueId = '';
      let defaultValueBaseFromField: any = Object.entries(
        props.properties,
      ).filter((x: any) => {
        return x[1].defaultValueBaseFrom && x[1].defaultValueBaseFrom === true;
      });
      if (defaultValueBaseFromField.length > 0 && !e.uniqueId) {
        defaultValueBaseFromField = defaultValueBaseFromField[0][0];
        const val = await RemovePunctuationMarks(
          String(e[defaultValueBaseFromField]),
        );
        for (let j = 0; j < val.length; j++) {
          const letter = val[j];
          const existUniqueIdInTable = uniqueIdConst.find((x: any) => {
            return x.Character === letter;
          });
          if (existUniqueIdInTable) {
            if (j + 1 < val.length) {
              uniqueId += `${existUniqueIdInTable.ID}_`;
            } else {
              uniqueId += existUniqueIdInTable.ID;
            }
          } else {
            uniqueId += letter;
          }
        }

        const regx = new RegExp(
          `((${uniqueIdDefaultValue + uniqueId})_[0-9]+$)`,
          'g',
        );
        const existUniqueIdLength = allData.filter(
          (x: any) =>
            x.uniqueId === uniqueIdDefaultValue + uniqueId ||
            x.uniqueId.match(regx),
        ).length;

        if (existUniqueIdLength !== 0) {
          newUniqueId = `${uniqueIdDefaultValue}${uniqueId}_${
            existUniqueIdLength + 1
          }`;
        } else {
          newUniqueId = uniqueIdDefaultValue + uniqueId;
        }
        const newData: any = {...e, uniqueId: newUniqueId};
        newUniqueId !== `${uniqueIdDefaultValue}undefined` &&
          allData.push(newData);
        const t: any = newImportDatas;
        newUniqueId !== `${uniqueIdDefaultValue}undefined` && t.push(newData);
        setNewImportDatas(t);
        if (e.uniqueId !== newUniqueId) {
          const diffL: any = diffList;
          diffL.push({old: e.uniqueId, new: newUniqueId});
          setDiffList(diffL);
        }
      } else {
        const val = String(e['uniqueId']);
        for (let j = 0; j < val.length; j++) {
          const letter = val[j];
          const existUniqueIdInTable = uniqueIdConst.find((x: any) => {
            return x.Character === letter;
          });
          if (existUniqueIdInTable) {
            if (j + 1 < val.length) {
              uniqueId += `${existUniqueIdInTable.ID}_`;
            } else {
              uniqueId += existUniqueIdInTable.ID;
            }
          } else {
            uniqueId += letter;
          }
        }

        const regx = new RegExp(`((${uniqueId})_[0-9]+$)`, 'g');
        const existUniqueIdLength = allData.filter(
          (x: any) => x.uniqueId === uniqueId || x.uniqueId.match(regx),
        ).length;

        if (existUniqueIdLength !== 0) {
          newUniqueId = `${uniqueId}_${existUniqueIdLength + 1}`;
        } else {
          newUniqueId = uniqueId;
        }

        const newData: any = {...e, uniqueId: newUniqueId};
        allData.push(newData);
        const t: any = newImportDatas;
        t.push(newData);
        setNewImportDatas(t);
        if (e.uniqueId !== newUniqueId) {
          const diffL: any = diffList;
          diffL.push({old: e.uniqueId, new: newUniqueId});
          setDiffList(diffL);
        }
      }
    }
  };

  const insertImportedData = () => {
    for (let i = 0; i < newImportDatas.length; i++) {
      const e: any = newImportDatas[i];
      props.insert({
        ...e,
        languageId: props.selected.id,
        status: 'Edited',
      });
    }
  };

  return (
    <>
      <div style={{padding: 20}}>
        <Stepper value={stepperValue} items={stepperItems} />
        {stepperValue === 0 && (
          <div>
            <div style={{height: '15px'}} />
            Please be sure about using the correct template. You can download
            the template from{' '}
            <a
              onClick={() => {
                generateTemplateFile();
              }}
              style={{color: 'blue', cursor: 'pointer'}}
            >
              click here
            </a>
            .
            <div style={{height: '15px'}} />
          </div>
        )}
        {stepperValue === 1 && (
          <div>
            <div style={{height: '15px'}} />
            <ExternalDropZone
              customNote={'Only CSV file is allowed.'}
              uploadRef={uploadRef}
            />
            <div style={{height: '10px'}} />
            <div>OR</div>
            <div style={{height: '10px'}} />
            <Upload
              ref={uploadRef}
              multiple={false}
              defaultFiles={[]}
              restrictions={{
                allowedExtensions: ['.csv', '.jpg'],
              }}
              withCredentials={false}
              saveUrl={`${apiUrl}/files`}
              autoUpload={true}
              saveHeaders={{
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user && JSON.parse(user).token}`,
              }}
              onBeforeUpload={(e: any) => {
                const utcMoment = moment.utc();
                e.files[0].name = `${t(menuKey)}-${utcMoment.format('LLLL')}${
                  e.files?.[0].extension
                }`;
                setTemplateCorrect(true);
              }}
              onStatusChange={(e: any) => {
                downloadCsv(e.newState?.[0].name);
              }}
            />
            <div style={{height: '15px'}} />
          </div>
        )}
        {stepperValue === 2 && (
          <div>
            <div style={{height: '15px'}} />
            <div>
              <a>3. Review the Conflicts</a>
            </div>
            {diffList && (
              <table>
                <thead>
                  <tr>
                    <td>Old</td>
                    <td>New</td>
                  </tr>
                </thead>
                <tbody>
                  {diffList.map((x: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span
                            style={{
                              backgroundColor: 'red',
                              textDecoration: 'line-through',
                              color: 'white',
                            }}
                          >
                            {x.old}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                            }}
                          >
                            {x.new}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <div style={{height: '15px'}} />
          </div>
        )}
        <div style={{height: '15px'}} />
        <hr />
        <div
          style={{
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
          className={'k-form-buttons k-buttons-end'}
        >
          <span style={{alignSelf: 'center'}}>
            Step {stepperValue + 1} of 3
          </span>
          <div>
            {stepperValue !== 0 ? (
              <Button
                style={{marginRight: '16px', padding: 5}}
                onClick={() => {
                  setStepperValue(stepperValue - 1);
                  setImportCSVData(null);
                  setTemplateCorrect(true);
                  setDiffList([]);
                }}
                icon={'k-icon k-i-arrow-60-left'}
              >
                Previous
              </Button>
            ) : undefined}
            <Button
              primary={true}
              disabled={
                stepperValue === stepperItems.length ||
                !templateCorrect ||
                (stepperValue === 1 && !importCSVData)
              }
              onClick={() => {
                {
                  if (stepperValue === stepperItems.length - 1) {
                    insertImportedData();
                    changeImportPopupStatus(false);
                    setStepperValue(0);
                    setImportCSVData(null);
                    setTemplateCorrect(true);
                    setDiffList([]);
                    setNewImportDatas([]);
                  } else {
                    setStepperValue(stepperValue + 1);
                    setTemplateCorrect(true);
                  }
                }
                {
                  stepperValue === stepperItems.length - 2 && reviewConflicts();
                }
              }}
              icon={
                stepperValue === stepperItems.length - 1
                  ? 'k-icon k-i-add'
                  : 'k-icon k-i-arrow-60-right'
              }
              style={{
                padding: 5,
                display: 'inline-flex',
                flexDirection: 'row-reverse',
              }}
            >
              {stepperValue === stepperItems.length - 1
                ? 'Insert Data'
                : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportCSV;
