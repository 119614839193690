export const uniqueIdConst = [
  {
    'Character': 'à',
    'ID': 'a_grave',
  },
  {
    'Character': 'á',
    'ID': 'a_acute',
  },
  {
    'Character': 'â',
    'ID': 'a_circumflex',
  },
  {
    'Character': 'ã',
    'ID': 'a_tilde',
  },
  {
    'Character': 'ä',
    'ID': 'a_diaeresis',
  },
  {
    'Character': 'ç',
    'ID': 'c_cedilla',
  },
  {
    'Character': 'è',
    'ID': 'e_grave',
  },
  {
    'Character': 'é',
    'ID': 'e_acute',
  },
  {
    'Character': 'ê',
    'ID': 'e_circumflex',
  },
  {
    'Character': 'ë',
    'ID': 'e_diaeresis',
  },
  {
    'Character': 'ì',
    'ID': 'i_grave',
  },
  {
    'Character': 'í',
    'ID': 'i_acute',
  },
  {
    'Character': 'î',
    'ID': 'i_circumflex',
  },
  {
    'Character': 'ï',
    'ID': 'i_diaeresis',
  },
  {
    'Character': 'ñ',
    'ID': 'n_tilde',
  },
  {
    'Character': 'ò',
    'ID': 'o_grave',
  },
  {
    'Character': 'ó',
    'ID': 'o_acute',
  },
  {
    'Character': 'ô',
    'ID': 'o_circumflex',
  },
  {
    'Character': 'õ',
    'ID': 'o_tilde',
  },
  {
    'Character': 'ö',
    'ID': 'o_diaeresis',
  },
  {
    'Character': 'ù',
    'ID': 'u_grave',
  },
  {
    'Character': 'ú',
    'ID': 'u_acute',
  },
  {
    'Character': 'û',
    'ID': 'u_circumflex',
  },
  {
    'Character': 'ü',
    'ID': 'u_diaeresis',
  },
  {
    'Character': 'ć',
    'ID': 'c_acute',
  },
  {
    'Character': 'ĉ',
    'ID': 'c_circumflex',
  },
  {
    'Character': 'œ',
    'ID': 'oe_ligature',
  },
  {
    'Character': 'ɓ',
    'ID': 'b_hook',
  },
  {
    'Character': 'ɗ',
    'ID': 'd_hook',
  },
  {
    'Character': 'ƙ',
    'ID': 'k_hook',
  },
  {
    'Character': '’',
    'ID': '_apost',
  },
  {
    'Character': 'ሀ',
    'ID': 'ha',
  },
  {
    'Character': 'ሁ',
    'ID': 'hu',
  },
  {
    'Character': 'ሂ',
    'ID': 'hi',
  },
  {
    'Character': 'ሃ',
    'ID': 'haa',
  },
  {
    'Character': 'ሄ',
    'ID': 'hee',
  },
  {
    'Character': 'ህ',
    'ID': 'he',
  },
  {
    'Character': 'ሆ',
    'ID': 'ho',
  },
  {
    'Character': 'ሇ',
    'ID': 'hoa',
  },
  {
    'Character': 'ለ',
    'ID': 'la',
  },
  {
    'Character': 'ሉ',
    'ID': 'lu',
  },
  {
    'Character': 'ሊ',
    'ID': 'li',
  },
  {
    'Character': 'ላ',
    'ID': 'laa',
  },
  {
    'Character': 'ሌ',
    'ID': 'lee',
  },
  {
    'Character': 'ል',
    'ID': 'le',
  },
  {
    'Character': 'ሎ',
    'ID': 'lo',
  },
  {
    'Character': 'ሏ',
    'ID': 'lwa',
  },
  {
    'Character': 'ሐ',
    'ID': 'hha',
  },
  {
    'Character': 'ሑ',
    'ID': 'hhu',
  },
  {
    'Character': 'ሒ',
    'ID': 'hhi',
  },
  {
    'Character': 'ሓ',
    'ID': 'hhaa',
  },
  {
    'Character': 'ሔ',
    'ID': 'hhee',
  },
  {
    'Character': 'ሕ',
    'ID': 'hhe',
  },
  {
    'Character': 'ሖ',
    'ID': 'hho',
  },
  {
    'Character': 'ሗ',
    'ID': 'hhwa',
  },
  {
    'Character': 'መ',
    'ID': 'ma',
  },
  {
    'Character': 'ሙ',
    'ID': 'mu',
  },
  {
    'Character': 'ሚ',
    'ID': 'mi',
  },
  {
    'Character': 'ማ',
    'ID': 'maa',
  },
  {
    'Character': 'ሜ',
    'ID': 'mee',
  },
  {
    'Character': 'ም',
    'ID': 'me',
  },
  {
    'Character': 'ሞ',
    'ID': 'mo',
  },
  {
    'Character': 'ሟ',
    'ID': 'mwa',
  },
  {
    'Character': 'ሠ',
    'ID': 'sza',
  },
  {
    'Character': 'ሡ',
    'ID': 'szu',
  },
  {
    'Character': 'ሢ',
    'ID': 'szi',
  },
  {
    'Character': 'ሣ',
    'ID': 'szaa',
  },
  {
    'Character': 'ሤ',
    'ID': 'szee',
  },
  {
    'Character': 'ሥ',
    'ID': 'sze',
  },
  {
    'Character': 'ሦ',
    'ID': 'szo',
  },
  {
    'Character': 'ሧ',
    'ID': 'szwa',
  },
  {
    'Character': 'ረ',
    'ID': 'ra',
  },
  {
    'Character': 'ሩ',
    'ID': 'ru',
  },
  {
    'Character': 'ሪ',
    'ID': 'ri',
  },
  {
    'Character': 'ራ',
    'ID': 'raa',
  },
  {
    'Character': 'ሬ',
    'ID': 'ree',
  },
  {
    'Character': 'ር',
    'ID': 're',
  },
  {
    'Character': 'ሮ',
    'ID': 'ro',
  },
  {
    'Character': 'ሯ',
    'ID': 'rwa',
  },
  {
    'Character': 'ሰ',
    'ID': 'sa',
  },
  {
    'Character': 'ሱ',
    'ID': 'su',
  },
  {
    'Character': 'ሲ',
    'ID': 'si',
  },
  {
    'Character': 'ሳ',
    'ID': 'saa',
  },
  {
    'Character': 'ሴ',
    'ID': 'see',
  },
  {
    'Character': 'ስ',
    'ID': 'se',
  },
  {
    'Character': 'ሶ',
    'ID': 'so',
  },
  {
    'Character': 'ሷ',
    'ID': 'swa',
  },
  {
    'Character': 'ሸ',
    'ID': 'sha',
  },
  {
    'Character': 'ሹ',
    'ID': 'shu',
  },
  {
    'Character': 'ሺ',
    'ID': 'shi',
  },
  {
    'Character': 'ሻ',
    'ID': 'shaa',
  },
  {
    'Character': 'ሼ',
    'ID': 'shee',
  },
  {
    'Character': 'ሽ',
    'ID': 'she',
  },
  {
    'Character': 'ሾ',
    'ID': 'sho',
  },
  {
    'Character': 'ሿ',
    'ID': 'shwa',
  },
  {
    'Character': 'ቀ',
    'ID': 'qa',
  },
  {
    'Character': 'ቁ',
    'ID': 'qu',
  },
  {
    'Character': 'ቂ',
    'ID': 'qi',
  },
  {
    'Character': 'ቃ',
    'ID': 'qaa',
  },
  {
    'Character': 'ቄ',
    'ID': 'qee',
  },
  {
    'Character': 'ቅ',
    'ID': 'qe',
  },
  {
    'Character': 'ቆ',
    'ID': 'qo',
  },
  {
    'Character': 'ቇ',
    'ID': 'qoa',
  },
  {
    'Character': 'ቈ',
    'ID': 'qwa',
  },
  {
    'Character': 'ቊ',
    'ID': 'qwi',
  },
  {
    'Character': 'ቋ',
    'ID': 'qwaa',
  },
  {
    'Character': 'ቌ',
    'ID': 'qwee',
  },
  {
    'Character': 'ቍ',
    'ID': 'qwe',
  },
  {
    'Character': 'ቐ',
    'ID': 'qha',
  },
  {
    'Character': 'ቑ',
    'ID': 'qhu',
  },
  {
    'Character': 'ቒ',
    'ID': 'qhi',
  },
  {
    'Character': 'ቓ',
    'ID': 'qhaa',
  },
  {
    'Character': 'ቔ',
    'ID': 'qhee',
  },
  {
    'Character': 'ቕ',
    'ID': 'qhe',
  },
  {
    'Character': 'ቖ',
    'ID': 'qho',
  },
  {
    'Character': 'ቘ',
    'ID': 'qhwa',
  },
  {
    'Character': 'ቚ',
    'ID': 'qhwi',
  },
  {
    'Character': 'ቛ',
    'ID': 'qhwaa',
  },
  {
    'Character': 'ቜ',
    'ID': 'qhwee',
  },
  {
    'Character': 'ቝ',
    'ID': 'qhwe',
  },
  {
    'Character': 'በ',
    'ID': 'ba',
  },
  {
    'Character': 'ቡ',
    'ID': 'bu',
  },
  {
    'Character': 'ቢ',
    'ID': 'bi',
  },
  {
    'Character': 'ባ',
    'ID': 'baa',
  },
  {
    'Character': 'ቤ',
    'ID': 'bee',
  },
  {
    'Character': 'ብ',
    'ID': 'be',
  },
  {
    'Character': 'ቦ',
    'ID': 'bo',
  },
  {
    'Character': 'ቧ',
    'ID': 'bwa',
  },
  {
    'Character': 'ቨ',
    'ID': 'va',
  },
  {
    'Character': 'ቩ',
    'ID': 'vu',
  },
  {
    'Character': 'ቪ',
    'ID': 'vi',
  },
  {
    'Character': 'ቫ',
    'ID': 'vaa',
  },
  {
    'Character': 'ቬ',
    'ID': 'vee',
  },
  {
    'Character': 'ቭ',
    'ID': 've',
  },
  {
    'Character': 'ቮ',
    'ID': 'vo',
  },
  {
    'Character': 'ቯ',
    'ID': 'vwa',
  },
  {
    'Character': 'ተ',
    'ID': 'ta',
  },
  {
    'Character': 'ቱ',
    'ID': 'tu',
  },
  {
    'Character': 'ቲ',
    'ID': 'ti',
  },
  {
    'Character': 'ታ',
    'ID': 'taa',
  },
  {
    'Character': 'ቴ',
    'ID': 'tee',
  },
  {
    'Character': 'ት',
    'ID': 'te',
  },
  {
    'Character': 'ቶ',
    'ID': 'to',
  },
  {
    'Character': 'ቷ',
    'ID': 'twa',
  },
  {
    'Character': 'ቸ',
    'ID': 'ca',
  },
  {
    'Character': 'ቹ',
    'ID': 'cu',
  },
  {
    'Character': 'ቺ',
    'ID': 'ci',
  },
  {
    'Character': 'ቻ',
    'ID': 'caa',
  },
  {
    'Character': 'ቼ',
    'ID': 'cee',
  },
  {
    'Character': 'ች',
    'ID': 'ce',
  },
  {
    'Character': 'ቾ',
    'ID': 'co',
  },
  {
    'Character': 'ቿ',
    'ID': 'cwa',
  },
  {
    'Character': 'ኀ',
    'ID': 'xa',
  },
  {
    'Character': 'ኁ',
    'ID': 'xu',
  },
  {
    'Character': 'ኂ',
    'ID': 'xi',
  },
  {
    'Character': 'ኃ',
    'ID': 'xaa',
  },
  {
    'Character': 'ኄ',
    'ID': 'xee',
  },
  {
    'Character': 'ኅ',
    'ID': 'xe',
  },
  {
    'Character': 'ኆ',
    'ID': 'xo',
  },
  {
    'Character': 'ኇ',
    'ID': 'xoa',
  },
  {
    'Character': 'ኈ',
    'ID': 'xwa',
  },
  {
    'Character': 'ኊ',
    'ID': 'xwi',
  },
  {
    'Character': 'ኋ',
    'ID': 'xwaa',
  },
  {
    'Character': 'ኌ',
    'ID': 'xwee',
  },
  {
    'Character': 'ኍ',
    'ID': 'xwe',
  },
  {
    'Character': 'ነ',
    'ID': 'na',
  },
  {
    'Character': 'ኑ',
    'ID': 'nu',
  },
  {
    'Character': 'ኒ',
    'ID': 'ni',
  },
  {
    'Character': 'ና',
    'ID': 'naa',
  },
  {
    'Character': 'ኔ',
    'ID': 'nee',
  },
  {
    'Character': 'ን',
    'ID': 'ne',
  },
  {
    'Character': 'ኖ',
    'ID': 'no',
  },
  {
    'Character': 'ኗ',
    'ID': 'nwa',
  },
  {
    'Character': 'ኘ',
    'ID': 'nya',
  },
  {
    'Character': 'ኙ',
    'ID': 'nyu',
  },
  {
    'Character': 'ኚ',
    'ID': 'nyi',
  },
  {
    'Character': 'ኛ',
    'ID': 'nyaa',
  },
  {
    'Character': 'ኜ',
    'ID': 'nyee',
  },
  {
    'Character': 'ኝ',
    'ID': 'nye',
  },
  {
    'Character': 'ኞ',
    'ID': 'nyo',
  },
  {
    'Character': 'ኟ',
    'ID': 'nywa',
  },
  {
    'Character': 'ከ',
    'ID': 'ka',
  },
  {
    'Character': 'ኩ',
    'ID': 'ku',
  },
  {
    'Character': 'ኪ',
    'ID': 'ki',
  },
  {
    'Character': 'ካ',
    'ID': 'kaa',
  },
  {
    'Character': 'ኬ',
    'ID': 'kee',
  },
  {
    'Character': 'ክ',
    'ID': 'ke',
  },
  {
    'Character': 'ኮ',
    'ID': 'ko',
  },
  {
    'Character': 'ኯ',
    'ID': 'koa',
  },
  {
    'Character': 'ኰ',
    'ID': 'kwa',
  },
  {
    'Character': 'ኲ',
    'ID': 'kwi',
  },
  {
    'Character': 'ኳ',
    'ID': 'kwaa',
  },
  {
    'Character': 'ኴ',
    'ID': 'kwee',
  },
  {
    'Character': 'ኵ',
    'ID': 'kwe',
  },
  {
    'Character': 'ኸ',
    'ID': 'kxa',
  },
  {
    'Character': 'ኹ',
    'ID': 'kxu',
  },
  {
    'Character': 'ኺ',
    'ID': 'kxi',
  },
  {
    'Character': 'ኻ',
    'ID': 'kxaa',
  },
  {
    'Character': 'ኼ',
    'ID': 'kxee',
  },
  {
    'Character': 'ኽ',
    'ID': 'kxe',
  },
  {
    'Character': 'ኾ',
    'ID': 'kxo',
  },
  {
    'Character': 'ዀ',
    'ID': 'kxwa',
  },
  {
    'Character': 'ዂ',
    'ID': 'kxwi',
  },
  {
    'Character': 'ዃ',
    'ID': 'kxwaa',
  },
  {
    'Character': 'ዄ',
    'ID': 'kxwee',
  },
  {
    'Character': 'ዅ',
    'ID': 'kxwe',
  },
  {
    'Character': 'ወ',
    'ID': 'wa',
  },
  {
    'Character': 'ዉ',
    'ID': 'wu',
  },
  {
    'Character': 'ዊ',
    'ID': 'wi',
  },
  {
    'Character': 'ዋ',
    'ID': 'waa',
  },
  {
    'Character': 'ዌ',
    'ID': 'wee',
  },
  {
    'Character': 'ው',
    'ID': 'we',
  },
  {
    'Character': 'ዎ',
    'ID': 'wo',
  },
  {
    'Character': 'ዏ',
    'ID': 'woa',
  },
  {
    'Character': 'ዘ',
    'ID': 'za',
  },
  {
    'Character': 'ዙ',
    'ID': 'zu',
  },
  {
    'Character': 'ዚ',
    'ID': 'zi',
  },
  {
    'Character': 'ዛ',
    'ID': 'zaa',
  },
  {
    'Character': 'ዜ',
    'ID': 'zee',
  },
  {
    'Character': 'ዝ',
    'ID': 'ze',
  },
  {
    'Character': 'ዞ',
    'ID': 'zo',
  },
  {
    'Character': 'ዟ',
    'ID': 'zwa',
  },
  {
    'Character': 'ዠ',
    'ID': 'zha',
  },
  {
    'Character': 'ዡ',
    'ID': 'zhu',
  },
  {
    'Character': 'ዢ',
    'ID': 'zhi',
  },
  {
    'Character': 'ዣ',
    'ID': 'zhaa',
  },
  {
    'Character': 'ዤ',
    'ID': 'zhee',
  },
  {
    'Character': 'ዥ',
    'ID': 'zhe',
  },
  {
    'Character': 'ዦ',
    'ID': 'zho',
  },
  {
    'Character': 'ዧ',
    'ID': 'zhwa',
  },
  {
    'Character': 'የ',
    'ID': 'ya',
  },
  {
    'Character': 'ዩ',
    'ID': 'yu',
  },
  {
    'Character': 'ዪ',
    'ID': 'yi',
  },
  {
    'Character': 'ያ',
    'ID': 'yaa',
  },
  {
    'Character': 'ዬ',
    'ID': 'yee',
  },
  {
    'Character': 'ይ',
    'ID': 'ye',
  },
  {
    'Character': 'ዮ',
    'ID': 'yo',
  },
  {
    'Character': 'ዯ',
    'ID': 'yoa',
  },
  {
    'Character': 'ደ',
    'ID': 'da',
  },
  {
    'Character': 'ዱ',
    'ID': 'du',
  },
  {
    'Character': 'ዲ',
    'ID': 'di',
  },
  {
    'Character': 'ዳ',
    'ID': 'daa',
  },
  {
    'Character': 'ዴ',
    'ID': 'dee',
  },
  {
    'Character': 'ድ',
    'ID': 'de',
  },
  {
    'Character': 'ዶ',
    'ID': 'do',
  },
  {
    'Character': 'ዷ',
    'ID': 'dwa',
  },
  {
    'Character': 'ዸ',
    'ID': 'dda',
  },
  {
    'Character': 'ዹ',
    'ID': 'ddu',
  },
  {
    'Character': 'ዺ',
    'ID': 'ddi',
  },
  {
    'Character': 'ዻ',
    'ID': 'ddaa',
  },
  {
    'Character': 'ዼ',
    'ID': 'ddee',
  },
  {
    'Character': 'ዽ',
    'ID': 'dde',
  },
  {
    'Character': 'ዾ',
    'ID': 'ddo',
  },
  {
    'Character': 'ዿ',
    'ID': 'ddwa',
  },
  {
    'Character': 'ጀ',
    'ID': 'ja',
  },
  {
    'Character': 'ጁ',
    'ID': 'ju',
  },
  {
    'Character': 'ጂ',
    'ID': 'ji',
  },
  {
    'Character': 'ጃ',
    'ID': 'jaa',
  },
  {
    'Character': 'ጄ',
    'ID': 'jee',
  },
  {
    'Character': 'ጅ',
    'ID': 'je',
  },
  {
    'Character': 'ጆ',
    'ID': 'jo',
  },
  {
    'Character': 'ጇ',
    'ID': 'jwa',
  },
  {
    'Character': 'ገ',
    'ID': 'ga',
  },
  {
    'Character': 'ጉ',
    'ID': 'gu',
  },
  {
    'Character': 'ጊ',
    'ID': 'gi',
  },
  {
    'Character': 'ጋ',
    'ID': 'gaa',
  },
  {
    'Character': 'ጌ',
    'ID': 'gee',
  },
  {
    'Character': 'ግ',
    'ID': 'ge',
  },
  {
    'Character': 'ጎ',
    'ID': 'go',
  },
  {
    'Character': 'ጏ',
    'ID': 'goa',
  },
  {
    'Character': 'ጐ',
    'ID': 'gwa',
  },
  {
    'Character': 'ጒ',
    'ID': 'gwi',
  },
  {
    'Character': 'ጓ',
    'ID': 'gwaa',
  },
  {
    'Character': 'ጔ',
    'ID': 'gwee',
  },
  {
    'Character': 'ጕ',
    'ID': 'gwe',
  },
  {
    'Character': 'ጘ',
    'ID': 'gga',
  },
  {
    'Character': 'ጙ',
    'ID': 'ggu',
  },
  {
    'Character': 'ጚ',
    'ID': 'ggi',
  },
  {
    'Character': 'ጛ',
    'ID': 'ggaa',
  },
  {
    'Character': 'ጜ',
    'ID': 'ggee',
  },
  {
    'Character': 'ጝ',
    'ID': 'gge',
  },
  {
    'Character': 'ጞ',
    'ID': 'ggo',
  },
  {
    'Character': 'ጟ',
    'ID': 'ggwaa',
  },
  {
    'Character': 'ጠ',
    'ID': 'tha',
  },
  {
    'Character': 'ጡ',
    'ID': 'thu',
  },
  {
    'Character': 'ጢ',
    'ID': 'thi',
  },
  {
    'Character': 'ጣ',
    'ID': 'thaa',
  },
  {
    'Character': 'ጤ',
    'ID': 'thee',
  },
  {
    'Character': 'ጥ',
    'ID': 'the',
  },
  {
    'Character': 'ጦ',
    'ID': 'tho',
  },
  {
    'Character': 'ጧ',
    'ID': 'thwa',
  },
  {
    'Character': 'ጨ',
    'ID': 'cha',
  },
  {
    'Character': 'ጩ',
    'ID': 'chu',
  },
  {
    'Character': 'ጪ',
    'ID': 'chi',
  },
  {
    'Character': 'ጫ',
    'ID': 'chaa',
  },
  {
    'Character': 'ጬ',
    'ID': 'chee',
  },
  {
    'Character': 'ጭ',
    'ID': 'che',
  },
  {
    'Character': 'ጮ',
    'ID': 'cho',
  },
  {
    'Character': 'ጯ',
    'ID': 'chwa',
  },
  {
    'Character': 'ጰ',
    'ID': 'pha',
  },
  {
    'Character': 'ጱ',
    'ID': 'phu',
  },
  {
    'Character': 'ጲ',
    'ID': 'phi',
  },
  {
    'Character': 'ጳ',
    'ID': 'phaa',
  },
  {
    'Character': 'ጴ',
    'ID': 'phee',
  },
  {
    'Character': 'ጵ',
    'ID': 'phe',
  },
  {
    'Character': 'ጶ',
    'ID': 'pho',
  },
  {
    'Character': 'ጷ',
    'ID': 'phwa',
  },
  {
    'Character': 'ጸ',
    'ID': 'tsa',
  },
  {
    'Character': 'ጹ',
    'ID': 'tsu',
  },
  {
    'Character': 'ጺ',
    'ID': 'tsi',
  },
  {
    'Character': 'ጻ',
    'ID': 'tsaa',
  },
  {
    'Character': 'ጼ',
    'ID': 'tsee',
  },
  {
    'Character': 'ጽ',
    'ID': 'tse',
  },
  {
    'Character': 'ጾ',
    'ID': 'tso',
  },
  {
    'Character': 'ጿ',
    'ID': 'tswa',
  },
  {
    'Character': 'ፀ',
    'ID': 'tza',
  },
  {
    'Character': 'ፁ',
    'ID': 'tzu',
  },
  {
    'Character': 'ፂ',
    'ID': 'tzi',
  },
  {
    'Character': 'ፃ',
    'ID': 'tzaa',
  },
  {
    'Character': 'ፄ',
    'ID': 'tzee',
  },
  {
    'Character': 'ፅ',
    'ID': 'tze',
  },
  {
    'Character': 'ፆ',
    'ID': 'tzo',
  },
  {
    'Character': 'ፇ',
    'ID': 'tzoa',
  },
  {
    'Character': 'ፈ',
    'ID': 'fa',
  },
  {
    'Character': 'ፉ',
    'ID': 'fu',
  },
  {
    'Character': 'ፊ',
    'ID': 'fi',
  },
  {
    'Character': 'ፋ',
    'ID': 'faa',
  },
  {
    'Character': 'ፌ',
    'ID': 'fee',
  },
  {
    'Character': 'ፍ',
    'ID': 'fe',
  },
  {
    'Character': 'ፎ',
    'ID': 'fo',
  },
  {
    'Character': 'ፏ',
    'ID': 'fwa',
  },
  {
    'Character': 'ፐ',
    'ID': 'pa',
  },
  {
    'Character': 'ፑ',
    'ID': 'pu',
  },
  {
    'Character': 'ፒ',
    'ID': 'pi',
  },
  {
    'Character': 'ፓ',
    'ID': 'paa',
  },
  {
    'Character': 'ፔ',
    'ID': 'pee',
  },
  {
    'Character': 'ፕ',
    'ID': 'pe',
  },
  {
    'Character': 'ፖ',
    'ID': 'po',
  },
  {
    'Character': 'ፗ',
    'ID': 'pwa',
  },
  {
    'Character': 'ፘ',
    'ID': 'rya',
  },
  {
    'Character': 'ፙ',
    'ID': 'mya',
  },
  {
    'Character': 'ፚ',
    'ID': 'fya',
  },
  {
    'Character': 'ᎁ',
    'ID': 'mwi',
  },
  {
    'Character': 'ᎂ',
    'ID': 'mwee',
  },
  {
    'Character': 'ᎃ',
    'ID': 'mwe',
  },
  {
    'Character': 'ᎅ',
    'ID': 'bwi',
  },
  {
    'Character': 'ᎆ',
    'ID': 'bwee',
  },
  {
    'Character': 'ᎇ',
    'ID': 'bwe',
  },
  {
    'Character': 'ᎉ',
    'ID': 'fwi',
  },
  {
    'Character': 'ᎊ',
    'ID': 'fwee',
  },
  {
    'Character': 'ᎋ',
    'ID': 'fwe',
  },
  {
    'Character': 'ᎍ',
    'ID': 'pwi',
  },
  {
    'Character': 'ᎎ',
    'ID': 'pwee',
  },
  {
    'Character': 'ᎏ',
    'ID': 'pwe',
  },
  {
    'Character': 'ⶀ',
    'ID': 'loa',
  },
  {
    'Character': 'ⶁ',
    'ID': 'moa',
  },
  {
    'Character': 'ⶂ',
    'ID': 'roa',
  },
  {
    'Character': 'ⶃ',
    'ID': 'soa',
  },
  {
    'Character': 'ⶄ',
    'ID': 'shoa',
  },
  {
    'Character': 'ⶅ',
    'ID': 'boa',
  },
  {
    'Character': 'ⶆ',
    'ID': 'toa',
  },
  {
    'Character': 'ⶇ',
    'ID': 'coa',
  },
  {
    'Character': 'ⶈ',
    'ID': 'noa',
  },
  {
    'Character': 'ⶉ',
    'ID': 'nyoa',
  },
  {
    'Character': 'ⶋ',
    'ID': 'zoa',
  },
  {
    'Character': 'ⶌ',
    'ID': 'doa',
  },
  {
    'Character': 'ⶍ',
    'ID': 'ddoa',
  },
  {
    'Character': 'ⶎ',
    'ID': 'joa',
  },
  {
    'Character': 'ⶏ',
    'ID': 'thoa',
  },
  {
    'Character': 'ⶐ',
    'ID': 'choa',
  },
  {
    'Character': 'ⶑ',
    'ID': 'phoa',
  },
  {
    'Character': 'ⶒ',
    'ID': 'poa',
  },
  {
    'Character': 'ⶓ',
    'ID': 'ggwa',
  },
  {
    'Character': 'ⶔ',
    'ID': 'ggwi',
  },
  {
    'Character': 'ⶕ',
    'ID': 'ggwee',
  },
  {
    'Character': 'ⶖ',
    'ID': 'ggwe',
  },
  {
    'Character': 'ⶠ',
    'ID': 'ssa',
  },
  {
    'Character': 'ⶡ',
    'ID': 'ssu',
  },
  {
    'Character': 'ⶢ',
    'ID': 'ssi',
  },
  {
    'Character': 'ⶣ',
    'ID': 'ssaa',
  },
  {
    'Character': 'ⶤ',
    'ID': 'ssee',
  },
  {
    'Character': 'ⶥ',
    'ID': 'sse',
  },
  {
    'Character': 'ⶦ',
    'ID': 'sso',
  },
  {
    'Character': 'ⶨ',
    'ID': 'cca',
  },
  {
    'Character': 'ⶩ',
    'ID': 'ccu',
  },
  {
    'Character': 'ⶪ',
    'ID': 'cci',
  },
  {
    'Character': 'ⶫ',
    'ID': 'ccaa',
  },
  {
    'Character': 'ⶬ',
    'ID': 'ccee',
  },
  {
    'Character': 'ⶭ',
    'ID': 'cce',
  },
  {
    'Character': 'ⶮ',
    'ID': 'cco',
  },
  {
    'Character': 'ⶰ',
    'ID': 'zza',
  },
  {
    'Character': 'ⶱ',
    'ID': 'zzu',
  },
  {
    'Character': 'ⶲ',
    'ID': 'zzi',
  },
  {
    'Character': 'ⶳ',
    'ID': 'zzaa',
  },
  {
    'Character': 'ⶴ',
    'ID': 'zzee',
  },
  {
    'Character': 'ⶵ',
    'ID': 'zze',
  },
  {
    'Character': 'ⶶ',
    'ID': 'zzo',
  },
  {
    'Character': 'ⶸ',
    'ID': 'ccha',
  },
  {
    'Character': 'ⶹ',
    'ID': 'cchu',
  },
  {
    'Character': 'ⶺ',
    'ID': 'cchi',
  },
  {
    'Character': 'ⶻ',
    'ID': 'cchaa',
  },
  {
    'Character': 'ⶼ',
    'ID': 'cchee',
  },
  {
    'Character': 'ⶽ',
    'ID': 'cche',
  },
  {
    'Character': 'ⶾ',
    'ID': 'ccho',
  },
  {
    'Character': 'ⷀ',
    'ID': 'qya',
  },
  {
    'Character': 'ⷁ',
    'ID': 'qyu',
  },
  {
    'Character': 'ⷂ',
    'ID': 'qyi',
  },
  {
    'Character': 'ⷃ',
    'ID': 'qyaa',
  },
  {
    'Character': 'ⷄ',
    'ID': 'qyee',
  },
  {
    'Character': 'ⷅ',
    'ID': 'qye',
  },
  {
    'Character': 'ⷆ',
    'ID': 'qyo',
  },
  {
    'Character': 'ⷈ',
    'ID': 'kya',
  },
  {
    'Character': 'ⷉ',
    'ID': 'kyu',
  },
  {
    'Character': 'ⷊ',
    'ID': 'kyi',
  },
  {
    'Character': 'ⷋ',
    'ID': 'kyaa',
  },
  {
    'Character': 'ⷌ',
    'ID': 'kyee',
  },
  {
    'Character': 'ⷍ',
    'ID': 'kye',
  },
  {
    'Character': 'ⷎ',
    'ID': 'kyo',
  },
  {
    'Character': 'ⷐ',
    'ID': 'xya',
  },
  {
    'Character': 'ⷑ',
    'ID': 'xyu',
  },
  {
    'Character': 'ⷒ',
    'ID': 'xyi',
  },
  {
    'Character': 'ⷓ',
    'ID': 'xyaa',
  },
  {
    'Character': 'ⷔ',
    'ID': 'xyee',
  },
  {
    'Character': 'ⷕ',
    'ID': 'xye',
  },
  {
    'Character': 'ⷖ',
    'ID': 'xyo',
  },
  {
    'Character': 'ⷘ',
    'ID': 'gya',
  },
  {
    'Character': 'ⷙ',
    'ID': 'gyu',
  },
  {
    'Character': 'ⷚ',
    'ID': 'gyi',
  },
  {
    'Character': 'ⷛ',
    'ID': 'gyaa',
  },
  {
    'Character': 'ⷜ',
    'ID': 'gyee',
  },
  {
    'Character': 'ⷝ',
    'ID': 'gye',
  },
  {
    'Character': 'ⷞ',
    'ID': 'gyo',
  },
  {
    'Character': 'ꬁ',
    'ID': 'tthu',
  },
  {
    'Character': 'ꬂ',
    'ID': 'tthi',
  },
  {
    'Character': 'ꬃ',
    'ID': 'tthaa',
  },
  {
    'Character': 'ꬄ',
    'ID': 'tthee',
  },
  {
    'Character': 'ꬅ',
    'ID': 'tthe',
  },
  {
    'Character': 'ꬆ',
    'ID': 'ttho',
  },
  {
    'Character': 'ꬉ',
    'ID': 'ddhu',
  },
  {
    'Character': 'ꬊ',
    'ID': 'ddhi',
  },
  {
    'Character': 'ꬋ',
    'ID': 'ddhaa',
  },
  {
    'Character': 'ꬌ',
    'ID': 'ddhee',
  },
  {
    'Character': 'ꬍ',
    'ID': 'ddhe',
  },
  {
    'Character': 'ꬎ',
    'ID': 'ddho',
  },
  {
    'Character': 'ꬑ',
    'ID': 'dzu',
  },
  {
    'Character': 'ꬒ',
    'ID': 'dzi',
  },
  {
    'Character': 'ꬓ',
    'ID': 'dzaa',
  },
  {
    'Character': 'ꬔ',
    'ID': 'dzee',
  },
  {
    'Character': 'ꬕ',
    'ID': 'dze',
  },
  {
    'Character': 'ꬖ',
    'ID': 'dzo',
  },
  {
    'Character': 'ꬠ',
    'ID': 'cchha',
  },
  {
    'Character': 'ꬡ',
    'ID': 'cchhu',
  },
  {
    'Character': 'ꬢ',
    'ID': 'cchhi',
  },
  {
    'Character': 'ꬣ',
    'ID': 'cchhaa',
  },
  {
    'Character': 'ꬤ',
    'ID': 'cchhee',
  },
  {
    'Character': 'ꬥ',
    'ID': 'cchhe',
  },
  {
    'Character': 'ꬦ',
    'ID': 'cchho',
  },
  {
    'Character': 'ꬨ',
    'ID': 'bba',
  },
  {
    'Character': 'ꬩ',
    'ID': 'bbu',
  },
  {
    'Character': 'ꬪ',
    'ID': 'bbi',
  },
  {
    'Character': 'ꬫ',
    'ID': 'bbaa',
  },
  {
    'Character': 'ꬬ',
    'ID': 'bbee',
  },
  {
    'Character': 'ꬭ',
    'ID': 'bbe',
  },
  {
    'Character': 'ꬮ',
    'ID': 'bbo',
  },
  {
    'Character': 'ⶊ',
    'ID': 'oa_glottal',
  },
  {
    'Character': 'ዐ',
    'ID': 'a_pharyngeal',
  },
  {
    'Character': 'ዑ',
    'ID': 'u_pharyngeal',
  },
  {
    'Character': 'ዒ',
    'ID': 'i_pharyngeal',
  },
  {
    'Character': 'ዓ',
    'ID': 'aa_pharyngeal',
  },
  {
    'Character': 'ዔ',
    'ID': 'ee_pharyngeal',
  },
  {
    'Character': 'ዕ',
    'ID': 'e_pharyngeal',
  },
  {
    'Character': 'ዖ',
    'ID': 'o_pharyngeal',
  },
  {
    'Character': 'ᎀ',
    'ID': 'mwa_sebatbeit',
  },
  {
    'Character': 'ᎄ',
    'ID': 'bwa_sebatbeit',
  },
  {
    'Character': 'ᎈ',
    'ID': 'fwa_sebatbeit',
  },
  {
    'Character': 'ᎌ',
    'ID': 'pwa_sebatbeit',
  },
  {
    'Character': 'አ',
    'ID': 'a_glottal',
  },
  {
    'Character': 'ኡ',
    'ID': 'u_glottal',
  },
  {
    'Character': 'ኢ',
    'ID': 'i_glottal',
  },
  {
    'Character': 'ኣ',
    'ID': 'aa_glottal',
  },
  {
    'Character': 'ኤ',
    'ID': 'ee_glottal',
  },
  {
    'Character': 'እ',
    'ID': 'e_glottal',
  },
  {
    'Character': 'ኦ',
    'ID': 'o_glottal',
  },
  {
    'Character': 'ኧ',
    'ID': 'wa_glottal',
  },
];
