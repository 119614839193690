/* eslint-disable require-jsdoc */
import React from 'react';

function ExportAudioX({props, languageId}: any) {
  const exportAll = () => {
    props.approveAll(languageId);
  };
  return (
    <>
      <button
        title="Export to AudioX"
        onClick={() => {
          exportAll();
        }}
        className="k-button k-primary"
        style={{padding: 10}}
      >
        <span className={'k-icon k-i-export'}></span> Export to AudioX
      </button>
    </>
  );
}

export default ExportAudioX;
