/* eslint-disable require-jsdoc */
import {schemeService} from '../../services/scheme.service';
import {schemesConstants} from '../constants';

export const schemeActions = {
  getAll,
};

function getAll() {
  return async (dispatch: any) => {
    dispatch(request());

    await schemeService.getAll().then(
      (schemes: any) => dispatch(success(schemes)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: schemesConstants.GETALL_REQUEST};
  }
  function success(schemes: any) {
    return {type: schemesConstants.GETALL_SUCCESS, schemes};
  }
  function failure(error: any) {
    return {type: schemesConstants.GETALL_FAILURE, error};
  }
}
