/* eslint object-curly-spacing: ["error", "always", { "objectsInObjects": false }]*/
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

const middleware = [thunk];
const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

export default createStore(
  reducer,
  {},
  enhancer,
);
