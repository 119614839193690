/* eslint-disable require-jsdoc */
import {isolatedSoundService} from '../../services/isolatedSound.service';
import {isolatedSoundConstants} from '../constants';

export const isolatedSoundActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());
    await isolatedSoundService.getAll(languageId).then(
      (isolatedSounds: any) => dispatch(success(isolatedSounds)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: isolatedSoundConstants.GETALL_REQUEST};
  }
  function success(isolatedSound: any) {
    return {type: isolatedSoundConstants.GETALL_SUCCESS, isolatedSound};
  }
  function failure(error: any) {
    return {type: isolatedSoundConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    isolatedSoundService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: isolatedSoundConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: isolatedSoundConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {type: isolatedSoundConstants.UPDATE_FAILURE, letter, error};
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await isolatedSoundService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: isolatedSoundConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: isolatedSoundConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: isolatedSoundConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await isolatedSoundService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: isolatedSoundConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: isolatedSoundConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: isolatedSoundConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await isolatedSoundService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: isolatedSoundConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: isolatedSoundConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: isolatedSoundConstants.APPROVE_ALL_FAILURE, error};
  }
}
