import {roles} from '../constant/roles';
import {getUserRole} from './getUserRole';

/* eslint-disable require-jsdoc */
export function relatedLanguageRules(user: any, languageId: string) {
  const isAdmin = getUserRole() === roles.admin;
  if (isAdmin) {
    return {
      read: true,
      write: true,
    };
  } else {
    const rules =
      user &&
      JSON.parse(user).access.find((x: any) => x.languageId === languageId);
    return rules;
  }
}
