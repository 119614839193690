/* eslint-disable require-jsdoc */
import {Controls, Player} from '@lottiefiles/react-lottie-player';
import {Dialog} from '@progress/kendo-react-dialogs';
import React from 'react';
import OfflineModalImage from '../../assets/images/modals/offlineModalImage.json';

function OfflineModal({}: any) {
  return (
    <>
      <Dialog>
        <Player
          autoplay
          loop
          src={OfflineModalImage}
          style={{height: '300px', width: '400px'}}
        >
          <Controls
            visible={false}
            buttons={['play', 'repeat', 'frame', 'debug']}
          />
        </Player>
      </Dialog>
    </>
  );
}

export default OfflineModal;
