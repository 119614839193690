/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerSelectEvent,
} from '@progress/kendo-react-layout';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {localActions} from '../redux/actions';

const DrawerRouterContainer = (props: any) => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();

  const menuItems = useSelector((state: any) => state.local.navbarData) ?? [];
  const CustomItem = (props: any) => {
    const {visible, isParent, ...others} = props;
    const arrowDir = props['data-expanded']
      ? 'k-i-arrow-chevron-down'
      : 'k-i-arrow-chevron-right';
    return (
      <React.Fragment>
        {visible === false ? null : (
          <DrawerItem {...others}>
            <span
              className={'k-icon ' + props.icon}
              style={{paddingLeft: isParent && 10}}
            />
            <span className={'k-item-text'}>{props.text}</span>
            {props['data-expanded'] !== undefined && (
              <span className={'k-icon ' + arrowDir} style={{right: 10}} />
            )}
          </DrawerItem>
        )}
      </React.Fragment>
    );
  };

  const handleClick = () => {
    dispatch(localActions.changeNavbarStatus());
  };

  const navbarStatus = useSelector((state: any) => state.local.navbarStatus);

  const onSelect = (ev: DrawerSelectEvent) => {
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem['data-expanded'] !== undefined;
    const nextExpanded = !currentItem['data-expanded'];
    const newData = menuItems.map((item: any) => {
      const {['data-expanded']: currentExpanded, id, ...others} = item;
      const isCurrentItem = currentItem.id === id;
      return {
        ['data-expanded']:
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
        selected: isCurrentItem,
      };
    });

    props.history.push(ev.itemTarget.props.route);
    dispatch(localActions.setNavbarData(newData));
    dispatch(localActions.changeSelectedSection(ev.itemTarget.props.route));
    if (!isParent) {
      dispatch(localActions.changeNavbarStatus());
    }
  };

  const data = menuItems.map((item: any) => {
    const {parentId, ...others} = item;
    if (parentId !== undefined) {
      const parent: any = menuItems.find(
        (parent: any) => parent.id === parentId,
      );
      return {
        ...others,
        isParent: parentId ? true : false,
        visible: parent['data-expanded'] ? true : false,
      };
    }
    return item;
  });

  return (
    <div>
      <Drawer
        expanded={navbarStatus}
        position={'start'}
        mode={'overlay'}
        dir={i18n.dir()}
        animation={{duration: 500}}
        items={data}
        item={CustomItem}
        onSelect={onSelect}
        onOverlayClick={handleClick}
      >
        <DrawerContent>{props.children}</DrawerContent>
      </Drawer>
    </div>
  );
};

export default withRouter(DrawerRouterContainer);
