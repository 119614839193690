/* eslint-disable require-jsdoc */
import {tagService} from '../../services/tag.service';
import {tagConstants} from '../constants';

export const tagActions = {
  getAll,
  insert,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await tagService.getAll(languageId).then(
      (tags: any) => dispatch(success(tags)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: tagConstants.GETALL_REQUEST};
  }
  function success(tag: any) {
    return {type: tagConstants.GETALL_SUCCESS, tag};
  }
  function failure(error: any) {
    return {type: tagConstants.GETALL_FAILURE, error};
  }
}

function insert(tag: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await tagService.insert(tag).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: tagConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: tagConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: tagConstants.INSERT_FAILURE, error};
  }
}
