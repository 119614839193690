export const languageConstants = {
  GETALL_REQUEST: 'LANGUAGES_GETALL_REQUEST',
  GETALL_SUCCESS: 'LANGUAGES_GETALL_SUCCESS',
  GETALL_FAILURE: 'LANGUAGES_GETALL_FAILURE',

  SETINTERFACELANGUAGE_REQUEST: 'LANGUAGES_SETINTERFACELANGUAGE_REQUEST',

  INSERT_REQUEST: 'LANGUAGES_INSERT_REQUEST',
  INSERT_SUCCESS: 'LANGUAGES_INSERT_SUCCESS',
  INSERT_FAILURE: 'LANGUAGES_INSERT_FAILURE',
};
