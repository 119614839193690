/* eslint-disable indent */

import {
  alphabetConstants,
  conjugatedVerbConstants,
  flashCardConstants,
  isolatedSoundConstants,
  isolatedSyllableConstants,
  morphologyConstants,
  nounsWithArticleConstants,
  nonWordConstants,
  onetestLetterNameConstants,
  onetestLetterSoundConstants,
  onetestNonWordConstants,
  onetestNumberIdentificationConstants,
  onetestPhonologicalAwarenessConstants,
  onetestSyllableIdentificationConstants,
  onetestWordConstants,
  phraseConstants,
  sentenceConstants,
  storyConstants,
} from '../redux/constants';

/* eslint-disable require-jsdoc */
export const getRelatedSectionConstant = (type: string) => {
  const sectionPath = '/sections/';
  switch (type) {
    case `${sectionPath}alphabet`:
      return alphabetConstants;
    case `${sectionPath}flashCard`:
      return flashCardConstants;
    case `${sectionPath}isolatedSound`:
      return isolatedSoundConstants;
    case `${sectionPath}isolatedSyllable`:
      return isolatedSyllableConstants;
    case `${sectionPath}onetestLetterName`:
      return onetestLetterNameConstants;
    case `${sectionPath}onetestLetterSound`:
      return onetestLetterSoundConstants;
    case `${sectionPath}onetestNonWord`:
      return onetestNonWordConstants;
    case `${sectionPath}onetestNumberIdentification`:
      return onetestNumberIdentificationConstants;
    case `${sectionPath}onetestPhonologicalAwareness`:
      return onetestPhonologicalAwarenessConstants;
    case `${sectionPath}onetestSyllableIdentification`:
      return onetestSyllableIdentificationConstants;
    case `${sectionPath}onetestWord`:
      return onetestWordConstants;
    case `${sectionPath}nonWord`:
      return nonWordConstants;
    case `${sectionPath}sentence`:
      return sentenceConstants;
    case `${sectionPath}phrase`:
      return phraseConstants;
    case `${sectionPath}conjugatedVerb`:
      return conjugatedVerbConstants;
    case `${sectionPath}nounsWithArticle`:
      return nounsWithArticleConstants;
    case `${sectionPath}morphology`:
      return morphologyConstants;
    case `${sectionPath}story`:
      return storyConstants;
    default:
      return alphabetConstants; // TODO:
  }
};
