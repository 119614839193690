/* eslint-disable require-jsdoc */
import {onetestNonWordService} from '../../services/onetestNonWord.service';
import {onetestNonWordConstants} from '../constants';

export const onetestNonWordActions = {
  getAll,
  update: _update,
  insert,
  remove,
  approveAll,
};

function getAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNonWordService.getAll(languageId).then(
      (onetestNonWord: any) => dispatch(success(onetestNonWord)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestNonWordConstants.GETALL_REQUEST};
  }
  function success(onetestNonWord: any) {
    return {
      type: onetestNonWordConstants.GETALL_SUCCESS,
      onetestNonWord,
    };
  }
  function failure(error: any) {
    return {type: onetestNonWordConstants.GETALL_FAILURE, error};
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _update(letter: any) {
  return (dispatch: any) => {
    dispatch(request(letter));

    onetestNonWordService.update(letter).then(
      (res: any) => {
        dispatch(success(letter));
      },
      (error: any) => dispatch(failure(letter, error.toString())),
    );
  };

  function request(letter: any) {
    return {type: onetestNonWordConstants.UPDATE_REQUEST, letter};
  }
  function success(letter: any) {
    return {type: onetestNonWordConstants.UPDATE_SUCCESS, letter};
  }
  function failure(letter: any, error: any) {
    return {
      type: onetestNonWordConstants.UPDATE_FAILURE,
      letter,
      error,
    };
  }
}

function insert(letter: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNonWordService.insert(letter).then(
      (payload: any) => dispatch(success(payload)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestNonWordConstants.INSERT_REQUEST};
  }
  function success(payload: any) {
    return {type: onetestNonWordConstants.INSERT_SUCCESS, payload};
  }
  function failure(error: any) {
    return {type: onetestNonWordConstants.INSERT_FAILURE, error};
  }
}

function remove(id: string) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNonWordService.remove(id).then(
      (payload: any) => dispatch(success(id)),
      (error: any) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: onetestNonWordConstants.REMOVE_REQUEST};
  }
  function success(id: string) {
    return {type: onetestNonWordConstants.REMOVE_SUCCESS, id};
  }
  function failure(error: any) {
    return {type: onetestNonWordConstants.REMOVE_FAILURE, error};
  }
}

function approveAll(languageId: any) {
  return async (dispatch: any) => {
    dispatch(request());

    await onetestNonWordService.approveAll(languageId).then(
      (payload: any) => dispatch(success()),
      (error: any) => dispatch(failure(error)),
    );
  };

  function request() {
    return {type: onetestNonWordConstants.APPROVE_ALL_REQUEST};
  }
  function success() {
    return {type: onetestNonWordConstants.APPROVE_ALL_SUCCESS};
  }
  function failure(error: any) {
    return {type: onetestNonWordConstants.APPROVE_ALL_FAILURE, error};
  }
}
