export const commentConstants = {
  GETALL_REQUEST: 'COMMENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'COMMENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'COMMENT_GETALL_FAILURE',

  UPDATE_REQUEST: 'COMMENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMMENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMMENT_UPDATE_FAILURE',

  INSERT_REQUEST: 'COMMENT_INSERT_REQUEST',
  INSERT_SUCCESS: 'COMMENT_INSERT_SUCCESS',
  INSERT_FAILURE: 'COMMENT_INSERT_FAILURE',

  REMOVE_REQUEST: 'COMMENT_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'COMMENT_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'COMMENT_REMOVE_FAILURE',

  INSERT_COMMENT_SEEN_REQUEST: 'COMMENT_INSERT_COMMENT_SEEN_REQUEST',
  INSERT_COMMENT_SEEN_SUCCESS: 'COMMENT_INSERT_COMMENT_SEEN_SUCCESS',
  INSERT_COMMENT_SEEN_FAILURE: 'COMMENT_INSERT_COMMENT_SEEN_FAILURE',

  GET_EXIST_COMMENTS_COUNT_REQUEST: 'COMMENT_GET_EXIST_COMMENTS_COUNT_REQUEST',
  GET_EXIST_COMMENTS_COUNT_SUCCESS: 'COMMENT_GET_EXIST_COMMENTS_COUNT_SUCCESS',
  GET_EXIST_COMMENTS_COUNT_FAILURE: 'COMMENT_GET_EXIST_COMMENTS_COUNT_FAILURE',

  CLEAN_COMMENTS: 'CLEAN_COMMENTS',
};
