export const storyConstants = {
  GETALL_REQUEST: 'STORY_GETALL_REQUEST',
  GETALL_SUCCESS: 'STORY_GETALL_SUCCESS',
  GETALL_FAILURE: 'STORY_GETALL_FAILURE',

  UPDATE_REQUEST: 'STORY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'STORY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'STORY_UPDATE_FAILURE',

  INSERT_REQUEST: 'STORY_INSERT_REQUEST',
  INSERT_SUCCESS: 'STORY_INSERT_SUCCESS',
  INSERT_FAILURE: 'STORY_INSERT_FAILURE',

  REMOVE_REQUEST: 'STORY_REMOVE_REQUEST',
  REMOVE_SUCCESS: 'STORY_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'STORY_REMOVE_FAILURE',

  MATCH_COMMENT_DATA: 'STORY_MATCH_COMMENT_DATA',
  MATCH_COMMENT_COUNT_DATA: 'STORY_MATCH_COMMENT_COUNT_DATA',
  REMOVE_UNREAD_NOTIFICATION: 'STORY_REMOVE_UNREAD_NOTIFICATION',
};
