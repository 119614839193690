/* eslint-disable indent */
/* eslint-disable require-jsdoc */
import React from 'react';
import {Beforeunload} from 'react-beforeunload';
import {getUserRole} from '../../helpers/getUserRole';
import {roles} from '../../constant/roles';

function NonexportedChanges({data}: any) {
  const isAdmin = getUserRole() === roles.admin;

  return (
    <>
      {isAdmin && data.findIndex((x: any) => x.status === 'Edited') !== -1 && (
        <div>
          <Beforeunload
            onBeforeunload={(event: any) => {
              event.preventDefault();
              alert('');
            }}
          />
          <div
            style={{
              color: 'white',
              padding: 12,
              backgroundColor: '#ffc000',
              fontSize: 15,
            }}
          >
            {`Don't forget to export!`}
          </div>
        </div>
      )}
    </>
  );
}
export default NonexportedChanges;
