/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable new-cap */
/* eslint-disable quote-props */
/* eslint-disable require-jsdoc */
import {Input} from '@progress/kendo-react-inputs';
import {Tooltip} from '@progress/kendo-react-tooltip';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';

export const tooltipField = (
  gridProps: any,
  value: string,
  defaultVal: string,
  defaultValueBaseFrom: string,
  uniqueIdDefaultValue: string,
  disabled: boolean,
  allData: any,
  updateData: Function,
) => {
  const {dataItem} = gridProps;
  const field = gridProps.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  // eslint-disable-next-line no-unused-vars
  const [focus, setFocus] = useState(false);
  const [selectedText, setSelectedText] = useState<Selection | null>(null);
  const [selectedStr, selectedTextStr] =
    useState<String | null | undefined>(null);
  const tags = useSelector((state: any) => state.tag.data);
  const markupMode = useSelector((state: any) => state.morphology.markupMode);

  const removeRelatedMrange = (
    dataItem: any,
    start: number,
    end: number,
    mtypes: any,
  ) => {
    for (let i = 0; i < mtypes.length; i++) {
      const mtype = mtypes[i];
      const relatedTag = tags.find((x: any) => x.value === mtype);
      const relatedMarkupIndex = dataItem.markups.findIndex(
        (x: any) => x.mtype === relatedTag.value,
      );
      const mranges = dataItem.markups[relatedMarkupIndex].mrange
        .split(',')
        .filter((mrange: any) => {
          return mrange !== `${start}-${end}`;
        })
        .join(',');
      if (mranges && mranges.length > 0) {
        dataItem.markups[relatedMarkupIndex].mrange = mranges;
      } else {
        dataItem.markups =
          dataItem.markups.length > 1
            ? dataItem.markups.filter((x: any) => x.mtype !== relatedTag.value)
            : [];
      }
      const obj: any = {
        id: dataItem.id,
        markups: dataItem.markups,
      };
      updateData(obj);
    }
  };

  const getRelatedTagNames = (mtypes: any[]) => {
    let name: any;
    if (mtypes.length === 1) {
      name = tags.find((x: any) => x.value === mtypes[0]).key;
    } else {
      name = mtypes
        .reduce((a: any, b: any) => (a += ` | ${b}`), '')
        .substring(2);
    }
    return name;
  };

  const showMarkups = () => {
    const text = dataValue.toString();
    if (dataItem.markups && dataItem.markups.length > 0) {
      const parsedData: any[] = [];
      dataItem.markups.map((markup: any) => {
        const parsedMrange = markup.mrange.split(',');
        parsedMrange.map((mrange: any) => {
          const existMrangeIndex = parsedData.findIndex(
            (x: any) => x.mrange === mrange,
          );
          if (existMrangeIndex !== -1) {
            parsedData[existMrangeIndex].mtypes.push(markup.mtype);
          } else {
            parsedData.push({mrange: mrange, mtypes: [markup.mtype]});
          }
        });
      });

      const newString: any = [];
      const sortedParsedData = parsedData.sort((a: any, b: any) => {
        return (
          parseInt(a.mrange.split('-')[0], 10) -
          parseInt(b.mrange.split('-')[0], 10)
        );
      });
      sortedParsedData.map((markup: any, index: number) => {
        const relatedTag = tags.find((x: any) => x.value === markup.mtypes[0]);
        const tagName = getRelatedTagNames(markup.mtypes);
        const parseMrange = markup.mrange.split('-');
        const start = parseInt(parseMrange[0]);
        const end = parseInt(parseMrange[parseMrange.length - 1]);
        if (index === 0) {
          if (start === 0) {
            newString.push(
              markup.mtypes.length === 1 ? (
                <span
                  style={{
                    color: relatedTag.color,
                    cursor: markupMode ? 'pointer' : 'auto',
                  }}
                  onClick={() =>
                    markupMode &&
                    removeRelatedMrange(dataItem, start, end, markup.mtypes)
                  }
                  title={tagName}
                >
                  {text.substring(start, end)}
                </span>
              ) : (
                <u>
                  <span
                    style={{
                      color: relatedTag.color,
                      cursor: markupMode ? 'pointer' : 'auto',
                    }}
                    onClick={() =>
                      markupMode &&
                      removeRelatedMrange(dataItem, start, end, markup.mtypes)
                    }
                    title={tagName}
                  >
                    {text.substring(start, end)}
                  </span>
                </u>
              ),
            );
          } else {
            newString.push(
              markup.mtypes.length === 1 ? (
                <span>
                  <span>{text.substring(0, start)}</span>
                  <span
                    style={{
                      color: relatedTag.color,
                      cursor: markupMode ? 'pointer' : 'auto',
                    }}
                    onClick={() =>
                      markupMode &&
                      removeRelatedMrange(dataItem, start, end, markup.mtypes)
                    }
                    title={tagName}
                  >
                    {text.substring(start, end)}
                  </span>
                </span>
              ) : (
                <span>
                  <span>{text.substring(0, start)}</span>
                  <u>
                    <span
                      style={{
                        color: relatedTag.color,
                        cursor: markupMode ? 'pointer' : 'auto',
                      }}
                      onClick={() =>
                        markupMode &&
                        removeRelatedMrange(dataItem, start, end, markup.mtypes)
                      }
                      title={tagName}
                    >
                      {text.substring(start, end)}
                    </span>
                  </u>
                </span>
              ),
            );
          }
          if (sortedParsedData.length === 1) {
            newString.push(<span>{text.substring(end, text.length)}</span>);
          }
        } else if (index === sortedParsedData.length - 1) {
          const prevEnd = sortedParsedData[index - 1].mrange.split('-')[1];
          if (prevEnd + 1 === start) {
            newString.push(
              markup.mtypes.length === 1 ? (
                <span>
                  <span
                    style={{
                      color: relatedTag.color,
                      cursor: markupMode ? 'pointer' : 'auto',
                    }}
                    onClick={() =>
                      markupMode &&
                      removeRelatedMrange(dataItem, start, end, markup.mtypes)
                    }
                    title={tagName}
                  >
                    {text.substring(start, end)}
                  </span>
                  <span>{text.substring(end, text.length)}</span>
                </span>
              ) : (
                <span>
                  <u>
                    <span
                      style={{
                        color: relatedTag.color,
                        cursor: markupMode ? 'pointer' : 'auto',
                      }}
                      onClick={() =>
                        markupMode &&
                        removeRelatedMrange(dataItem, start, end, markup.mtypes)
                      }
                      title={tagName}
                    >
                      {text.substring(start, end)}
                    </span>
                  </u>
                  <span>{text.substring(end, text.length)}</span>
                </span>
              ),
            );
          } else {
            newString.push(
              markup.mtypes.length === 1 ? (
                <span>
                  <span>{text.substring(prevEnd, start)}</span>
                  <span
                    style={{
                      color: relatedTag.color,
                      cursor: markupMode ? 'pointer' : 'auto',
                    }}
                    onClick={() =>
                      markupMode &&
                      removeRelatedMrange(dataItem, start, end, markup.mtypes)
                    }
                    title={tagName}
                  >
                    {text.substring(start, end)}
                  </span>
                  <span>{text.substring(end, text.length)}</span>
                </span>
              ) : (
                <span>
                  <span>{text.substring(0, start)}</span>
                  <u>
                    <span
                      style={{
                        color: relatedTag.color,
                        cursor: markupMode ? 'pointer' : 'auto',
                      }}
                      onClick={() =>
                        markupMode &&
                        removeRelatedMrange(dataItem, start, end, markup.mtypes)
                      }
                      title={tagName}
                    >
                      {text.substring(start, end)}
                    </span>
                  </u>
                  <span>{text.substring(end, text.length)}</span>
                </span>
              ),
            );
          }
        } else {
          const prevEnd = sortedParsedData[index - 1].mrange.split('-')[1];
          if (prevEnd + 1 === start) {
            newString.push(
              markup.mtypes.length === 1 ? (
                <span
                  style={{
                    color: relatedTag.color,
                    cursor: markupMode ? 'pointer' : 'auto',
                  }}
                  onClick={() =>
                    markupMode &&
                    removeRelatedMrange(dataItem, start, end, markup.mtypes)
                  }
                  title={tagName}
                >
                  {text.substring(start, end)}
                </span>
              ) : (
                <u>
                  <span
                    style={{
                      color: relatedTag.color,
                      cursor: markupMode ? 'pointer' : 'auto',
                    }}
                    onClick={() =>
                      markupMode &&
                      removeRelatedMrange(dataItem, start, end, markup.mtypes)
                    }
                    title={tagName}
                  >
                    {text.substring(start, end)}
                  </span>
                </u>
              ),
            );
          } else {
            newString.push(
              markup.mtypes.length === 1 ? (
                <span>
                  <span>{text.substring(prevEnd, start)}</span>
                  <span
                    style={{
                      color: relatedTag.color,
                      cursor: markupMode ? 'pointer' : 'auto',
                    }}
                    onClick={() =>
                      markupMode &&
                      removeRelatedMrange(dataItem, start, end, markup.mtypes)
                    }
                    title={tagName}
                  >
                    {text.substring(start, end)}
                  </span>
                </span>
              ) : (
                <span>
                  <span>{text.substring(0, start)}</span>
                  <u>
                    <span
                      style={{
                        color: relatedTag.color,
                        cursor: markupMode ? 'pointer' : 'auto',
                      }}
                      onClick={() =>
                        markupMode &&
                        removeRelatedMrange(dataItem, start, end, markup.mtypes)
                      }
                      title={tagName}
                    >
                      {text.substring(start, end)}
                    </span>
                  </u>
                </span>
              ),
            );
          }
        }
      });
      return (
        <Tooltip position={'top'} anchorElement={'pointer'}>
          {newString.map((e: any) => e)}
        </Tooltip>
      );
    }
    return <div>{text}</div>;
  };

  return (
    <td>
      {dataItem.inEdit ? (
        <div>
          {!markupMode ? (
            <Input
              defaultValue={dataValue ? dataValue : defaultVal}
              name={field}
              value={field === 'uniqueId' && !focus ? dataValue : undefined}
              disabled={disabled}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={async (e: any) => {
                setFocus(false);
                const obj: any = {
                  id: dataItem.id,
                };

                obj[`${field}`] = e.target.value;
                if (value !== e.target.value) {
                  obj['status'] = 'Edited';
                  obj['markups'] = [];
                  updateData(obj);
                }
              }}
            />
          ) : (
            <p
              data-tip
              data-for="clickme"
              data-event="click focus"
              onMouseUpCapture={() => {
                const selection = window.getSelection();
                setSelectedText(selection);
                selectedTextStr(selection ? selection?.toString() : null);
              }}
            >
              {dataValue.toString()}
            </p>
          )}
          {selectedStr && tags && (
            <ReactTooltip
              id="clickme"
              place="top"
              effect="solid"
              clickable={true}
              afterShow={() => {}}
            >
              <div style={{display: 'flex'}}>
                {tags.map((e: any, index: number) => {
                  return (
                    <button
                      key={index}
                      style={
                        {
                          margin: '0.5em',
                          color: e.color,

                          background: 'none',
                          border: 'none',
                          padding: 0,
                          font: 'inherit',
                          cursor: markupMode ? 'pointer' : 'auto',
                          outline: 'inherit',
                        } as React.CSSProperties
                      }
                      onClick={() => {
                        const oRange = selectedText?.getRangeAt(0); // get the text range
                        const obj: any = {
                          id: dataItem.id,
                        };
                        const start: any = oRange?.startOffset;
                        const end: any = oRange?.endOffset;
                        const mrange = `${start}-${end}`;

                        const markup = {
                          mtype: e.value,
                          mrange: mrange,
                        };

                        if (dataItem.markups) {
                          const existMtypeIndex = dataItem.markups.findIndex(
                            (x: any) => x.mtype === e.value,
                          );
                          if (existMtypeIndex !== -1) {
                            dataItem.markups[
                              existMtypeIndex
                            ].mrange += `,${mrange}`;
                            dataItem.markups[existMtypeIndex].mrange =
                              dataItem.markups[existMtypeIndex].mrange
                                .split(',')
                                .sort((a: any, b: any) => {
                                  return (
                                    parseInt(a.split('-')[0], 10) -
                                    parseInt(b.split('-')[0], 10)
                                  );
                                })
                                .join(',');
                          } else {
                            dataItem.markups.push(markup);
                          }
                          dataItem.markups = dataItem.markups.sort(
                            (a: any, b: any) => {
                              return (
                                parseInt(a.mrange.split('-')[0], 10) -
                                parseInt(b.mrange.split('-')[0], 10)
                              );
                            },
                          );
                          obj['markups'] = dataItem.markups;
                        } else {
                          obj['markups'] = [markup];
                        }
                        updateData(obj);
                        if (window.getSelection) {
                          if (window?.getSelection()?.empty) {
                            // Chrome
                            window?.getSelection()?.empty();
                          } else if (window?.getSelection()?.removeAllRanges) {
                            // Firefox
                            window?.getSelection()?.removeAllRanges();
                          }
                          setSelectedText(null);
                          selectedTextStr(null);
                        }
                      }}
                    >
                      {e.key}
                    </button>
                  );
                })}
              </div>
            </ReactTooltip>
          )}
        </div>
      ) : dataItem.markups ? (
        showMarkups()
      ) : (
        dataValue.toString()
      )}
    </td>
  );
};
