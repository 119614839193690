/* eslint-disable camelcase */

export const autoStringFixer = (
  target: string,
  selectedLanguageShortCode: string,
) => {
  if (selectedLanguageShortCode === 'pt_MZ') {
    const EM_DASH = '—';
    const WHITESPACE = [' ', '\u00A0', '\u202F', '\u2009', '\u0020'];
    const FULL_STOP = '.';
    const NON_BREAKING_SPACE = ['\u00A0'];
    const NORMAL_SPACE = [' ', '\u0020'];
    let first_em_dash_status = true;

    let correctString = target;
    let operationCount = 0;
    let t = 0;

    for (let i = 0; i < target.length; i++) {
      const char = target[i];
      if (char === EM_DASH) {
        if (i === 1 && WHITESPACE.includes(target[i - 1])) {
          t = i + operationCount;
          correctString =
            correctString.slice(0, t - 1) +
            correctString.slice(t, correctString.length);
          operationCount += -1;
        }
        if (i !== 0 && first_em_dash_status == true) {
          if (!NORMAL_SPACE.includes(target[i - 1])) {
            if (WHITESPACE.includes(target[i - 1])) {
              // REPLACE
              t = i + operationCount;
              correctString =
                correctString.slice(0, t - 1) +
                '\u0020' +
                correctString.slice(t, correctString.length);
            } else {
              // ADD
              t = i + operationCount;
              correctString =
                correctString.slice(0, t) +
                '\u0020' +
                correctString.slice(t, correctString.length);
              operationCount += 1;
            }
          }
          if (!NON_BREAKING_SPACE.includes(target[i + 1])) {
            if (WHITESPACE.includes(target[i + 1])) {
              // REPLACE
              t = i + operationCount;
              correctString =
                correctString.slice(0, t + 1) +
                '\u00A0' +
                correctString.slice(t + 2, correctString.length);
            } else {
              // ADD
              t = i + operationCount;
              correctString =
                correctString.slice(0, t + 1) +
                '\u00A0' +
                correctString.slice(t + 1, correctString.length);
              operationCount += 1;
            }
          }
        }
        if (i === 0 && !NON_BREAKING_SPACE.includes(target[i + 1])) {
          if (WHITESPACE.includes(target[i + 1])) {
            // REPLACE
            t = i + operationCount;
            correctString =
              correctString.slice(0, t + 1) +
              '\u00A0' +
              correctString.slice(t + 2, correctString.length);
          } else {
            // ADD
            t = i + operationCount;
            correctString =
              correctString.slice(0, t + 1) +
              '\u00A0' +
              correctString.slice(t + 1, correctString.length);
            operationCount += 1;
          }
        }
        if (first_em_dash_status === false) {
          if (!NON_BREAKING_SPACE.includes(target[i - 1])) {
            if (WHITESPACE.includes(target[i - 1])) {
              // REPLACE
              t = i + operationCount;
              correctString =
                correctString.slice(0, t - 1) +
                '\u00A0' +
                correctString.slice(t, correctString.length);
            } else {
              // ADD
              t = i + operationCount;
              correctString =
                correctString.slice(0, t) +
                '\u00A0' +
                correctString.slice(t, correctString.length);
              operationCount += 1;
            }
          }
          if (!NORMAL_SPACE.includes(target[i + 1])) {
            if (WHITESPACE.includes(target[i + 1])) {
              // REPLACE
              t = i + operationCount;
              correctString =
                correctString.slice(0, t + 1) +
                '\u0020' +
                correctString.slice(t + 2, correctString.length);
            } else {
              // ADD
              t = i + operationCount;
              correctString =
                correctString.slice(0, t + 1) +
                '\u0020' +
                correctString.slice(t + 1, correctString.length);
              operationCount += 1;
            }
          }
        }
        first_em_dash_status = false;
      }
      if (char === FULL_STOP) {
        first_em_dash_status = true;
      }
    }

    return correctString;
  } else {
    return target;
  }
};
